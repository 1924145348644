import React, { useMemo } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import RenderHtml from 'react-native-render-html';
import { useViewWidth } from '../../hooks';
import { QuestResource, TokenHolderResource, TokenResource } from '../../mint';
import { Theme, withTheme } from '../../theme';
import { MembershipCard, Wrapper } from '../elements';
import { iframeModel, IframeRenderer } from '../html';

const useStyles = ({
  theme: { colors },
  viewWidth,
}: {
  theme: Theme;
  viewWidth: number;
}) => {
  return useMemo(() => {
    return StyleSheet.create({
      wrapper: {
        paddingTop: 16,
        paddingHorizontal: 16,
      },
      description: {
        marginTop: 24,
        width: '100%',
        borderRadius: 16,
        backgroundColor: colors.card,
        overflow: 'hidden',
      },
      cover: {
        width: '100%',
        height: viewWidth * 0.5,
      },
      htmlContainer: {
        width: '100%',
        padding: 16,
      },
    });
  }, [colors, viewWidth]);
};

type Props = {
  theme: Theme;
  point: TokenResource;
  quest: QuestResource;
  holder: TokenHolderResource;
};

const Component: React.FC<Props> = ({ theme, point, holder, quest }) => {
  const viewWidth = useViewWidth();
  const styles = useStyles({ theme, viewWidth });
  return (
    <Wrapper style={styles.wrapper}>
      <MembershipCard
        viewWidth={viewWidth}
        point={point}
        holder={holder}
        quest={quest}
      />
      <View style={styles.description}>
        {quest.cover_image.uploaded && (
          <Image
            style={styles.cover}
            resizeMode='cover'
            source={{ uri: quest.cover_image.url }}
          />
        )}
        <View style={styles.htmlContainer}>
          <RenderHtml
            defaultWebViewProps={{}}
            renderers={{ iframe: IframeRenderer }}
            renderersProps={{
              iframe: { scalesPageToFit: true },
            }}
            tagsStyles={{
              iframe: {
                marginTop: 15,
                borderRadius: 5,
                marginHorizontal: 0,
              },
            }}
            customHTMLElementModels={{ iframe: iframeModel }}
            source={{ html: quest.description }}
          />
        </View>
      </View>
    </Wrapper>
  );
};

export type { Props as MembershipProps };
export const Membership = withTheme(Component);
