import { useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useMemo } from 'react';
import { RootParamList } from '../navigation';
import { HeaderButtonProps } from './types';

export function useGoBack(
  callback?: (event: { goBack: () => void }) => void,
  deps?: React.DependencyList,
): HeaderButtonProps {
  const navigation = useNavigation<StackNavigationProp<RootParamList>>();
  const route = useRoute();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onPress = useCallback(callback ?? (() => undefined), deps);
  return useMemo(() => {
    const canGoBack = navigation.getState().routes.length > 1;
    return {
      image: canGoBack ? ('back' as const) : ('close' as const),
      onPress: () => {
        let navigated = false;
        const goBack = () => {
          navigated = true;
          if (canGoBack) {
            navigation.pop();
          } else if (route.name.startsWith('Auth')) {
            navigation.reset({ index: 0, routes: [{ name: 'AuthStart' }] });
          } else {
            navigation.reset({ index: 0, routes: [{ name: 'Portal' }] });
          }
        };
        onPress({ goBack });
        if (!navigated) goBack();
      },
    };
  }, [navigation, route.name, onPress]);
}
