import { createSelector } from 'reselect';
import { State as RootState } from '../store';

export const accountSelector = (state: RootState) => state.account;

export default {
  getProfile: createSelector([accountSelector], (account) => account.profile),
  getPasswordless: createSelector(
    [accountSelector],
    (account) => account.passwordless,
  ),
  getSigninTask: createSelector(
    [accountSelector],
    (account) => account.signinTask,
  ),
  getUpdateProfileTask: createSelector(
    [accountSelector],
    (account) => account.updateProfileTask,
  ),
  getUpdateEmailTask: createSelector(
    [accountSelector],
    (account) => account.updateEmailTask,
  ),
  getUpdatePhoneNumberTask: createSelector(
    [accountSelector],
    (account) => account.updatePhoneNumberTask,
  ),
  getUpdatePasswordTask: createSelector(
    [accountSelector],
    (account) => account.updatePasswordTask,
  ),
  getDisconnectTwitterTask: createSelector(
    [accountSelector],
    (account) => account.disconnectTwitterTask,
  ),
};
