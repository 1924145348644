import { MaterialCommunityIcons } from '@expo/vector-icons';
import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { BenefitFeature } from '../../mint';
import { Theme, withTheme } from '../../theme';

const useStyles = ({
  theme: { colors },
  size,
}: {
  theme: Theme;
  size: number;
}) => {
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          width: size,
          height: size,
          borderRadius: 16,
          backgroundColor: colors.primary,
        },
      }),
    [colors, size],
  );
};

type Props = {
  theme: Theme;
  size: number;
  feature: BenefitFeature;
};

const Component = withTheme<Props>(({ theme, size, feature }) => {
  const styles = useStyles({ theme, size });
  const icon = useMemo(() => {
    switch (feature) {
      case 'in_place':
      case 'pass':
        return 'cellphone-iphone' as const;
      case 'remote':
        return 'email-outline' as const;
      case 'delivery':
        return 'truck-fast-outline' as const;
      default:
        return 'alert-circle-outline' as const;
    }
  }, [feature]);
  return (
    <View style={styles.container}>
      <MaterialCommunityIcons
        name={icon}
        size={size * 0.7}
        color={theme.colors.card}
        style={{ transform: [{ scaleX: -1 }] }}
      />
    </View>
  );
});

export type { Props as BenefitIconProps };
export { Component as BenefitIcon };
