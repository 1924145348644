import React, { useMemo } from 'react';
import {
  FlatList,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { RefreshControl } from 'react-native-web-refresh-control';
import { EmptyNoticesImage } from '../../assets';
import { useViewWidth } from '../../hooks';
import { TokenNoticeResource } from '../../mint';
import { Theme, withTheme } from '../../theme';
import { ItemImage, NoticeAmount, Wrapper } from '../elements';
import { formatDate, toImageSource } from '../utils';
import { ListData } from './types';

const useEmptyStyles = ({
  theme: { colors, typography },
}: {
  theme: Theme;
}) => {
  return useMemo(() => {
    return StyleSheet.create({
      container: {
        flex: 1,
        alignItems: 'center',
      },
      title: {
        ...typography.paragraphXL,
        marginTop: 43,
        fontWeight: 'bold',
        textAlign: 'center',
        color: colors.textCardPrimary,
      },
    });
  }, [colors, typography]);
};

const Empty = withTheme<{
  theme: Theme;
  viewWidth: number;
}>(({ theme, viewWidth }) => {
  const styles = useEmptyStyles({ theme });
  const { paddingTop, width, height } = useMemo(
    () => ({
      paddingTop: viewWidth * 0.16,
      width: viewWidth * 0.51,
      height: viewWidth * 0.44,
    }),
    [viewWidth],
  );
  return (
    <View style={[styles.container, { paddingTop }]}>
      <Image
        style={{ width, height }}
        resizeMode='contain'
        source={EmptyNoticesImage}
      />
      <Text style={styles.title}>{'おしらせは準備中です\nおたのしみに!'}</Text>
    </View>
  );
});

const useListItemStyles = ({
  theme: { colors, typography },
}: {
  theme: Theme;
}) => {
  return useMemo(
    () =>
      StyleSheet.create({
        listItemContainer: {
          marginHorizontal: 24,
          padding: 16,
          borderRadius: 16,
          backgroundColor: colors.card,
        },
        listItemImage: {
          borderRadius: 12,
          marginBottom: 16,
        },
        listItemBody: {
          ...typography.paragraphS,
          paddingRight: 16,
          color: colors.textCardPrimary,
        },
        listItemContinue: {
          ...typography.paragraphS,
          width: '100%',
          textAlign: 'right',
          color: colors.link,
        },
        listItemAmount: {
          ...typography.paragraphS,
        },
        listItemTime: {
          ...typography.paragraphS,
          color: colors.textCardSecondary,
        },
      }),
    [colors, typography],
  );
};

const ListItem = withTheme<{
  theme: Theme;
  viewWidth: number;
  notice: TokenNoticeResource;
  onPress: () => void;
}>(({ theme, viewWidth, notice, onPress }) => {
  const styles = useListItemStyles({ theme });
  const deliveredAt = useMemo(
    () => formatDate('ApproximateTime', new Date(notice.created_at)),
    [notice],
  );
  return (
    <TouchableOpacity style={styles.listItemContainer} onPress={onPress}>
      {notice.has_image && (
        <ItemImage
          containerStyle={styles.listItemImage}
          size={viewWidth * 0.8}
          rounded
          source={toImageSource(notice.image)}
        />
      )}
      <Text
        style={[
          styles.listItemBody,
          { fontWeight: !notice.read_at ? 'bold' : 'normal' },
        ]}
        numberOfLines={2}
        ellipsizeMode='tail'
      >
        {notice.body}
      </Text>
      <Text style={styles.listItemContinue}>続きを読む</Text>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 16,
        }}
      >
        <NoticeAmount
          style={styles.listItemAmount}
          iconSize={16}
          amount={notice.amount}
        />
        <Text style={styles.listItemTime}>{deliveredAt}</Text>
      </View>
    </TouchableOpacity>
  );
});

type Props = {
  data: ListData<TokenNoticeResource>;
  onPressItem: (v: TokenNoticeResource) => void;
};

const Component: React.FC<Props> = ({
  data: { data, refreshing, onRefresh, onEndReached },
  onPressItem,
}) => {
  const viewWidth = useViewWidth();
  return (
    <Wrapper scrollDisabled>
      <FlatList
        style={{ paddingTop: 16 }}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        data={data}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => (
          <ListItem
            viewWidth={viewWidth}
            notice={item}
            onPress={() => onPressItem(item)}
          />
        )}
        ListEmptyComponent={() =>
          !refreshing && <Empty viewWidth={viewWidth} />
        }
        onEndReached={onEndReached}
        onEndReachedThreshold={0.1}
        ItemSeparatorComponent={() => <View style={{ height: 16 }} />}
        ListFooterComponent={() => <View style={{ height: 100 }} />}
      />
    </Wrapper>
  );
};

export type { Props as NoticeListProps };
export { Component as NoticeList };
