import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HistoryList } from '../components/pages';
import {
  useAuth,
  useGoBack,
  useListData,
  useNavigationHeader,
  usePoint,
} from '../hooks';
import { TokenTransactionResource } from '../mint';
import { HistoryListNavigation, HistoryListRoute } from '../navigation';
import {
  operations as pointOperations,
  selectors as pointSelectors,
} from '../states/point';

type ContainerProps = {
  navigation: HistoryListNavigation;
  route: HistoryListRoute;
};

const Container: React.FC<ContainerProps> = ({ navigation }) => {
  useAuth();
  const { point } = usePoint();
  const dispatch = useDispatch();
  const { status, items } = useSelector(pointSelectors.listTransaction);
  const holder = useSelector(pointSelectors.holder);
  const checkInStampIDs = useSelector(pointSelectors.checkinStampIDs);
  const introStampIDs = useSelector(pointSelectors.introStampIDs);
  const goBack = useGoBack();
  useNavigationHeader(() => ({ title: '送受信履歴', left: goBack }), [goBack]);
  const onPressItem = useCallback(
    ({ id: transactionId }: TokenTransactionResource) =>
      navigation.push('HistoryDetail', { transactionId }),
    [navigation],
  );
  const onLoad = useCallback(
    (v?: { more: boolean }) => dispatch(pointOperations.listTransaction(v)),
    [dispatch],
  );
  const data = useListData({ data: items, status, onLoad });
  return (
    <HistoryList
      point={point}
      holder={holder}
      data={data}
      checkInStampIDs={checkInStampIDs}
      introStampIDs={introStampIDs}
      onPressItem={onPressItem}
    />
  );
};

export { Container as HistoryListView };
export default Container;
