import { MaterialIcons } from '@expo/vector-icons';
import React, { useCallback, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { TokenResource } from '../../mint';
import { Theme, withTheme } from '../../theme';
import { Button, OTPInput, TextInput, Wrapper } from '../elements';

const styles = StyleSheet.create({
  wrapper: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 40,
  },
});

type Props = {
  theme: Theme;
  point: TokenResource;
  connection: 'email' | 'sms';
  value: string;
  error?: string;
  onChange: (code: string) => void;
  onPressSubmit: (code: string) => void;
  onPressResend: () => void;
  onPressBack: () => void;
  onPressInquiry: () => void;
};

const Component: React.FC<Props> = ({
  theme: { colors, typography },
  ...props
}) => {
  const [value, setValue] = useState<string>('');
  const onChange = useCallback(
    (code) => {
      setValue(code);
      props.onChange(code);
    },
    [props],
  );
  const onPressSubmit = useCallback(
    () => props.onPressSubmit(value),
    [props, value],
  );
  return (
    <Wrapper style={styles.wrapper}>
      <View style={{ width: '100%', alignItems: 'center' }}>
        <MaterialIcons
          style={{ marginTop: 40 }}
          name={'smartphone'}
          size={56}
          color={colors.primary}
        />
        <Text
          style={{
            ...typography.paragraphXL,
            marginTop: 16,
            textAlign: 'center',
            fontWeight: 'bold',
            color: colors.textBackgroundPrimary,
          }}
        >
          {props.connection === 'sms' ? 'SMS' : 'メール'}
          で届いた認証コードを{'\n'}
          入力してください
        </Text>
        <OTPInput
          containerStyle={{ marginTop: 24 }}
          onChange={onChange}
          errorHint={props.error}
        />
        <Button
          title='次へ'
          style={{ marginTop: 48, width: 128 }}
          onPress={onPressSubmit}
        />
      </View>
      <Text
        style={{
          ...typography.textL,
          marginTop: 64,
          fontWeight: 'bold',
          color: colors.textBackgroundPrimary,
        }}
      >
        認証コードが届かない場合
      </Text>
      <Text
        style={{
          ...typography.paragraphM,
          marginTop: 16,
          color: colors.textBackgroundPrimary,
        }}
      >
        認証コードの送り先（
        {props.connection === 'sms' ? '電話番号' : 'メールアドレス'}
        ）が合っているかご確認ください。
      </Text>
      <TextInput
        containerStyle={{ marginTop: 24 }}
        label='認証コードの送り先'
        placeholder={maskRealm(props.connection, props.value)}
        placeholderTextColor={colors.primary}
        editable={false}
      />
      <Text
        style={{
          ...typography.paragraphM,
          marginTop: 24,
          color: colors.textBackgroundPrimary,
        }}
      >
        1.
        {props.connection === 'sms' ? '電話番号' : 'メールアドレス'}
        が正しく、届かない場合は「認証コードを再送する」を押してください。
      </Text>
      <Text
        style={{
          ...typography.paragraphM,
          marginTop: 8,
          color: colors.textBackgroundPrimary,
        }}
      >
        2.
        {props.connection === 'sms' ? '電話番号' : 'メールアドレス'}
        が間違っている場合は、「
        {props.connection === 'sms' ? '電話番号' : 'メールアドレス'}
        を入力しなおす」を押してください。
      </Text>
      <View style={{ width: '100%', alignItems: 'center' }}>
        <Button
          title='認証コードを再送する'
          style={{ marginTop: 32, width: 240 }}
          onPress={props.onPressResend}
        />
        <Button
          title={
            (props.connection === 'sms' ? '電話番号' : 'メールアドレス') +
            'を入力しなおす'
          }
          style={{ marginTop: 16, width: 240 }}
          onPress={props.onPressBack}
        />
      </View>
      <Text
        style={{
          ...typography.paragraphM,
          marginTop: 32,
          color: colors.textBackgroundPrimary,
        }}
      >
        その他、登録やログインで何かお困りのことがありましたら、
        <TouchableOpacity onPress={props.onPressInquiry}>
          <Text
            style={{
              ...typography.paragraphM,
              color: colors.link,
            }}
          >
            こちら
          </Text>
        </TouchableOpacity>
        からお問い合わせください。
      </Text>
    </Wrapper>
  );
};

export type { Props as AuthVerificationProps };
export const AuthVerification = withTheme(Component);

const maskRealm = (connection: 'sms' | 'email', value: string): string => {
  if (connection === 'sms') {
    return '*******' + (value.length > 4 ? value.slice(-4) : value);
  }
  return value;
};
