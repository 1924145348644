import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Scanner } from '../components/pages';
import {
  useAcquisitionPopUp,
  useAuth,
  useGoBack,
  useNavigationHeader,
  usePoint,
} from '../hooks';
import { ScannerNavigation, ScannerRoute } from '../navigation';
import { operations as pointOperations } from '../states/point';

type Props = {
  navigation: ScannerNavigation;
  route: ScannerRoute;
};

const Container: React.FC<Props> = () => {
  useAuth();
  usePoint();
  const dispatch = useDispatch();
  const { onPress: goBack } = useGoBack();
  useNavigationHeader(
    () => ({
      title: 'QRコードをスキャン',
      transparent: true,
      right: { tintColor: 'white', image: 'close', onPress: goBack },
    }),
    [goBack],
  );
  useAcquisitionPopUp(() => ({ onPressClose: goBack }), [goBack]);
  const onScanned = useCallback(
    (event: { type: string; data: string }) => {
      dispatch(pointOperations.capture({ type: 'qrcode', value: event.data }));
    },
    [dispatch],
  );
  return <Scanner onScanned={onScanned} />;
};

export { Container as ScannerView };
export default Container;
