import React, { ReactNode, useMemo } from 'react';
import {
  ActivityIndicator,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import { Theme, withTheme } from '../../theme';

const useStyles = ({
  theme: { colors, typography },
  disabled,
}: {
  theme: Theme;
  disabled: boolean;
}) =>
  useMemo(
    () =>
      StyleSheet.create({
        container: {
          height: 46,
          borderRadius: 23,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: !disabled ? colors.primary : colors.textCardHidden,
        },
        text: {
          ...typography.textM,
          fontWeight: 'bold',
          color: colors.card,
        },
      }),
    [colors, typography, disabled],
  );

type Props = {
  theme: Theme;
  style?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  title?: string;
  disabled?: boolean;
  loading?: boolean;
  onPress: () => void;
  children?: ReactNode;
};

const Component: React.FC<Props> = ({
  theme,
  style,
  titleStyle,
  title,
  disabled,
  loading,
  onPress,
  children,
}) => {
  const styles = useStyles({ theme, disabled });
  return (
    <TouchableOpacity
      style={[styles.container, style]}
      disabled={disabled}
      onPress={onPress}
    >
      {loading ? (
        <ActivityIndicator size={40} color={theme.colors.primary} />
      ) : (
        children ?? (
          <Text style={[styles.text, titleStyle]}>{title ?? 'SUBMIT'}</Text>
        )
      )}
    </TouchableOpacity>
  );
};

export type { Props as ButtonProps };
export const Button = withTheme(Component);
