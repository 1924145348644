import React, { useCallback, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Button, TextInput, Wrapper } from '../elements';

const styles = StyleSheet.create({
  hint: {
    marginTop: '25px',
  },
  resendContainer: {
    marginTop: '10px',
    width: '100%',
    textAlign: 'right',
    paddingLeft: 10,
    paddingRight: 10,
  },
  resend: {
    color: '#2e90b7',
    fontWeight: 'bold',
  },
  button: {
    marginTop: '25px',
  },
});

type Props = {
  loading: boolean;
  updated: boolean;
  phoneNumber: string;
  onPressResend: () => void;
  onPressSubmit: (code: string) => void;
};

const Component: React.FC<Props> = (props) => {
  const [value, setValue] = useState<string>('');
  const onPressSubmit = useCallback(
    () => props.onPressSubmit(value),
    [props, value],
  );
  return (
    <Wrapper style={{ paddingHorizontal: 16 }}>
      <Text style={styles.hint}>
        {maskPhoneNumber(props.phoneNumber)}
        にお送りした認証コードを入力してください
      </Text>
      <TextInput
        editable={!props.loading && !props.updated}
        placeholder='認証コード'
        containerStyle={{ marginTop: '25px' }}
        maxLength={6}
        keyboardType={'number-pad'}
        onChangeText={setValue}
      />
      <View style={styles.resendContainer}>
        <TouchableOpacity onPress={props.onPressResend}>
          <Text style={styles.resend}>認証コードを再度送信</Text>
        </TouchableOpacity>
      </View>
      <Button
        loading={props.loading}
        disabled={props.updated || value.length !== 6}
        title='変更'
        style={styles.button}
        onPress={onPressSubmit}
      />
    </Wrapper>
  );
};

export type { Props as AccountPhoneVerificationProps };
export { Component as AccountPhoneVerification };

const maskPhoneNumber = (value: string): string => {
  if (value.length > 4) {
    return (
      value.slice(0, value.length - 4).replace(/./g, '*') + value.slice(-4)
    );
  }
  return value;
};
