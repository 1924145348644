import { Reducer } from 'redux';
import { Problem } from '../../mint';
import { Actions, ActionTypes } from './actions';

interface State {
  title?: string;
  message?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cause?: any;
}

const initialState: State = {};

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_ERROR: {
      console.error(action.error);
      const err =
        action.error instanceof Problem
          ? action.error
          : new Problem(action.error);
      return {
        ...state,
        title: err.userTitle,
        message: err.userMsg,
        cause: err,
      };
    }
    case ActionTypes.CLEAR_ERROR:
      return {
        ...state,
        title: undefined,
        message: undefined,
        cause: undefined,
      };

    default:
      return state;
  }
};

export default reducer;
