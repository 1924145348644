import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Animated, Easing, Linking, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { DrawerMenu, DrawerMenuRef } from '../components/elements/DrawerMenu';
import { PopUp, PopUpProps, PopUpRef } from '../components/elements/PopUp';
import { setForwardRef } from '../components/utils';
import { useViewWidth } from '../hooks';
import { selectors as accountSelectors } from '../states/account';
import {
  operations as errorOperations,
  selectors as errorSelectors,
} from '../states/error';
import { selectors as pointSelectors } from '../states/point';

export type ModalPopUpProps = Omit<PopUpProps, 'theme'> & {
  backdrop?: { onPress: () => void };
};

export type Modal = {
  openPopUp: (popup: ModalPopUpProps) => void;
  openDrawer: () => void;
  close: (options?: { animated: boolean }) => void;
};

const MINT_URL = 'https../../themint.jp';
const MINT_POLICY_URL = 'https://them../../.jp/about/policies.html';
const MINT_INQUIRY_URL =
  'https://themint.zendesk.com/hc/ja/categories/115000443711';

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity);

type BackgroundRef = { hideAnimation: () => Animated.CompositeAnimation };

const Background = React.forwardRef<
  BackgroundRef,
  { children: React.ReactNode; onPress: () => void }
>(({ children, onPress }, ref) => {
  const v = useRef(new Animated.Value(0));
  useEffect(() => {
    Animated.timing(v.current, {
      useNativeDriver: false,
      toValue: 1,
      duration: 300,
      easing: Easing.bezier(0.4, 0.0, 0.2, 1),
    }).start();
  }, []);
  useEffect(() => {
    setForwardRef(ref, {
      hideAnimation: () =>
        Animated.timing(v.current, {
          useNativeDriver: false,
          toValue: 0,
          duration: 300,
          easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        }),
    });
  }, [ref]);
  return (
    <AnimatedTouchable
      activeOpacity={1}
      style={{
        position: 'absolute',
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: v.current.interpolate({
          inputRange: [0, 1],
          outputRange: ['rgba(0,0,0,0)', 'rgba(0,0,0,0.5)'],
        }),
      }}
      onPress={onPress}
    >
      {children}
    </AnimatedTouchable>
  );
});

type Content =
  | { shown: false }
  | { shown: true; type: 'drawer' }
  | {
      shown: true;
      type: 'popup';
      popup: ModalPopUpProps;
    };

export const ModalContext = createContext<Modal>(null);

export const ModalProvider: React.FC<{
  children: React.ReactNode;
  onPressSettings: () => void;
}> = ({ children, ...props }) => {
  const viewWidth = useViewWidth();
  const dispatch = useDispatch();
  const bgRef = useRef<BackgroundRef>();
  const popupRef = useRef<PopUpRef>();
  const drawerRef = useRef<DrawerMenuRef>();
  const [content, setContent] = useState<Content>({ shown: false });
  const error = useSelector(errorSelectors.getError);
  const profile = useSelector(accountSelectors.getProfile);
  const point = useSelector(pointSelectors.point);
  const { footer } = useSelector(pointSelectors.siteUI);
  const ctx = useMemo<Modal>(
    () => ({
      openPopUp: (popup) => {
        setContent({ shown: true, type: 'popup', popup });
      },
      openDrawer: () => {
        setContent({ shown: true, type: 'drawer' });
      },
      close: (options?: { animated: boolean }) => {
        if (options && options.animated) {
          Animated.parallel([
            bgRef?.current?.hideAnimation(),
            popupRef?.current?.hideAnimation(),
            drawerRef?.current?.hideAnimation(),
          ]).start(() => {
            setContent({ shown: false });
          });
        } else {
          setContent({ shown: false });
        }
      },
    }),
    [],
  );
  useEffect(() => {
    if (error.title || error.message) {
      setContent({
        shown: true,
        type: 'popup',
        popup: {
          image: 'alert',
          title: error.title,
          body: error.message,
          secondaryButton: {
            title: '閉じる',
            onPress: () => {
              setContent({ shown: false });
              dispatch(errorOperations.clearError());
            },
          },
        },
      });
    }
  }, [dispatch, error]);
  const onPressBackground = useCallback(() => {
    if (content.shown) {
      switch (content.type) {
        case 'popup':
          if (content.popup.backdrop) {
            content.popup.backdrop.onPress();
          }
          break;
        default:
          Animated.parallel([
            bgRef?.current?.hideAnimation(),
            popupRef?.current?.hideAnimation(),
            drawerRef?.current?.hideAnimation(),
          ]).start(() => {
            setContent({ shown: false });
          });
      }
    }
  }, [content]);
  const onPressSettings = useCallback(() => {
    setContent({ shown: false });
    props.onPressSettings();
  }, [props]);
  const onPressCloseDrawer = useCallback(() => {
    Animated.parallel([
      bgRef.current.hideAnimation(),
      drawerRef.current.hideAnimation(),
    ]).start(() => {
      setContent({ shown: false });
    });
  }, []);
  return (
    <ModalContext.Provider value={ctx}>
      {children}
      {content.shown && (
        <Background ref={bgRef} onPress={onPressBackground}>
          {content.type === 'drawer' && (
            <DrawerMenu
              ref={drawerRef}
              viewWidth={viewWidth}
              profile={profile}
              ownerName={point.owner.name}
              additionalFooters={footer}
              onPressPolicy={() => Linking.openURL(MINT_POLICY_URL)}
              onPressInquiry={() => Linking.openURL(MINT_INQUIRY_URL)}
              onPressMintjp={() => Linking.openURL(MINT_URL)}
              onPressSettings={onPressSettings}
              onPressClose={onPressCloseDrawer}
            />
          )}
          {content.type === 'popup' && (
            <PopUp ref={popupRef} {...content.popup} />
          )}
        </Background>
      )}
    </ModalContext.Provider>
  );
};
