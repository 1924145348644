import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AccountSettings,
  AccountSettingsPressEvent,
} from '../components/pages';
import { useAuth, useGoBack, useNavigationHeader, usePoint } from '../hooks';
import mint from '../mint';
import { AccountSettingsNavigation, AccountSettingsRoute } from '../navigation';
import {
  operations as accountOperations,
  selectors as accountSelectors,
} from '../states/account';
import { selectors as pointSelectors } from '../states/point';

type Props = {
  navigation: AccountSettingsNavigation;
  route: AccountSettingsRoute;
};

const Container: React.FC<Props> = ({ navigation }) => {
  useAuth();
  usePoint();
  const dispatch = useDispatch();
  const disconnectTwitterTask = useSelector(
    accountSelectors.getDisconnectTwitterTask,
  );
  const profile = useSelector(accountSelectors.getProfile);
  const holder = useSelector(pointSelectors.holder);
  const goBack = useGoBack();
  useNavigationHeader(
    () => ({ title: 'アカウント設定', left: goBack }),
    [goBack],
  );
  useEffect(() => {
    if (
      disconnectTwitterTask === 'success' ||
      disconnectTwitterTask === 'error'
    ) {
      dispatch(accountOperations.endDisconnectTwitter());
    }
  }, [dispatch, disconnectTwitterTask]);
  const onPress = useCallback(
    ({ name }: AccountSettingsPressEvent) => {
      switch (name) {
        case 'profile':
          navigation.push('AccountProfile');
          break;
        case 'email':
          navigation.push('AccountEmail');
          break;
        case 'phone':
          navigation.push('AccountPhone');
          break;
        case 'password':
          navigation.push('AccountPassword');
          break;
        case 'twitter':
          profile.twitterConnected
            ? dispatch(accountOperations.disconnectTwitter())
            : mint.getTwitterLoginURL((url) => window.open(url, '_self'));
          break;
        case 'logout':
          navigation.push('AuthLogout');
          break;
      }
    },
    [navigation, dispatch, profile.twitterConnected],
  );
  return (
    <AccountSettings profile={profile} holder={holder} onPress={onPress} />
  );
};

export { Container as AccountSettingsView };
export default Container;
