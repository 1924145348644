import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  StyleProp,
  StyleSheet,
  Text,
  TextInput,
  View,
  ViewStyle,
} from 'react-native';
import { Theme, withTheme } from '../../theme';

const styles = StyleSheet.create({
  textInput: {
    width: 32,
    height: 59,
    borderBottomWidth: 2,
    fontSize: 32,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

type Props = {
  theme: Theme;
  containerStyle?: StyleProp<ViewStyle>;
  errorHint?: string;
  onChange: (text: string) => void;
};

const Component: React.FC<Props> = ({
  theme: { colors, typography },
  onChange,
  ...props
}) => {
  const pinCount = 6;
  const fields = useRef(
    new Array(pinCount).fill(0).map(() => createRef<TextInput>()),
  );
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const change = useCallback(
    () => onChange(fields.current.map((ref) => ref.current['value']).join('')),
    [onChange],
  );
  useEffect(() => fields.current[0].current.focus(), []);
  return (
    <View style={props.containerStyle}>
      <View
        style={{
          width: 272,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {fields.current.map((ref, index) => (
          <TextInput
            key={`otp-input-${index}`}
            ref={fields.current[index]}
            keyboardType='number-pad'
            caretHidden
            maxLength={1}
            style={[
              styles.textInput,
              {
                color: colors.textBackgroundPrimary,
                borderBottomColor:
                  selectedIndex === index
                    ? colors.primary
                    : colors.textBackgroundPrimary,
              },
            ]}
            onKeyPress={({ nativeEvent: { key } }) => {
              if (key === 'Backspace') {
                const prevRef = fields.current[index - 1];
                if (prevRef && prevRef.current['value']) {
                  prevRef.current['value'] = '';
                  prevRef.current.focus();
                  change();
                }
                return;
              }
            }}
            onChangeText={(text) => {
              const ref = fields.current[index];
              if (!text.match(/\d/)) {
                ref.current['value'] = '';
                return;
              }
              const nextRef = fields.current[index + 1];
              if (nextRef) {
                nextRef.current.focus();
                setSelectedIndex(index + 1);
              } else {
                ref.current.blur();
                setSelectedIndex(-1);
              }
              change();
            }}
            onFocus={() => {
              ref.current['value'] = '';
              setSelectedIndex(index);
              change();
            }}
          />
        ))}
      </View>
      {props.errorHint && (
        <View style={{ paddingTop: 3, paddingLeft: 3 }}>
          <Text
            style={{
              ...typography.textM,
              fontWeight: 'bold',
              color: colors.alertPrimary,
              marginTop: 9,
              textAlign: 'center',
            }}
          >
            {props.errorHint}
          </Text>
        </View>
      )}
    </View>
  );
};

export type { Props as OTPInputProps };
export const OTPInput = withTheme(Component);
