import React, { useMemo, useState } from 'react';
import {
  ColorValue,
  KeyboardTypeOptions,
  NativeSyntheticEvent,
  StyleProp,
  StyleSheet,
  Text,
  TextInput as RNTextInput,
  TextInputChangeEventData,
  TextInputFocusEventData,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import { Theme, withTheme } from '../../theme';

const useStyles = ({
  theme: { colors, typography },
  numberOfLines,
}: {
  theme: Theme;
  numberOfLines?: number;
}) => {
  return useMemo(
    () =>
      StyleSheet.create({
        labelContainer: {
          flexDirection: 'row',
          marginBottom: 8,
        },
        label: {
          ...typography.textM,
          fontWeight: 'bold',
          color: colors.textBackgroundPrimary,
        },
        edit: {
          ...typography.textM,
          fontWeight: 'bold',
          color: colors.primary,
          marginLeft: 4,
        },
        inputContainer: {
          height: 43 + 14 * (numberOfLines ?? 1),
          justifyContent: 'center',
          borderRadius: 15,
          paddingLeft: 16,
          paddingRight: 16,
          width: '100%',
          backgroundColor: colors.card,
        },
        inputContainerError: {
          borderWidth: 2,
          borderColor: colors.alertPrimary,
        },
        input: {
          ...typography.textM,
          color: colors.textBackgroundPrimary,
        },
        confirmationContainer: {
          height: 43 + 14 * (numberOfLines ?? 1),
          justifyContent: 'center',
          width: '100%',
        },
        confirmation: {
          ...typography.textM,
          color: colors.textCardSecondary,
        },
        error: {
          ...typography.textM,
          fontWeight: 'bold',
          color: colors.alertPrimary,
        },
      }),
    [colors, typography, numberOfLines],
  );
};

type Props = {
  theme: Theme;
  keyboardType?: KeyboardTypeOptions;
  placeholder?: string;
  placeholderTextColor?: ColorValue;
  autoCompleteType?: 'email';
  label?: string;
  value?: string;
  defaultValue?: string;
  editable?: boolean;
  confirmation?: boolean;
  error?: boolean;
  errorMessage?: string;
  multiline?: boolean;
  numberOfLines?: number;
  maxLength?: number;
  secureTextEntry?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  onEdit?: () => void;
  onChangeText?: (text: string) => void;
  onChange?: (e: NativeSyntheticEvent<TextInputChangeEventData>) => void;
  onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
};

const Component = React.forwardRef<RNTextInput, Props>(
  ({ theme, label, placeholder, numberOfLines, ...props }, ref) => {
    const styles = useStyles({ theme, numberOfLines });
    const [confirmationValue, setConfirmationValue] = useState(
      props.defaultValue,
    );
    const isError = useMemo(
      () =>
        props.error !== undefined
          ? props.error
          : props.errorMessage && props.errorMessage !== '',
      [props.error, props.errorMessage],
    );
    return (
      <View style={[{ width: '100%' }, props.containerStyle]}>
        {label && (
          <View style={styles.labelContainer}>
            <Text style={[styles.label, {}, props.labelStyle]}>{label}</Text>
            {props.confirmation && (
              <Text onPress={props.onEdit} style={styles.edit}>
                編集する
              </Text>
            )}
          </View>
        )}
        {props.confirmation && (
          <View style={styles.confirmationContainer}>
            <Text style={styles.confirmation}>{confirmationValue}</Text>
          </View>
        )}
        <View
          style={[
            styles.inputContainer,
            {},
            isError && styles.inputContainerError,
            props.confirmation && { opacity: 0, height: 0 },
          ]}
        >
          <RNTextInput
            ref={ref}
            style={styles.input}
            placeholderTextColor={
              props.placeholderTextColor
                ? props.placeholderTextColor
                : theme.colors.textCardHidden
            }
            keyboardType={props.keyboardType}
            multiline={props.multiline}
            numberOfLines={numberOfLines}
            maxLength={props.maxLength}
            placeholder={placeholder}
            secureTextEntry={props.secureTextEntry}
            editable={props.editable}
            value={props.value}
            defaultValue={props.defaultValue}
            onChange={props.onChange}
            onChangeText={(value) => {
              setConfirmationValue(value);
              props.onChangeText && props.onChangeText(value);
            }}
            onBlur={props.onBlur}
            {...(props.autoCompleteType === 'email' && {
              nativeID: 'email',
              autoCompleteType: 'email',
            })}
          />
        </View>
        {props.errorMessage && (
          <View style={{ paddingTop: 3, paddingLeft: 3 }}>
            <Text style={styles.error}>{props.errorMessage}</Text>
          </View>
        )}
      </View>
    );
  },
);

export type { Props as TextInputProps };
export const TextInput = withTheme(Component);
