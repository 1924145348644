import { ActionType } from 'redux-actions-type';
import { UserImageResource, UserProfile } from '../../mint';
import { operations as errorOperations } from '../error';
import { Route } from './types';

export const ActionTypes = {
  START_SIGNIN_PASSWORDLESS: '@@account/START_SIGNIN_PASSWORDLESS' as const,
  COMPLETE_SIGNIN: '@@account/COMPLETE_SIGNIN' as const,
  CANCEL_SIGNIN: '@@account/CANCEL_SIGNIN' as const,
  SET_SIGNIN_VERIFICATION_ERROR:
    '@@account/SET_SIGNIN_VERIFICATION_ERROR' as const,
  ADD_NEXT_ROUTE_AFTER_SIGNIN: '@@account/ADD_NEXT_ROUTE_AFTER_SIGNIN' as const,
  CONSUME_NEXT_ROUTE_AFTER_SIGNIN:
    '@@account/CONSUME_NEXT_ROUTE_AFTER_SIGNIN' as const,
  SIGNOUT: '@@account/SIGNOUT' as const,

  SET_PROFILE: '@@account/SET_PROFILE' as const,

  START_UPDATE_PROFILE: '@@account/START_UPDATE_PROFILE' as const,
  SUCCESS_UPDATE_PROFILE: '@@account/SUCCESS_UPDATE_PROFILE' as const,
  ERROR_UPDATE_PROFILE: '@@account/ERROR_UPDATE_PROFILE' as const,
  END_UPDATE_PROFILE: '@@account/END_UPDATE_PROFILE' as const,

  START_UPDATE_EMAIL: '@@account/START_UPDATE_EMAIL' as const,
  SUCCESS_UPDATE_EMAIL: '@@account/SUCCESS_UPDATE_EMAIL' as const,
  ERROR_UPDATE_EMAIL: '@@account/ERROR_UPDATE_EMAIL' as const,
  END_UPDATE_EMAIL: '@@account/END_UPDATE_EMAIL' as const,

  SET_UPDATE_PHONE_NUMBER: '@@account/SET_UPDATE_PHONE_NUMBER' as const,
  START_UPDATE_PHONE_NUMBER: '@@account/START_UPDATE_PHONE_NUMBER' as const,
  SUCCESS_UPDATE_PHONE_NUMBER: '@@account/SUCCESS_UPDATE_PHONE_NUMBER' as const,
  ERROR_UPDATE_PHONE_NUMBER: '@@account/ERROR_UPDATE_PHONE_NUMBER' as const,
  END_UPDATE_PHONE_NUMBER: '@@account/END_UPDATE_PHONE_NUMBER' as const,

  START_UPDATE_PASSWORD: '@@account/START_UPDATE_PASSWORD' as const,
  SUCCESS_UPDATE_PASSWORD: '@@account/SUCCESS_UPDATE_PASSWORD' as const,
  ERROR_UPDATE_PASSWORD: '@@account/ERROR_UPDATE_PASSWORD' as const,
  END_UPDATE_PASSWORD: '@@account/END_UPDATE_PASSWORD' as const,

  START_DISCONNECT_TWITTER: '@@account/START_DISCONNECT_TWITTER' as const,
  SUCCESS_DISCONNECT_TWITTER: '@@account/SUCCESS_DISCONNECT_TWITTER' as const,
  ERROR_DISCONNECT_TWITTER: '@@account/ERROR_DISCONNECT_TWITTER' as const,
  END_DISCONNECT_TWITTER: '@@account/END_DISCONNECT_TWITTER' as const,
};

const actions = {
  setError: errorOperations.setError,
  startSignInPasswordless: (v: {
    state?: string;
    verifier: string;
    username: string;
    connection: 'sms' | 'email';
  }) => ({
    type: ActionTypes.START_SIGNIN_PASSWORDLESS,
    passwordless: v,
  }),
  completeSignin: (options: { profile: UserProfile; nextRoutes: Route[] }) => ({
    type: ActionTypes.COMPLETE_SIGNIN,
    profile: options.profile,
    nextRoutes: options.nextRoutes,
  }),
  cancelSignIn: () => ({ type: ActionTypes.CANCEL_SIGNIN }),
  setSignInVerificationError: (error: string) => ({
    type: ActionTypes.SET_SIGNIN_VERIFICATION_ERROR,
    error,
  }),
  clearSignInVerificationError: () => ({
    type: ActionTypes.SET_SIGNIN_VERIFICATION_ERROR,
    error: undefined,
  }),
  addNextRouteAfterSignIn: (route: Route) => ({
    type: ActionTypes.ADD_NEXT_ROUTE_AFTER_SIGNIN,
    route,
  }),
  consumeNextRouteAfterSignIn: () => ({
    type: ActionTypes.CONSUME_NEXT_ROUTE_AFTER_SIGNIN,
  }),
  signout: () => ({ type: ActionTypes.SIGNOUT }),
  setProfile: (profile: UserProfile) => ({
    type: ActionTypes.SET_PROFILE,
    profile,
  }),

  startUpdateProfile: () => ({ type: ActionTypes.START_UPDATE_PROFILE }),
  endUpdateProfile: () => ({ type: ActionTypes.END_UPDATE_PROFILE }),
  errorUpdateProfile: () => ({ type: ActionTypes.ERROR_UPDATE_PROFILE }),
  successUpdateProfile: (v: {
    name: string;
    screenName: string;
    image?: UserImageResource;
  }) => ({
    type: ActionTypes.SUCCESS_UPDATE_PROFILE,
    ...v,
  }),

  startUpdateEmail: () => ({ type: ActionTypes.START_UPDATE_EMAIL }),
  endUpdateEmail: () => ({ type: ActionTypes.END_UPDATE_EMAIL }),
  errorUpdateEmail: () => ({ type: ActionTypes.ERROR_UPDATE_EMAIL }),
  successUpdateEmail: (v: { email: string }) => ({
    type: ActionTypes.SUCCESS_UPDATE_EMAIL,
    ...v,
  }),

  setUpdatePhoneNumber: (v: {
    verificationId: string;
    phoneNumber: string;
  }) => ({
    type: ActionTypes.SET_UPDATE_PHONE_NUMBER,
    ...v,
  }),
  startUpdatePhoneNumber: () => ({
    type: ActionTypes.START_UPDATE_PHONE_NUMBER,
  }),
  endUpdatePhoneNumber: () => ({ type: ActionTypes.END_UPDATE_PHONE_NUMBER }),
  errorUpdatePhoneNumber: () => ({
    type: ActionTypes.ERROR_UPDATE_PHONE_NUMBER,
  }),
  successUpdatePhoneNumber: () => ({
    type: ActionTypes.SUCCESS_UPDATE_PHONE_NUMBER,
  }),

  startUpdatePassword: () => ({ type: ActionTypes.START_UPDATE_PASSWORD }),
  endUpdatePassword: () => ({ type: ActionTypes.END_UPDATE_PASSWORD }),
  errorUpdatePassword: () => ({ type: ActionTypes.ERROR_UPDATE_PASSWORD }),
  successUpdatePassword: () => ({ type: ActionTypes.SUCCESS_UPDATE_PASSWORD }),

  startDissconectTwitter: () => ({
    type: ActionTypes.START_DISCONNECT_TWITTER,
  }),
  endDisconnectTwitter: () => ({ type: ActionTypes.END_DISCONNECT_TWITTER }),
  errorDissconectTwitter: () => ({
    type: ActionTypes.ERROR_DISCONNECT_TWITTER,
  }),
  successDissconectTwitter: () => ({
    type: ActionTypes.SUCCESS_DISCONNECT_TWITTER,
  }),
};

export type Actions = ActionType<typeof actions>;

export default actions;
