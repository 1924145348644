import React from 'react';
import { Loading } from '../components/elements';
import { NoticeDetail } from '../components/pages';
import {
  useAuth,
  useGoBack,
  useNavigationHeader,
  useNotice,
  usePoint,
} from '../hooks';
import { NoticeDetailNavigation, NoticeDetailRoute } from '../navigation';

type ContainerProps = {
  navigation: NoticeDetailNavigation;
  route: NoticeDetailRoute;
};

const Container: React.FC<ContainerProps> = ({ route }) => {
  useAuth();
  usePoint();
  const { noticeId } = { noticeId: undefined, ...route.params };
  const notice = useNotice({ id: noticeId });
  const goBack = useGoBack();
  useNavigationHeader(() => ({ title: 'おしらせ', left: goBack }), [goBack]);
  return notice ? <NoticeDetail notice={notice} /> : <Loading title='通信中' />;
};

export { Container as NoticeDetailView };
export default Container;
