import { Ionicons } from '@expo/vector-icons';
import React, { useMemo } from 'react';
import {
  Image,
  SectionList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { RefreshControl } from 'react-native-web-refresh-control';
import { EmptyBenefitsImage } from '../../assets';
import { useViewWidth } from '../../hooks';
import { BenefitFeature, BenefitResource, TokenResource } from '../../mint';
import { Theme, withTheme } from '../../theme';
import { ItemImage, Wrapper } from '../elements';
import { BenefitIcon } from '../elements/BenefitIcon';
import { diffDate, formatDate, toBenefitInfo, toImageSource } from '../utils';
import { ListData } from './types';

const useEmptyStyles = ({
  theme: { colors, typography },
}: {
  theme: Theme;
}) => {
  return useMemo(() => {
    return StyleSheet.create({
      container: {
        flex: 1,
        alignItems: 'center',
      },
      title: {
        ...typography.paragraphXL,
        marginTop: 43,
        fontWeight: 'bold',
        textAlign: 'center',
        color: colors.textCardPrimary,
      },
    });
  }, [colors, typography]);
};

const Empty = withTheme<{
  theme: Theme;
  viewWidth: number;
}>(({ theme, viewWidth }) => {
  const styles = useEmptyStyles({ theme });
  const { paddingTop, width, height } = useMemo(
    () => ({
      paddingTop: viewWidth * 0.16,
      width: viewWidth * 0.51,
      height: viewWidth * 0.44,
    }),
    [viewWidth],
  );
  return (
    <View style={[styles.container, { paddingTop }]}>
      <Image
        style={{ width, height }}
        resizeMode='contain'
        source={EmptyBenefitsImage}
      />
      <Text style={styles.title}>{'特典は準備中です\nおたのしみに!'}</Text>
    </View>
  );
});

const useListItemStyles = ({
  theme: { colors, typography },
}: {
  theme: Theme;
}) => {
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          flex: 1,
          flexDirection: 'row',
          // alignItems: 'center',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          borderRadius: 16,
          marginHorizontal: 16,
          padding: 16,
          backgroundColor: colors.card,
        },
        image: {
          borderRadius: 12,
        },
        pass: {
          ...typography.textS,
          color: colors.textCardSecondary,
          marginTop: 8,
        },
        title: {
          ...typography.paragraphM,
          fontWeight: 'bold',
          color: colors.textCardPrimary,
        },
        amount: {
          ...typography.paragraphM,
          marginBottom: 16,
          fontWeight: 'bold',
          color: colors.accent,
        },
        deadlineDate: {
          ...typography.textS,
          color: colors.textCardSecondary,
        },
        deadlineHint: {
          ...typography.textS,
          fontWeight: 'bold',
          color: colors.alertPrimary,
        },
      }),
    [colors, typography],
  );
};

const ListItem = withTheme<{
  theme: Theme;
  viewWidth: number;
  last: boolean;
  point: TokenResource;
  benefit: BenefitResource;
  onPress: (b: BenefitResource) => void;
}>(({ theme, viewWidth, last, point, benefit, onPress }) => {
  const styles = useListItemStyles({ theme });
  const deadline = useMemo(() => {
    if (!benefit.expire_time) {
      return null;
    }
    const exp = new Date(benefit.expire_time);
    return {
      date: formatDate('Date', exp),
      remaining: diffDate(exp, new Date()),
    };
  }, [benefit.expire_time]);
  return (
    <TouchableOpacity
      style={[styles.container, last && { marginBottom: 24 }]}
      onPress={() => onPress(benefit)}
    >
      <ItemImage
        containerStyle={styles.image}
        emptyImage='benefit'
        size={152}
        rounded
        source={toImageSource(benefit.profile_image)}
      />
      <View
        style={{
          marginTop: 13,
          marginLeft: 16,
          width: viewWidth * 0.4,
        }}
      >
        <Text style={styles.title} numberOfLines={2}>
          {benefit.name}
        </Text>
        <Text style={styles.amount}>
          {benefit.amount}
          {point.short_unit}
        </Text>
        {benefit.feature === 'pass' && (
          <Text style={styles.pass}>何回でも使える</Text>
        )}
        {deadline && (
          <>
            <Text style={styles.deadlineDate}>
              {benefit.feature === 'in_place' || benefit.feature === 'pass'
                ? '獲得'
                : '申込'}
              期限: {deadline.date}
            </Text>
            {deadline.remaining < 6 && (
              <Text style={styles.deadlineHint}>
                <Ionicons
                  name='alert-circle'
                  size={12}
                  color={theme.colors.alertPrimary}
                />
                もうすぐ
              </Text>
            )}
          </>
        )}
      </View>
    </TouchableOpacity>
  );
});

const useSectionHeaderStyles = ({
  theme: { colors, typography },
}: {
  theme: Theme;
}) => {
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingHorizontal: 16,
          width: '100%',
        },
        border: {
          flexGrow: 1,
          height: 2,
          backgroundColor: colors.border,
        },
        titleContainer: {
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: 16,
        },
        icon: {
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          width: 32,
          height: 32,
          borderRadius: 16,
          backgroundColor: colors.primary,
        },
        title: {
          ...typography.textXL,
          marginLeft: 8,
          fontWeight: 'bold',
          color: colors.textBackgroundSecondary,
        },
      }),
    [colors, typography],
  );
};

const SectionHeader = withTheme<{ theme: Theme; feature: BenefitFeature }>(
  ({ theme, feature }) => {
    const styles = useSectionHeaderStyles({ theme });
    const { featureTitle } = useMemo(() => toBenefitInfo(feature), [feature]);
    return (
      <View style={styles.container}>
        <View style={styles.border} />
        <View style={styles.titleContainer}>
          <BenefitIcon feature={feature} size={32} />
          <Text style={styles.title}>{featureTitle}</Text>
        </View>
        <View style={styles.border} />
      </View>
    );
  },
);

const useBalancesStyles = ({
  theme: { colors, typography },
}: {
  theme: Theme;
}) => {
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: 42,
          paddingRight: 32,
          backgroundColor: colors.card,
          width: '100%',
          height: 59,
          borderTopColor: colors.border,
          borderTopWidth: 2,
        },
        label: {
          ...typography.textXL,
          color: colors.textBackgroundSecondary,
          fontWeight: 'bold',
        },
        balances: {
          ...typography.textXL,
          color: colors.accent,
          fontWeight: 'bold',
        },
      }),
    [colors, typography],
  );
};

const Balances = withTheme<{ theme: Theme; balances: number; unit: string }>(
  ({ theme, balances, unit }) => {
    const styles = useBalancesStyles({ theme });
    return (
      <View style={styles.container}>
        <Text style={styles.label}>所有ポイント</Text>
        <Text style={styles.balances}>
          {balances}
          {unit}
        </Text>
      </View>
    );
  },
);

type section = {
  title: BenefitFeature;
  data: BenefitResource[];
};

const featureOrder = ['in_place', 'pass', 'remote', 'delivery'];

type Props = {
  point: TokenResource;
  balances: number;
  data: ListData<BenefitResource>;
  onPressItem: ({ id: string }) => void;
};

const Component: React.FC<Props> = ({
  point,
  balances,
  data: { data, refreshing, onRefresh },
  onPressItem,
}) => {
  const viewWidth = useViewWidth();
  const sections = useMemo(
    () =>
      data
        .sort(({ feature: x }, { feature: y }) =>
          featureOrder.indexOf(x) < featureOrder.indexOf(y) ? -1 : 1,
        )
        .reduce((sections, b) => {
          const prev = sections.slice(-1)[0];
          const feature = b.feature === 'pass' ? 'in_place' : b.feature;
          if (!prev || prev.title !== feature) {
            sections.push({ title: b.feature, data: [b] });
          } else {
            prev.data.push(b);
          }
          return sections;
        }, [] as section[]),
    [data],
  );
  return (
    <Wrapper
      FooterComponent={<Balances balances={balances} unit={point.short_unit} />}
    >
      <SectionList
        style={{ paddingTop: 16 }}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        sections={sections}
        keyExtractor={(item) => item.id}
        renderItem={({ item, section: { data } }) => (
          <ListItem
            viewWidth={viewWidth}
            last={data[data.length - 1].id === item.id}
            point={point}
            benefit={item}
            onPress={onPressItem}
          />
        )}
        renderSectionHeader={({ section }) => (
          <SectionHeader feature={section.title} />
        )}
        ListEmptyComponent={() => <Empty viewWidth={viewWidth} />}
        ItemSeparatorComponent={() => <View style={{ height: 16 }} />}
        SectionSeparatorComponent={() => <View style={{ height: 16 }} />}
        ListFooterComponent={() => <View style={{ height: 100 }} />}
      />
    </Wrapper>
  );
};

export type { Props as BenefitListProps };
export { Component as BenefitList };
