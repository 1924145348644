import { Ionicons } from '@expo/vector-icons';
import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useViewWidth } from '../../hooks';
import { Ticket } from '../../states/point';
import { Theme, withTheme } from '../../theme';
import { ItemImage, Wrapper } from '../elements';
import {
  formatDate,
  getTicketReceptionTime,
  toImageSource,
  toTicketInfo,
} from '../utils';
import { TicketInfo } from '../utils/types';

const useFooterStyles = ({
  theme: { colors, typography },
}: {
  theme: Theme;
}) => {
  return useMemo(() => {
    return StyleSheet.create({
      container: {
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 12,
        width: '100%',
        height: 80,
        backgroundColor: colors.card,
      },
      available: {
        backgroundColor: colors.primary,
      },
      timerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 12,
        width: '100%',
      },
      timerHint: {
        ...typography.textM,
        fontWeight: 'bold',
        color: colors.card,
      },
      timerClockRow: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 121,
        height: 43,
        borderWidth: 1,
        borderRadius: 4,
        borderColor: colors.card,
      },
      timerClock: {
        ...typography.textXL,
        fontFamily: 'monospace',
        fontWeight: 'bold',
        color: colors.card,
      },
      buttonContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderRadius: 190,
        width: '100%',
        height: 56,
        backgroundColor: colors.card,
      },
      availableButtonContainer: {
        borderWidth: 0,
        backgroundColor: colors.primary,
      },
      buttonTitle: {
        ...typography.textL,
        fontWeight: 'bold',
        color: colors.card,
      },
    });
  }, [colors, typography]);
};

const Footer = withTheme<{
  theme: Theme;
  info: TicketInfo;
}>(({ theme, info }) => {
  const styles = useFooterStyles({ theme });
  return (
    <View style={styles.container}>
      <View
        style={[
          styles.buttonContainer,
          info.available
            ? styles.availableButtonContainer
            : { borderColor: info.color },
        ]}
      >
        <Text style={styles.buttonTitle}>
          {info.available ? (
            '使用する'
          ) : (
            <Text style={{ color: info.color }}>
              <Ionicons name={info.icon} size={18} color={info.color} />
              {' ' + info.title}
            </Text>
          )}
        </Text>
      </View>
    </View>
  );
});

const useStyles = ({ theme: { colors, typography } }: { theme: Theme }) => {
  return useMemo(() => {
    return StyleSheet.create({
      container: {
        alignItems: 'center',
        padding: 16,
        backgroundColor: colors.card,
      },
      image: {
        borderRadius: 16,
      },
      title: {
        ...typography.titleL,
        marginTop: 16,
        color: colors.textCardPrimary,
      },
      description: {
        ...typography.paragraphM,
        padding: 16,
        borderRadius: 16,
        color: colors.textCardPrimary,
        backgroundColor: colors.card,
      },
      infoContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 16,
        padding: 16,
        borderRadius: 16,
        width: '100%',
        backgroundColor: colors.card,
      },
      info: {
        ...typography.paragraphM,
        color: colors.textCardPrimary,
      },
    });
  }, [colors, typography]);
};

type ModalState = 'loading' | 'confirm';

type ComponentRef = {
  setModalState: (s: ModalState) => void;
  hideModal: () => void;
};

type Props = {
  theme: Theme;
  ticket: Ticket;
};

const Component: React.FC<Props> = ({ theme, ticket }) => {
  const viewWidth = useViewWidth();
  const info = useMemo(() => toTicketInfo(theme, ticket), [theme, ticket]);
  const styles = useStyles({ theme });
  const [timer, setTimer] = useState<NodeJS.Timeout | null>();
  const [receptionTime, setReceptionTime] = useState(info.receptionTime);
  useEffect(() => {
    const update = () =>
      setReceptionTime(getTicketReceptionTime(ticket.usedAt));
    update();
    const id = setInterval(update, 250);
    setTimer(id);
    return () => {
      clearInterval(id);
      setTimer(null);
    };
  }, [ticket.usedAt]);
  useEffect(() => {
    if (timer && receptionTime === 0) {
      clearInterval(timer);
      setTimer(null);
    }
  }, [timer, receptionTime]);
  return (
    <Wrapper FooterComponent={<Footer info={{ ...info, receptionTime }} />}>
      <View style={styles.container}>
        <ItemImage
          containerStyle={styles.image}
          emptyImage='benefit'
          size={viewWidth * 0.8}
          rounded
          source={toImageSource(ticket.profileImage)}
        />
        <Text style={styles.title} numberOfLines={2}>
          {ticket.name}
        </Text>
      </View>
      <View style={{ padding: 24 }}>
        <Text style={styles.description}>{ticket.description}</Text>
        <View style={styles.infoContainer}>
          <Text style={styles.info}>申込日時</Text>
          <Text style={styles.info}>
            {formatDate('DateTime', ticket.usedAt)}
          </Text>
        </View>
        <View style={styles.infoContainer}>
          <Text style={styles.info}>ご連絡先</Text>
          <Text style={styles.info}>{ticket.email}</Text>
        </View>
        {ticket.feature === 'delivery' && (
          <View style={styles.infoContainer}>
            <Text style={styles.info}>お届け先</Text>
            <Text style={styles.info}>
              {'〒 ' +
                ticket.zipcode +
                '\n' +
                ticket.addressLine1 +
                '\n' +
                ticket.addressLine2 +
                '\n' +
                ticket.addressee}
            </Text>
          </View>
        )}
      </View>
    </Wrapper>
  );
};

export type { ComponentRef as OrderDetailRef, Props as OrderDetailProps };
export const OrderDetail = withTheme(Component);
