import {
  NavigationContainer,
  useNavigationContainerRef,
} from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { AppState, AppStateStatus } from 'react-native';
import {
  Provider as ReduxProvider,
  useDispatch,
  useSelector,
} from 'react-redux';
import { RootParamList } from '.';
import * as analytics from '../analytics';
import { ModalProvider } from '../containers';
import {
  operations as appinfoOperations,
  selectors as appinfoSelectors,
} from '../states/appinfo';
import { selectors as pointSelectors } from '../states/point';
import store from '../states/store';
import { ThemeProvider } from '../theme';
import LinkingConfiguration from './LinkingConfiguration';
import { RootNavigation } from './RootNavigation';
import { getDocumentTitleOptions } from './utils';

const Navigation: React.FC = () => {
  const dispatch = useDispatch();
  const sourceVersion = useSelector(appinfoSelectors.getSourceVersion);
  const checkVersionTask = useSelector(appinfoSelectors.getCheckVersionTask);
  const point = useSelector(pointSelectors.point);
  const routeRef = useRef<string>();
  const navRef = useNavigationContainerRef<RootParamList>();
  useEffect(() => {
    const f = (status: AppStateStatus) => {
      if (sourceVersion === 'local') {
        analytics.setUserProperty('client', 'mint-ext-app/' + sourceVersion);
      } else if (status === 'active') {
        dispatch(appinfoOperations.checkVersion());
      }
    };
    f('active' as const);
    AppState.addEventListener('change', f);
    return () => AppState.removeEventListener('change', f);
  }, [dispatch, sourceVersion]);
  useEffect(() => {
    if (checkVersionTask.status === 'success') {
      if (sourceVersion !== checkVersionTask.version) {
        navigator.serviceWorker
          .getRegistrations()
          .then((regs) => Promise.all(regs.map((r) => r.unregister())))
          .then(() => window.location.reload());
      } else {
        analytics.setUserProperty('client', 'mint-ext-app/' + sourceVersion);
      }
    }
  }, [sourceVersion, checkVersionTask]);
  const documentTitle = useMemo(() => getDocumentTitleOptions(point), [point]);
  const onNavigationStateChange = useCallback(() => {
    const routeName = navRef?.current?.getCurrentRoute()?.name;
    if (routeRef.current !== routeName) {
      if (__DEV__) {
        console.info(
          'Routes',
          navRef?.getRootState().routes.map((r) => r.name),
        );
      }
      analytics.setCurrentScreen(routeName);
      routeRef.current = routeName;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onPressSettings = useCallback(() => {
    navRef.navigate('AccountSettings');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <NavigationContainer
      documentTitle={documentTitle}
      ref={navRef}
      linking={LinkingConfiguration}
      onReady={onNavigationStateChange}
      onStateChange={onNavigationStateChange}
    >
      <ThemeProvider>
        <ModalProvider onPressSettings={onPressSettings}>
          <RootNavigation />
        </ModalProvider>
      </ThemeProvider>
    </NavigationContainer>
  );
};

export * from './SignInActions';
export * from './types';
export default () => {
  return (
    <ReduxProvider store={store}>
      <Navigation />
    </ReduxProvider>
  );
};
