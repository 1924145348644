import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AccountProfileEdit,
  AccountProfileEditForm,
} from '../components/pages';
import { useAuth, useGoBack, useNavigationHeader, usePoint } from '../hooks';
import { AccountProfileNavigation, AccountProfileRoute } from '../navigation';
import {
  operations as accountOperations,
  selectors as accountSelectors,
} from '../states/account';

type ContainerProps = {
  navigation: AccountProfileNavigation;
  route: AccountProfileRoute;
};

const Container: React.FC<ContainerProps> = ({ navigation }) => {
  useAuth();
  usePoint();
  const dispatch = useDispatch();
  const taskStatus = useSelector(accountSelectors.getUpdateProfileTask);
  const profile = useSelector(accountSelectors.getProfile);
  const goBack = useGoBack();
  useNavigationHeader(
    () => ({ title: 'プロフィールの編集', left: goBack }),
    [goBack],
  );
  useEffect(() => {
    if (taskStatus === 'success') {
      dispatch(accountOperations.endUpdateProfile());
      goBack.onPress();
    }
  }, [navigation, dispatch, goBack, taskStatus]);
  const onPressChange = useCallback(
    ({ name, screenName, image }: AccountProfileEditForm) =>
      dispatch(
        accountOperations.updateProfile({
          name,
          screenName,
          imageUri: image ? image.uri : null,
        }),
      ),
    [dispatch],
  );
  return (
    <AccountProfileEdit
      profile={profile}
      loading={taskStatus === 'in_progress'}
      updated={taskStatus === 'success'}
      onPressChange={onPressChange}
    />
  );
};

export { Container as AccountProfileEditView };
export default Container;
