import { ActionType } from 'redux-actions-type';
import { TicketListType } from '.';
import {
  BenefitResource,
  LotteryTicketResource,
  Problem,
  QuestResource,
  SiteUISettings,
  TicketDefaultValuesResource,
  TokenHolderResource,
  TokenNoticeResource,
  TokenResource,
  TokenStampResource,
  TokenTransactionResource,
} from '../../mint';
import { operations as errorOperations } from '../error';
import { Ticket } from './types';

export const ActionTypes = {
  GET_POINT: '@@point/GET_POINT' as const,
  GET_WALLET: '@@point/GET_WALLET' as const,
  INIT_WALLET: '@@point/INIT_WALLET' as const,
  UPDATE_HOLDER_NOTIF: '@@point/UPDATE_HOLDER_NOTIF' as const,
  POINT_ACQUISITION: '@@point/POINT_ACQUISITION' as const,
  // GET_TRANSACTION: '@@point/GET_TRANSACTION' as const,
  LIST_TRANSACTION: '@@point/LIST_TRANSACTION' as const,
  // GET_NOTICE: '@@point/GET_NOTICE' as const,
  LIST_NOTICE: '@@point/LIST_NOTICE' as const,
  MARK_AS_READ_NOTICE: '@@point/MARK_AS_READ_NOTICE' as const,
  MARK_AS_UNREAD_NOTICE: '@@point/MARK_AS_UNREAD_NOTICE' as const,
  // GET_BENEFIT: '@@point/GET_BENEFIT' as const,
  LIST_BENEFIT: '@@point/LIST_BENEFIT' as const,
  // GET_TICKET: '@@point/LIST_TICKET' as const,
  LIST_TICKET: '@@point/LIST_TICKET' as const,
  ISSUE_TICKET: '@@point/ISSUE_TICKET' as const,
  USE_TICKET: '@@point/USE_TICKET' as const,
  ORDER_TICKET: '@@point/ORDER_TICKET' as const,
  GET_TICKET_DEFAULT_VALUES: '@@point/GET_TICKET_DEFAULT_VALUES' as const,
  GET_LOTTERY: '@@point/GET_LOTTERY' as const,
  CAPTURE_LOTTERY: '@@point/SET_LOTTERY_TASK' as const,
};

const actions = {
  setError: errorOperations.setError,

  startGetPoint: () => ({
    type: ActionTypes.GET_POINT,
    task: { status: 'in_progress' as const },
  }),
  resetGetPoint: () => ({
    type: ActionTypes.GET_POINT,
    task: { status: '' as const },
  }),
  successGetPoint: (v: {
    clientId: string;
    token: TokenResource;
    introStamp: TokenStampResource;
    siteui: SiteUISettings;
    benefits: BenefitResource[];
  }) => ({
    type: ActionTypes.GET_POINT,
    task: { status: 'success' as const, ...v },
  }),

  startGetWallet: () => ({
    type: ActionTypes.GET_WALLET,
    task: { status: 'in_progress' as const },
  }),
  resetGetWallet: () => ({
    type: ActionTypes.GET_WALLET,
    task: { status: '' as const },
  }),
  successGetWallet: (v: {
    acquisitionInfo: TokenTransactionResource;
    holder: TokenHolderResource;
    notices: TokenNoticeResource[];
    unreadNoticeCount: number;
    transactions: TokenTransactionResource[];
    availableVouchers: Ticket[];
    allVouchers: Ticket[];
    orders: Ticket[];
    quests: QuestResource[];
    checkinStampIDs: string[];
    introStampIDs: string[];
  }) => ({
    type: ActionTypes.GET_WALLET,
    task: { status: 'success' as const, ...v },
  }),
  initWallet: () => ({ type: ActionTypes.INIT_WALLET }),

  startUpdateHolderNotification: () => ({
    type: ActionTypes.UPDATE_HOLDER_NOTIF,
    task: { status: 'in_progress' as const, enabled: false },
  }),
  resetUpdateHolderNotification: () => ({
    type: ActionTypes.UPDATE_HOLDER_NOTIF,
    task: { status: '' as const, enabled: false },
  }),
  successUpdateHolderNotification: (enabled: boolean) => ({
    type: ActionTypes.UPDATE_HOLDER_NOTIF,
    task: { status: 'success' as const, enabled },
  }),

  startPointAcquisition: () => ({
    type: ActionTypes.POINT_ACQUISITION,
    task: { status: 'in_progress' as const },
  }),
  resetPointAcquisition: () => ({
    type: ActionTypes.POINT_ACQUISITION,
    task: { status: '' as const },
  }),
  errorPointAcquisition: (err: Problem) => ({
    type: ActionTypes.POINT_ACQUISITION,
    task: { status: 'error' as const, error: err },
  }),
  successPointAcquisition: (transaction: TokenTransactionResource) => ({
    type: ActionTypes.POINT_ACQUISITION,
    task: { status: 'success' as const, transaction },
  }),

  startListTransaction: () => ({
    type: ActionTypes.LIST_TRANSACTION,
    task: { status: 'in_progress' as const },
  }),
  resetListTransaction: () => ({
    type: ActionTypes.LIST_TRANSACTION,
    task: { status: '' as const },
  }),
  successListTransaction: (transactions: TokenTransactionResource[]) => ({
    type: ActionTypes.LIST_TRANSACTION,
    task: { status: 'success' as const, transactions },
  }),

  startListNotice: () => ({
    type: ActionTypes.LIST_NOTICE,
    task: { status: 'in_progress' as const },
  }),
  resetListNoticeTask: () => ({
    type: ActionTypes.LIST_NOTICE,
    task: { status: '' as const },
  }),
  successListNotice: ({
    notices,
    unread,
  }: {
    notices: TokenNoticeResource[];
    unread: number;
  }) => ({
    type: ActionTypes.LIST_NOTICE,
    task: { status: 'success' as const, notices, unread },
  }),
  markAsReadNotice: ({ id }: { id: string }) => ({
    type: ActionTypes.MARK_AS_READ_NOTICE,
    id,
  }),
  markAsUnreadNotice: ({ id }: { id: string }) => ({
    type: ActionTypes.MARK_AS_UNREAD_NOTICE,
    id,
  }),

  startListBenefit: () => ({
    type: ActionTypes.LIST_BENEFIT,
    task: { status: 'in_progress' as const },
  }),
  resetListBenefit: () => ({
    type: ActionTypes.LIST_BENEFIT,
    task: { status: '' as const },
  }),
  successListBenefit: (benefits: BenefitResource[]) => ({
    type: ActionTypes.LIST_BENEFIT,
    task: { status: 'success' as const, benefits },
  }),

  startListTicket: ({ type }: { type: TicketListType }) => ({
    type: ActionTypes.LIST_TICKET,
    task: {
      type,
      status: 'in_progress' as const,
      tickets: undefined,
    },
  }),
  resetListTicket: ({ type }: { type: TicketListType }) => ({
    type: ActionTypes.LIST_TICKET,
    task: { type, status: '' as const, tickets: undefined },
  }),
  successListTicket: ({
    type,
    tickets,
  }: {
    type: TicketListType;
    tickets: Ticket[];
  }) => ({
    type: ActionTypes.LIST_TICKET,
    task: { type, status: 'success' as const, tickets },
  }),

  startIssueTicket: () => ({
    type: ActionTypes.ISSUE_TICKET,
    task: { status: 'in_progress' as const, ticket: undefined },
  }),
  resetIssueTicket: () => ({
    type: ActionTypes.ISSUE_TICKET,
    task: { status: '' as const, ticket: undefined },
  }),
  successIssueTicket: (ticket: Ticket) => ({
    type: ActionTypes.ISSUE_TICKET,
    task: { status: 'success' as const, ticket: ticket },
  }),

  startUseTicket: () => ({
    type: ActionTypes.USE_TICKET,
    task: { status: 'in_progress' as const },
  }),
  resetUseTicket: () => ({
    type: ActionTypes.USE_TICKET,
    task: { status: '' as const },
  }),
  successUseTicket: (ticket: Ticket) => ({
    type: ActionTypes.USE_TICKET,
    task: { status: 'success' as const, ticket },
  }),

  startOrderTicket: () => ({
    type: ActionTypes.ORDER_TICKET,
    task: { status: 'in_progress' as const },
  }),
  resetOrderTicket: () => ({
    type: ActionTypes.ORDER_TICKET,
    task: { status: '' as const },
  }),
  successOrderTicket: (ticket: Ticket) => ({
    type: ActionTypes.ORDER_TICKET,
    task: { status: 'success' as const, ticket },
  }),

  startGetTicketDefaultVAlues: () => ({
    type: ActionTypes.GET_TICKET_DEFAULT_VALUES,
    task: { status: 'in_progress' as const, values: undefined },
  }),
  resetGetTicketDefaultVAlues: () => ({
    type: ActionTypes.GET_TICKET_DEFAULT_VALUES,
    task: { status: '' as const, values: undefined },
  }),
  successGetTicketDefaultVAlues: ({
    values,
  }: {
    values: TicketDefaultValuesResource;
  }) => ({
    type: ActionTypes.GET_TICKET_DEFAULT_VALUES,
    task: { status: 'success' as const, values },
  }),

  startGetLottery: () => ({
    type: ActionTypes.GET_LOTTERY,
    task: {
      status: 'in_progress' as const,
      lottery: undefined as LotteryTicketResource,
    },
  }),
  errorGetLottery: () => ({
    type: ActionTypes.GET_LOTTERY,
    task: {
      status: 'error' as const,
      lottery: undefined as LotteryTicketResource,
    },
  }),
  successGetLottery: (lottery?: LotteryTicketResource) => ({
    type: ActionTypes.GET_LOTTERY,
    task: { status: 'success' as const, lottery },
  }),

  startCaptureLottery: () => ({
    type: ActionTypes.CAPTURE_LOTTERY,
    task: {
      status: 'in_progress' as const,
      isWinning: false,
      ticket: undefined,
      transaction: undefined,
    },
  }),
  errorCaptureLottery: () => ({
    type: ActionTypes.CAPTURE_LOTTERY,
    task: {
      status: 'error' as const,
      isWinning: false,
      ticket: undefined,
      transaction: undefined,
    },
  }),
  successCaptureLottery: (v: {
    isWinning: boolean;
    ticket?: Ticket;
    transaction?: TokenTransactionResource;
  }) => ({
    type: ActionTypes.CAPTURE_LOTTERY,
    task: { status: 'success' as const, ...v },
  }),
};

export type Actions = ActionType<typeof actions>;

export default actions;
