import React, { useMemo } from 'react';
import { FlatList, Image, StyleSheet, Text, View } from 'react-native';
import { RefreshControl } from 'react-native-web-refresh-control';
import { EmptyTicketsImage } from '../../assets';
import { useViewWidth } from '../../hooks';
import { Ticket } from '../../states/point';
import { Theme, withTheme } from '../../theme';
import { Button, TicketListItem, Wrapper } from '../elements';
import { ListData } from './types';

const useEmptyStyles = ({
  theme: { colors, typography },
}: {
  theme: Theme;
}) => {
  return useMemo(() => {
    return StyleSheet.create({
      container: {
        flex: 1,
        alignItems: 'center',
      },
      title: {
        ...typography.paragraphXL,
        marginTop: 43,
        fontWeight: 'bold',
        textAlign: 'center',
        color: colors.textCardPrimary,
      },
      button: {
        marginTop: 32,
        width: 140,
        height: 45,
      },
    });
  }, [colors, typography]);
};

const Empty = withTheme<{
  theme: Theme;
  viewWidth: number;
  onPress: () => void;
}>(({ theme, viewWidth, onPress }) => {
  const styles = useEmptyStyles({ theme });
  const { paddingTop, width, height } = useMemo(
    () => ({
      paddingTop: viewWidth * 0.1,
      width: viewWidth * 0.51,
      height: viewWidth * 0.44,
    }),
    [viewWidth],
  );
  return (
    <View style={[styles.container, { paddingTop }]}>
      <Image
        style={{ width, height }}
        resizeMode='contain'
        source={EmptyTicketsImage}
      />
      <Text style={styles.title}>
        {'使用できる特典がありません\nまずは特典に交換してみましょう'}
      </Text>
      <Button style={styles.button} title='交換する' onPress={onPress} />
    </View>
  );
});

type Props = {
  data: ListData<Ticket>;
  onPressItem: (t: Ticket) => void;
  onPressEmpty: () => void;
};

const Component: React.FC<Props> = ({
  data: { data, refreshing, onRefresh, onEndReached },
  onPressItem,
  onPressEmpty,
}) => {
  const viewWidth = useViewWidth();
  return (
    <Wrapper scrollDisabled>
      <FlatList<Ticket>
        style={{ paddingTop: 16 }}
        contentContainerStyle={{ marginHorizontal: 16 }}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        data={data}
        renderItem={({ item }) => (
          <TicketListItem
            viewWidth={viewWidth}
            ticket={item}
            onPress={() => onPressItem(item)}
          />
        )}
        ListEmptyComponent={() => (
          <Empty viewWidth={viewWidth} onPress={onPressEmpty} />
        )}
        keyExtractor={(item) => item.id}
        ItemSeparatorComponent={() => <View style={{ height: 16 }} />}
        ListFooterComponent={() => <View style={{ height: 100 }} />}
        onEndReached={onEndReached}
        onEndReachedThreshold={0.1}
      />
    </Wrapper>
  );
};

export type { Props as OrderListProps };
export { Component as OrderList };
