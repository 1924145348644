import { Ionicons } from '@expo/vector-icons';
import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useViewWidth } from '../../hooks';
import {
  BenefitResource,
  TokenHolderResource,
  TokenResource,
} from '../../mint';
import { Theme, withTheme } from '../../theme';
import { Button, ItemImage, Loading, Wrapper } from '../elements';
import { BenefitIcon } from '../elements/BenefitIcon';
import { diffDate, formatDate, toBenefitInfo, toImageSource } from '../utils';

const useStyles = ({ theme: { colors, typography } }: { theme: Theme }) => {
  return useMemo(
    () =>
      StyleSheet.create({
        deadlineContainer: {
          alignItems: 'center',
          height: 35,
          backgroundColor: '#f8cacc',
        },
        deadlineTitle: {
          ...typography.textM,
          marginTop: 8,
          color: colors.alertPrimary,
        },
        imageContainer: {
          alignItems: 'center',
          paddingTop: 16,
          backgroundColor: colors.card,
        },
        image: {
          borderRadius: 16,
        },
        titleContiner: {
          justifyContent: 'center',
          alignItems: 'center',
          paddingHorizontal: 16,
          backgroundColor: colors.card,
        },
        title: {
          ...typography.titleL,
          color: colors.textCardPrimary,
        },
        featureHint: {
          ...typography.textM,
          fontWeight: 'bold',
          color: colors.primary,
          marginTop: 8,
        },
        infoContainer: {
          padding: 16,
          borderRadius: 16,
          backgroundColor: colors.card,
        },
        dateInfoContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 16,
          padding: 16,
          borderRadius: 16,
          backgroundColor: colors.card,
        },
        info: {
          ...typography.paragraphM,
          color: colors.textCardPrimary,
        },
        footerContainer: {
          justifyContent: 'space-between',
          paddingVertical: 16,
          paddingHorizontal: 24,
          width: '100%',
          height: 122,
          backgroundColor: colors.card,
        },
        footerAmountRow: {
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        },
        footerAmount: {
          ...typography.textXL,
          fontWeight: 'bold',
          color: colors.accent,
        },
        footerAmountSuffix: {
          ...typography.textL,
          color: colors.textCardPrimary,
          marginRight: 16,
        },
        footerFeature: {
          ...typography.textL,
          fontWeight: 'bold',
          color: colors.accent,
          marginLeft: 4,
        },
        footerButton: { borderRadius: 190, height: 56 },
        footerButtonTitle: {
          ...typography.textL,
          color: colors.card,
        },
      }),
    [colors, typography],
  );
};

const Footer: React.FC<{
  styles: ReturnType<typeof useStyles>;
  point: TokenResource;
  holder: TokenHolderResource;
  benefit: BenefitResource;
  onPress: () => void;
}> = ({ styles, point, holder, benefit, onPress }) => {
  const { featureTitle, issueShortTitle, issueTitle } = useMemo(
    () => toBenefitInfo(benefit?.feature),
    [benefit],
  );
  return (
    <View style={styles.footerContainer}>
      <View style={styles.footerAmountRow}>
        <Text
          style={styles.footerAmount}
        >{`${benefit.amount}${point.short_unit}`}</Text>
        <Text style={styles.footerAmountSuffix}>{issueShortTitle}</Text>
        <BenefitIcon feature={benefit?.feature} size={20} />
        <Text style={styles.footerFeature}>{featureTitle}</Text>
      </View>
      <Button
        style={styles.footerButton}
        titleStyle={styles.footerButtonTitle}
        title={
          benefit.amount > holder.balances
            ? `${point.unit}が足りません`
            : issueTitle
        }
        disabled={benefit.amount > holder.balances}
        onPress={onPress}
      />
    </View>
  );
};

type Props = {
  theme: Theme;
  point: TokenResource;
  holder: TokenHolderResource;
  benefit: BenefitResource;
  onPressIssue: () => void;
};

const Component: React.FC<Props> = ({
  theme,
  point,
  holder,
  benefit,
  onPressIssue,
}) => {
  const viewWidth = useViewWidth();
  const styles = useStyles({ theme });
  const deadline = useMemo(() => {
    if (!benefit) {
      return null;
    }
    const benefitExp = benefit.expire_time && new Date(benefit.expire_time);
    return {
      ticketExp:
        benefit.ticket_expire_time &&
        formatDate('DateTime', new Date(benefit.ticket_expire_time)),
      benefitExp: benefitExp && formatDate('DateTime', benefitExp),
      remaining: benefitExp && diffDate(benefitExp, new Date()),
    };
  }, [benefit]);
  const { issueShortTitle, isVoucher } = useMemo(
    () => toBenefitInfo(benefit?.feature),
    [benefit],
  );
  if (!benefit) {
    return <Loading title='通信中' />;
  }
  return (
    <Wrapper
      style={{ paddingBottom: 24 }}
      FooterComponent={
        <Footer
          styles={styles}
          point={point}
          holder={holder}
          benefit={benefit}
          onPress={onPressIssue}
        />
      }
    >
      {deadline && deadline.remaining < 6 && (
        <View style={styles.deadlineContainer}>
          <Text style={[styles.deadlineTitle]}>
            <Ionicons
              name='alert-circle'
              size={14}
              color={theme.colors.alertPrimary}
            />
            {issueShortTitle}期限が迫っています
          </Text>
        </View>
      )}
      <View style={styles.imageContainer}>
        <ItemImage
          containerStyle={styles.image}
          emptyImage='benefit'
          size={viewWidth * 0.88}
          rounded
          source={toImageSource(benefit.profile_image)}
        />
      </View>
      <View
        style={[
          styles.titleContiner,
          { height: deadline && deadline.remaining < 6 ? 125 : 96 },
        ]}
      >
        <Text style={styles.title} numberOfLines={2}>
          {benefit.name}
        </Text>
        {(benefit.feature === 'in_place' || benefit.feature === 'pass') && (
          <Text style={styles.featureHint}>
            {benefit.feature === 'in_place'
              ? '1回だけ使える'
              : '何回でも使える'}
          </Text>
        )}
      </View>
      <View
        style={{
          justifyContent: 'center',
          paddingTop: 24,
          paddingHorizontal: 24,
        }}
      >
        <View style={styles.infoContainer}>
          <Text style={styles.info}>{benefit.description}</Text>
        </View>
        {deadline?.benefitExp && (
          <View style={styles.dateInfoContainer}>
            <Text style={styles.info}>{issueShortTitle}期限</Text>
            <Text style={styles.info}>{deadline.benefitExp}</Text>
          </View>
        )}
        {deadline?.ticketExp && isVoucher && (
          <View style={styles.dateInfoContainer}>
            <Text style={styles.info}>使用期限</Text>
            <Text style={styles.info}>{deadline.ticketExp}</Text>
          </View>
        )}
      </View>
    </Wrapper>
  );
};

export type { Props as BenefitDetailProps };
export const BenefitDetail = withTheme(Component);
