import { MaterialIcons } from '@expo/vector-icons';
import { HeaderBackButton, HeaderTitle } from '@react-navigation/elements';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useLayoutEffect, useMemo } from 'react';
import { View } from 'react-native';
import { RootParamList } from '../navigation';
import { HeaderButtonIcons } from './constants';
import { HeaderButtonProps } from './types';

type HeaderTitleProps = Parameters<typeof HeaderTitle>[0];
type HeaderBackButtonProps = Parameters<typeof HeaderBackButton>[0];

function None(): React.ReactElement {
  return <></>;
}

function Title(props: HeaderTitleProps) {
  return (
    <HeaderTitle
      {...props}
      style={{
        fontSize: 16,
        fontWeight: 'bold',
      }}
    />
  );
}

function Button({ tintColor, image, onPress }: HeaderButtonProps) {
  return function (props: HeaderBackButtonProps) {
    return (
      <HeaderBackButton
        {...props}
        onPress={onPress}
        backImage={() => (
          <View
            style={{
              width: 30,
              height: 30,
              borderRadius: 15,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MaterialIcons
              name={HeaderButtonIcons[image ?? 'back']}
              size={24}
              color={tintColor ?? 'black'}
            />
          </View>
        )}
      />
    );
  };
}

export function useNavigationHeader(
  factory?: () => {
    title?: string;
    transparent?: boolean;
    left?: HeaderButtonProps;
    right?: HeaderButtonProps;
  },
  deps?: React.DependencyList,
) {
  const navigation = useNavigation<StackNavigationProp<RootParamList>>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const options = useMemo(() => (factory ? factory() : {}), deps);
  useLayoutEffect(() => {
    const { title, transparent, left, right } = options;
    navigation.setOptions({
      title,
      headerStyle: { height: 56 },
      headerTitleStyle: { fontSize: 16, fontWeight: 'bold' },
      headerTransparent: transparent ? true : false,
      headerTitleAlign: 'center',
      headerTitle: transparent ? None : Title,
      headerLeft: left ? Button(left) : None,
      headerRight: right ? Button(right) : None,
      headerShadowVisible: false,
    });
  }, [navigation, options]);
}
