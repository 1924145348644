import { AxiosError } from 'axios';
import { ApplicationError } from '../../lib/error';

type ProblemResource = {
  id: string;
  type: string;
  status: number;
  title: string;
  detail: string;
  user_msg: string;
  resource: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: Map<string, any>;
};

export class Problem extends Error {
  isProblem: true;
  id: string;
  type: string;
  status: number;
  title: string;
  detail: string;
  userTitle?: string;
  userMsg: string;
  resource: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: Map<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cause: any;
  constructor(cause) {
    super(errmsg(cause));
    const err = <AxiosError<ProblemResource>>cause;
    this.isProblem = true;
    this.cause = cause;
    this.id = '';
    this.status = 0;
    this.resource = '';
    this.meta = new Map<string, any>();
    if (err.isAxiosError) {
      if (err.response) {
        const { data, status } = err.response;
        this.type = data.type ? data.type : 'unknown';
        this.title = data.title ? data.title : 'unknwon';
        this.detail = data.detail ? data.detail : 'unknwon';
        this.userMsg = data.user_msg ? data.user_msg : '不明なエラー';
        this.id = data.id ? data.id : '';
        this.status = data.status ? data.status : status;
        this.resource = data.resource ? data.resource : '';
        this.meta = data.meta ? data.meta : this.meta;
        switch (this.title) {
          case 'internal_error':
          case 'io_error':
            this.userTitle = this.title.toUpperCase();
            this.userMsg = `APIサーバーがエラーを応答しました\nID: ${this.id}`;
            break;
        }
      } else {
        this.type = 'https://api.themint.jp/errors/client_app/network_error';
        this.title = 'network_error';
        this.detail = this.message;
        this.userMsg = 'APIサーバーとの通信に失敗しました';
      }
    } else if (err instanceof ApplicationError) {
      this.type = 'https://api.themint.jp/errors/client_app/' + err.title;
      this.title = err.title;
      this.detail = err.userMsg;
      this.userTitle = err.userTitle;
      this.userMsg = err.userMsg;
    } else {
      this.type = 'https://api.themint.jp/errors/client_app/unknown';
      this.title = 'internal_error';
      this.detail = this.message;
      this.userMsg = 'アプリケーションエラーが発生しました\n' + this.message;
    }
  }
}

const errmsg = (cause): string => {
  const err = <AxiosError<ProblemResource>>cause;
  if (err.isAxiosError) {
    if (err.response && err.response.data.title) {
      return err.response.data.title;
    }
  }
  return err.message ? err.message : 'unknown';
};
