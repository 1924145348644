import React, { useEffect, useLayoutEffect } from 'react';
import { View } from 'react-native';
import { NotFoundNavigation, NotFoundRoute } from '../navigation';

type Props = {
  navigation: NotFoundNavigation;
  route: NotFoundRoute;
};

const Container: React.FC<Props> = ({ navigation }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'ページが見つかりません',
      headerShown: false,
    });
  }, [navigation]);
  useEffect(() => {
    navigation.reset({ index: 0, routes: [{ name: 'Portal' }] });
  }, [navigation]);
  return <View />;
};

export { Container as NotFoundView };
export default Container;
