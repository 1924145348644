import { createSelector } from 'reselect';
import { State as RootState } from '../store';

export const appinfo = (state: RootState) => state.appinfo;

export default {
  getSourceVersion: createSelector([appinfo], ({ sourceVersion }) => {
    return sourceVersion;
  }),
  getCheckVersionTask: createSelector([appinfo], ({ checkVersionTask }) => {
    return checkVersionTask;
  }),
};
