import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BenefitList } from '../components/pages';
import {
  useGoBack,
  useListData,
  useNavigationHeader,
  usePoint,
} from '../hooks';
import { BenefitListNavigation, BenefitListRoute } from '../navigation';
import {
  operations as pointOperations,
  selectors as pointSelectors,
} from '../states/point';

type ContainerProps = {
  navigation: BenefitListNavigation;
  route: BenefitListRoute;
};

const Container: React.FC<ContainerProps> = ({ navigation }) => {
  const { point } = usePoint();
  const dispatch = useDispatch();
  const { balances } = useSelector(pointSelectors.holder);
  const { status, items } = useSelector(pointSelectors.listBenefit);
  const goBack = useGoBack();
  useNavigationHeader(
    () => ({ title: '特典ショップ', left: goBack }),
    [goBack],
  );
  const onPressItem = useCallback(
    ({ id: benefitId }) => navigation.push('BenefitDetail', { benefitId }),
    [navigation],
  );
  const onLoad = useCallback(
    (v?: { more: boolean }) => dispatch(pointOperations.listBenefit(v)),
    [dispatch],
  );
  const data = useListData({ data: items, status, onLoad });
  return (
    <BenefitList
      point={point}
      balances={balances}
      data={data}
      onPressItem={onPressItem}
    />
  );
};

export { Container as BenefitListView };
export default Container;
