import React from 'react';
import { Image, Pressable, StyleSheet, Text, View } from 'react-native';
import { LineButtonImage } from '../../assets';
import { LoginProvider } from '../../mint';

type Props = {
  provider: LoginProvider;
  width?: string | number;
  onPress: () => void;
};

const styles = StyleSheet.create({
  lineBase: {
    height: 52,
    backgroundColor: '#06c755',
    borderRadius: 7,
    margin: 7,
  },
  lineHover: {
    flex: 1,
    flexDirection: 'row',
    borderRadius: 7,
  },
  lineIcon: {
    width: 52,
    height: 52,
  },
  lineBorder: {
    width: 1,
    height: '100%',
    borderWidth: 0.5,
    borderLeftColor: 'black',
    opacity: 0.08,
  },
  lineText: {
    width: '100%',
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
    alignSelf: 'center',
  },
});

const Line: React.FC<{
  width?: string | number;
  onPress: () => void;
}> = ({ width, onPress }) => (
  <Pressable onPress={onPress} style={[styles.lineBase, width && { width }]}>
    {({ pressed }) => (
      <View
        style={[
          styles.lineHover,
          {
            backgroundColor: pressed ? 'rgba(0, 0, 0, 0.3)' : 'transparent',
          },
        ]}
      >
        <Image source={LineButtonImage} style={styles.lineIcon} />
        <View style={styles.lineBorder} />
        <Text style={styles.lineText}>LINEでログイン</Text>
      </View>
    )}
  </Pressable>
);

const Component: React.FC<Props> = (props) => {
  switch (props.provider.type) {
    case 'line':
      return <Line width={props.width} onPress={props.onPress} />;
  }
};

export type { Props as SocialLoginButtonProps };
export { Component as SocialLoginButton };
