import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../components/elements';
import { usePoint } from '../hooks';
import {
  AuthCallbackNavigation,
  AuthCallbackRoute,
  SignInActions,
} from '../navigation';
import {
  operations as accountOperations,
  selectors as accountSelectors,
} from '../states/account';

type Props = {
  navigation: AuthCallbackNavigation;
  route: AuthCallbackRoute;
};

const Container: React.FC<Props> = ({ navigation, route }) => {
  const isFocused = navigation.isFocused();
  const {
    getPortal: { loaded },
  } = usePoint();
  const params = useMemo(
    () => ({
      code: undefined,
      state: undefined,
      passwordless_verifier: undefined,
      error: undefined,
      ...route.params,
    }),
    [route.params],
  );
  const dispatch = useDispatch();
  const signinTask = useSelector(accountSelectors.getSigninTask);
  useLayoutEffect(() => {
    navigation.setOptions({ title: 'ログイン', headerShown: false });
  }, [navigation]);
  useEffect(() => {
    if (!loaded || signinTask.status !== '') {
      return;
    }
    if (params.code || params.passwordless_verifier) {
      dispatch(
        accountOperations.resumeSignin({
          code: params.code,
          state: params.state,
          passwordlessVerifier: params.passwordless_verifier,
        }),
      );
    } else {
      navigation.reset({ index: 0, routes: [{ name: 'AuthStart' }] });
      if (params.error) {
        dispatch(accountOperations.errorSignInCallback(params.error));
      }
    }
  }, [navigation, loaded, params, signinTask.status, dispatch]);
  useEffect(() => {
    if (!isFocused) {
      return;
    }
    switch (signinTask.status) {
      case 'signed_in':
        navigation.dispatch(SignInActions.next(signinTask));
        break;
      case 'verification':
        navigation.reset({
          index: 1,
          routes: [{ name: 'AuthStart' }, { name: 'AuthVerification' }],
        });
        break;
    }
  }, [navigation, isFocused, signinTask]);
  return <Loading title='通信中' />;
};

export { Container as AuthCallbackView };
export default Container;
