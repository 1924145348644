import Geolocation, {
  GeolocationError,
  GeolocationResponse,
} from '@react-native-community/geolocation';
import { ApplicationError } from '../error';

export const getCurrentPosition = (): Promise<GeolocationResponse> =>
  new Promise<GeolocationResponse>((resolve, reject) =>
    Geolocation.getCurrentPosition(resolve, reject),
  ).catch((e: GeolocationError) => {
    switch (e.code) {
      case e.PERMISSION_DENIED:
        return Promise.reject(
          new ApplicationError('geolocation_permission_denied'),
        );
      case e.POSITION_UNAVAILABLE:
        return Promise.reject(
          new ApplicationError('geolocation_position_unavailable'),
        );
      case e.TIMEOUT:
        return Promise.reject(new ApplicationError('geolocation_timeout'));
      default:
        return Promise.reject(e);
    }
  });
