import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import React from 'react';
import { Easing } from 'react-native';
import {
  AccountEmailEditView,
  AccountPasswordEditView,
  AccountPhoneEditView,
  AccountPhoneVerificationView,
  AccountProfileEditView,
  AccountSettingsView,
  AuthCallbackView,
  AuthLogoutView,
  AuthStartView,
  AuthVerificationView,
  BenefitComposeView,
  BenefitDetailView,
  BenefitListView,
  CameraView,
  HistoryDetailView,
  HistoryListView,
  MembershipView,
  NotFoundView,
  NoticeDetailView,
  NoticeListView,
  OrderDetailView,
  OrderListView,
  PortalView,
  ScannerView,
  VoucherDetailView,
  VoucherListView,
  VoucherPresentView,
} from '../containers';

const Stack = createStackNavigator();

const Navigation: React.FC = () => (
  <Stack.Navigator
    initialRouteName='Portal'
    screenOptions={{
      cardStyle: {
        // On Web, the height of the screen isn't limited to the height of the
        // viewport. This is by design to allow the browser's address bar to
        // hide when scrolling. If this isn't desirable behavior, you can set
        // `cardStyle` to `{ flex: 1 }` to force the screen to fill the viewport.
        // https://reactnavigation.org/docs/stack-navigator#animation-related-options
        flex: 1,
      },
      animationEnabled: true,
      cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      transitionSpec: {
        open: {
          animation: 'timing',
          config: { duration: 300, easing: Easing.bezier(0.4, 0.0, 0.2, 1) },
        },
        close: {
          animation: 'timing',
          config: { duration: 300, easing: Easing.bezier(0.4, 0.0, 0.2, 1) },
        },
      },
    }}
  >
    <Stack.Screen name='NotFound' component={NotFoundView} />

    {/* Auth */}
    <Stack.Screen name='AuthStart' component={AuthStartView} />
    <Stack.Screen name='AuthLogout' component={AuthLogoutView} />
    <Stack.Screen name='AuthVerification' component={AuthVerificationView} />
    <Stack.Screen name='AuthCallback' component={AuthCallbackView} />

    {/* Account */}
    <Stack.Screen name='AccountSettings' component={AccountSettingsView} />
    <Stack.Screen name='AccountProfile' component={AccountProfileEditView} />
    <Stack.Screen name='AccountEmail' component={AccountEmailEditView} />
    <Stack.Screen name='AccountPhone' component={AccountPhoneEditView} />
    <Stack.Screen
      name='AccountPhoneVerification'
      component={AccountPhoneVerificationView}
    />
    <Stack.Screen name='AccountPassword' component={AccountPasswordEditView} />

    {/* Point */}
    <Stack.Screen name='Portal' component={PortalView} />
    <Stack.Screen name='Membership' component={MembershipView} />
    <Stack.Screen name='HistoryList' component={HistoryListView} />
    <Stack.Screen name='HistoryDetail' component={HistoryDetailView} />
    <Stack.Screen name='NoticeList' component={NoticeListView} />
    <Stack.Screen name='NoticeDetail' component={NoticeDetailView} />
    <Stack.Screen name='BenefitList' component={BenefitListView} />
    <Stack.Screen name='BenefitDetail' component={BenefitDetailView} />
    <Stack.Screen name='BenefitCompose' component={BenefitComposeView} />
    <Stack.Screen name='VoucherList' component={VoucherListView} />
    <Stack.Screen name='VoucherDetail' component={VoucherDetailView} />
    <Stack.Screen name='VoucherPresent' component={VoucherPresentView} />
    <Stack.Screen name='OrderList' component={OrderListView} />
    <Stack.Screen name='OrderDetail' component={OrderDetailView} />
    <Stack.Screen name='Scanner' component={ScannerView} />
    <Stack.Screen name='Camera' component={CameraView} />
  </Stack.Navigator>
);

export { Navigation as RootNavigation };
export default Navigation;
