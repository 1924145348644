import { createSelector } from 'reselect';
import { State as RootState } from '../store';

export const sel = (state: RootState) => state.point;

export default {
  clientId: createSelector([sel], (point) => point.clientId),
  point: createSelector([sel], (point) => point.token),
  introStamp: createSelector([sel], (point) => point.introStamp),
  siteUI: createSelector([sel], (point) => point.siteui),
  holder: createSelector([sel], (point) => point.holder),
  holderTitleQuest: createSelector([sel], (point) => point.holderTitleQuest),
  isHolderLoaded: createSelector([sel], (point) => point.holder.wallet !== ''),
  introStampIDs: createSelector([sel], (point) => point.introStampIDs),
  checkinStampIDs: createSelector([sel], (point) => point.checkInStampIDs),
  getPortal: createSelector([sel], (point) => point.getPortal),
  getWallet: createSelector([sel], (point) => point.getWallet),
  updateHolderNotification: createSelector(
    [sel],
    (point) => point.updateHolderNotification,
  ),
  listTransaction: createSelector([sel], (point) => point.listTransaction),
  listNotice: createSelector([sel], (point) => point.listNotice),
  listBenefit: createSelector([sel], (point) => point.listBenefit),
  listAvailableVoucher: createSelector(
    [sel],
    (point) => point.listAvailableVoucher,
  ),
  listAllVoucher: createSelector([sel], (point) => point.listAllVoucher),
  listOrder: createSelector([sel], (point) => point.listOrder),
  pointAcquisition: createSelector([sel], (point) => point.pointAcquisition),
  issueTicket: createSelector([sel], (point) => point.issueTicket),
  useTicket: createSelector([sel], (point) => point.useTicket),
  orderTicket: createSelector([sel], (point) => point.orderTicket),
  ticketDefaultValues: createSelector(
    [sel],
    (point) => point.ticketDefaultValues,
  ),
  getLottery: createSelector([sel], (point) => point.getLottery),
  captureLottery: createSelector([sel], (point) => point.captureLottery),
};
