import React, { useMemo } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { CoinAnimation } from '../../assets';
import { useViewWidth } from '../../hooks';
import { TokenHolderResource, TokenTransactionResource } from '../../mint';
import { Theme, withTheme } from '../../theme';
import { Person, Wrapper } from '../elements';
import { getTransactionTitle, toImageSource } from '../utils';

const useStyles = ({
  theme: { colors, typography },
  viewWidth,
}: {
  theme: Theme;
  viewWidth: number;
}) => {
  return useMemo(() => {
    return StyleSheet.create({
      imageContainer: {
        width: viewWidth,
        height: viewWidth,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.card,
      },
      image: {
        width: viewWidth * 0.5,
        height: viewWidth * 0.5,
      },
      textContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 109,
        backgroundColor: colors.card,
      },
      text: {
        ...typography.paragraphL,
        fontWeight: 'bold',
        color: colors.textCardPrimary,
      },
      messageContainer: {
        alignItems: 'center',
        marginTop: 16,
        marginHorizontal: 16,
        padding: 16,
        borderRadius: 16,
        backgroundColor: colors.card,
      },
      ownerName: {
        ...typography.paragraphS,
        marginTop: 8,
        fontWeight: 'bold',
        color: colors.textCardPrimary,
      },
      message: {
        ...typography.paragraphM,
        marginTop: 16,
        color: colors.textCardPrimary,
      },
    });
  }, [colors, typography, viewWidth]);
};

type Props = {
  theme: Theme;
  holder: TokenHolderResource;
  transaction: TokenTransactionResource;
  checkInStampIDs: string[];
  introStampIDs: string[];
};

const Component: React.FC<Props> = ({
  theme,
  holder,
  transaction: tx,
  checkInStampIDs,
  introStampIDs,
}) => {
  const viewWidth = useViewWidth();
  const styles = useStyles({ theme, viewWidth });
  const {
    token: { owner },
  } = tx;
  const info = useMemo(() => {
    const {
      amount,
      token: { name: pn, unit, short_unit: su },
    } = tx;
    const recv = tx.to.wallet === holder.wallet;
    return {
      title: getTransactionTitle({
        tx,
        recv,
        checkInStampIDs,
        introStampIDs,
        text: ({ type, recv }) => {
          switch (type) {
            case 'checkin':
              return `チェックインで「${pn}」を${amount}${su}獲得しました`;
            case 'intro':
              return `会員登録で「${pn}」を${amount}${su}獲得しました`;
            case 'benefit':
              return `特典「${tx.meta?.benefit_name}」と交換しました`;
            default:
              return recv
                ? `${unit}を${amount}${su}獲得しました`
                : `${unit}を${amount}${su}消費しました`;
          }
        },
      }),
      message: tx.meta?.message,
    };
  }, [holder, tx, checkInStampIDs, introStampIDs]);
  return (
    <Wrapper style={{ paddingBottom: 40 }}>
      <View style={styles.imageContainer}>
        <Image style={styles.image} source={CoinAnimation} />
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.text}>{info.title}</Text>
      </View>
      {info.message && (
        <View style={styles.messageContainer}>
          <Person
            emptyImage='person'
            size={80}
            source={toImageSource(owner.profile_image)}
          />
          <Text style={styles.ownerName}>{owner.name}</Text>
          <Text style={styles.message}>{info.message}</Text>
        </View>
      )}
    </Wrapper>
  );
};

export type { Props as HistoryDetailProps };
export const HistoryDetail = withTheme(Component);
