import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet, Switch, Text, View } from 'react-native';
import regexp from '../../lib/regexp';
import { Button, TextInput, Wrapper } from '../elements';

const styles = StyleSheet.create({
  button: {
    marginTop: '25px',
  },
});

type FormState = {
  email: string;
  notificationsEnabled: boolean;
  error?: string;
};

type Props = {
  loading: boolean;
  updated: boolean;
  defaultEmailValue: string;
  notificationsEnabled: boolean;
  onPressChange: (form: FormState) => void;
};

const Component: React.FC<Props> = (props) => {
  const initFormState = useMemo(
    (): FormState => ({
      email: props.defaultEmailValue,
      notificationsEnabled: props.notificationsEnabled,
    }),
    [props],
  );
  const [form, setForm] = useState({ ...initFormState });
  const setEmail = useCallback(
    (value: string) =>
      setForm({ ...form, email: value, error: validateEmail(value) }),
    [form],
  );
  const setNotificationsEnabled = useCallback(
    (value: boolean) => setForm({ ...form, notificationsEnabled: value }),
    [form],
  );
  const onPressChange = useCallback(() => {
    props.onPressChange(form);
    setForm(initFormState);
  }, [form, initFormState, props]);
  return (
    <Wrapper style={{ paddingHorizontal: 16 }}>
      <TextInput
        editable={!props.loading && !props.updated}
        label='メールアドレス'
        value={form.email}
        containerStyle={{ marginTop: '25px' }}
        onChangeText={setEmail}
        errorMessage={form.error}
      />
      <View
        style={{
          marginTop: 25,
          paddingLeft: 10,
          paddingRight: 10,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Text>おしらせをEメール通知する</Text>
        <Switch
          value={form.notificationsEnabled}
          onValueChange={setNotificationsEnabled}
        />
      </View>
      <Button
        loading={props.loading}
        disabled={
          props.updated || form.email === '' || (form.error ? true : false)
        }
        title='変更'
        style={styles.button}
        onPress={onPressChange}
      />
    </Wrapper>
  );
};

export type { FormState as AccountEmailForm };
export type { Props as AccountEmailEditProps };
export { Component as AccountEmailEdit };

const validateEmail = (value: string): string | undefined => {
  if (value !== '' && !regexp.email.test(value)) {
    return 'メールアドレスが正しくありません';
  }
  if (value.length > 254) {
    return 'メールアドレスは254文字以内で入力してください';
  }
  return undefined;
};
