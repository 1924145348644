import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { loadAuthTokens, saveAuthTokens } from '../storage';
import { Problem } from './types';

type RequestConfig = AxiosRequestConfig;

type Response<T = any> = AxiosResponse<T>;

type FailedAction = () => Promise<Response<any>>;

type ErrorHandler = (
  fa: FailedAction,
) => (pb: Problem) => Promise<Response<any>>;

const newFactory =
  (handler: ErrorHandler) => (getConfig: () => Promise<RequestConfig>) => {
    const getClient = () => {
      // ヘッダーの値が関数の場合は評価して値をセットする
      return getConfig().then((config) => {
        const headers = config.headers ? { ...config.headers } : {};
        Object.keys(headers).forEach((key) => {
          if (typeof headers[key] === 'function') {
            headers[key] = headers[key]();
          }
        });
        return axios.create({ ...config, headers });
      });
    };
    return {
      get: <T = any>(
        url: string,
        config?: RequestConfig,
      ): Promise<Response<T>> => {
        const action = () => getClient().then((cli) => cli.get<T>(url, config));
        return action().catch((error) => handler(action)(new Problem(error)));
      },
      post: <T = any>(
        url: string,
        data?: any,
        config?: RequestConfig,
      ): Promise<Response<T>> => {
        const action = () =>
          getClient().then((cli) => cli.post<T>(url, data, config));
        return action().catch((error) => handler(action)(new Problem(error)));
      },
    };
  };

const create = (config: RequestConfig) => {
  const factory = newFactory(() => (pb: Problem) => Promise.reject(pb));
  return factory(() => Promise.resolve(config));
};

const createAuthorized = (config: RequestConfig) => {
  const retryWithRefreshedToken =
    (fa: FailedAction) =>
    (pb: Problem): Promise<Response<any | Problem>> => {
      if (pb.title !== 'access_token_expired') {
        return Promise.reject(pb);
      }
      return loadAuthTokens()
        .then(({ clientId, refreshToken }) =>
          axios
            .create({
              baseURL: new URL(config.baseURL).origin + '/api/auth/v0',
            })
            .post<{ access_token: string; refresh_token: string }>('token', {
              client_id: clientId,
              grant_type: 'refresh_token',
              refresh_token: refreshToken,
            })
            .then((response) => {
              saveAuthTokens({
                clientId,
                accessToken: response.data.access_token,
                refreshToken: response.data.refresh_token,
              });
              return fa();
            }),
        )
        .catch((e) => Promise.reject(new Problem(e)));
    };
  const factory = newFactory(retryWithRefreshedToken);
  return factory(() =>
    loadAuthTokens().then(({ accessToken }) => ({
      ...config,
      headers: {
        ...(config.headers ? config.headers : {}),
        Authorization: () => 'Bearer ' + accessToken,
      },
    })),
  );
};

export default { create, createAuthorized };
