import React, { useCallback, useEffect } from 'react';
import { Linking } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { AuthVerification } from '../components/pages';
import { useGoBack, useNavigationHeader, usePoint } from '../hooks';
import {
  AuthVerificationNavigation,
  AuthVerificationRoute,
  SignInActions,
} from '../navigation';
import {
  operations as accountOperations,
  selectors as accountSelectors,
} from '../states/account';

const MINT_INQUIRY_URL = 'https://themint.zendesk.com/hc/ja/requests/new';

type Props = {
  navigation: AuthVerificationNavigation;
  route: AuthVerificationRoute;
};

const Container: React.FC<Props> = ({ navigation }) => {
  const isFocused = navigation.isFocused();
  const { getPortal, point } = usePoint();
  const dispatch = useDispatch();
  const signinTask = useSelector(accountSelectors.getSigninTask);
  const passwordless = useSelector(accountSelectors.getPasswordless);
  const { connection, username, error } = useSelector(
    accountSelectors.getPasswordless,
  );
  const goBack = useGoBack(
    ({ goBack }) => {
      goBack();
      dispatch(accountOperations.cancelSignIn());
    },
    [dispatch],
  );
  useNavigationHeader(
    () => ({ title: '認証コード入力', left: goBack }),
    [goBack],
  );
  useEffect(() => {
    if (!isFocused) {
      return;
    }
    if (signinTask.status === 'signed_in') {
      navigation.dispatch(SignInActions.next(signinTask));
    } else if (signinTask.status !== 'verification') {
      navigation.reset({ index: 0, routes: [{ name: 'AuthStart' }] });
    }
  }, [navigation, isFocused, signinTask]);
  const onChange = useCallback(() => {
    if (passwordless.error) {
      dispatch(accountOperations.clearSignInVerificationError());
    }
  }, [dispatch, passwordless.error]);
  const onPressSubmit = useCallback(
    (code) => dispatch(accountOperations.verifyPasswordlessOTP(code)),
    [dispatch],
  );
  const onPressResend = useCallback(
    () => dispatch(accountOperations.resendPasswordlessOTP()),
    [dispatch],
  );
  return (
    <AuthVerification
      point={getPortal.loaded ? point : null}
      connection={connection}
      value={username}
      error={error}
      onChange={onChange}
      onPressSubmit={onPressSubmit}
      onPressResend={onPressResend}
      onPressBack={goBack.onPress}
      onPressInquiry={() => Linking.openURL(MINT_INQUIRY_URL)}
    />
  );
};

export { Container as AuthVerificationView };
export default Container;
