import { Reducer } from 'redux';
import { SignInTask } from '.';
import * as analytics from '../../analytics';
import { TaskStatus } from '../../lib/task';
import { UserProfile } from '../../mint';
import { saveNextRouteAfterSignIn } from '../../storage';
import { Actions, ActionTypes } from './actions';

type Connection = 'sms' | 'email';

type Passwordless = {
  state?: string;
  verifier: string;
  connection: Connection;
  username: string;
  error?: string;
};

interface State {
  passwordless: Passwordless;
  profile: UserProfile;
  signinTask: SignInTask;
  updateProfileTask: TaskStatus;
  updatePasswordTask: TaskStatus;
  updateEmailTask: TaskStatus;
  updatePhoneNumberTask: {
    status: TaskStatus;
    verificationId?: string;
    value?: string;
  };
  disconnectTwitterTask: TaskStatus;
}

const emptyProfile: UserProfile = {
  loaded: false,
  id: '',
  name: '',
  screenName: '',
  email: '',
  passwordRegistered: false,
  twitterConnected: false,
  facebookConnected: false,
  portraitImage: { id: '', url: '', uploaded: false },
};

const emptyPasswordless: Passwordless = {
  verifier: '',
  connection: 'sms' as Connection,
  username: '',
};

const initialState: State = {
  signinTask: {
    status: '',
    nextRoutes: [],
  },
  passwordless: emptyPasswordless,
  profile: emptyProfile,
  updateProfileTask: '',
  updatePasswordTask: '',
  updateEmailTask: '',
  updatePhoneNumberTask: { status: '' },
  disconnectTwitterTask: '',
};

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.START_SIGNIN_PASSWORDLESS:
      return {
        ...state,
        signinTask: { ...state.signinTask, status: 'verification' },
        passwordless: action.passwordless,
      };
    case ActionTypes.COMPLETE_SIGNIN:
      analytics.setUserId(action.profile.id);
      return {
        ...state,
        existsAuthToken: true,
        signinTask: {
          status: 'signed_in',
          nextRoutes: saveNextRouteAfterSignIn(
            action.nextRoutes.concat(state.signinTask.nextRoutes), // 先頭に追加
          ),
        },
        passwordless: emptyPasswordless,
        profile: action.profile,
      };
    case ActionTypes.SET_SIGNIN_VERIFICATION_ERROR:
      return {
        ...state,
        passwordless: { ...state.passwordless, error: action.error },
      };
    case ActionTypes.CANCEL_SIGNIN:
      return {
        ...state,
        signinTask: { ...state.signinTask, status: 'signed_out' },
        passwordless: emptyPasswordless,
      };
    case ActionTypes.SIGNOUT:
      return {
        ...state,
        existsAuthToken: false,
        signinTask: {
          ...state.signinTask,
          status: 'signed_out',
        },
        profile: emptyProfile,
      };
    case ActionTypes.CONSUME_NEXT_ROUTE_AFTER_SIGNIN:
      return {
        ...state,
        signinTask: {
          ...state.signinTask,
          nextRoutes: saveNextRouteAfterSignIn(
            state.signinTask.nextRoutes.length > 0
              ? state.signinTask.nextRoutes.splice(1)
              : state.signinTask.nextRoutes,
          ),
        },
      };
    case ActionTypes.ADD_NEXT_ROUTE_AFTER_SIGNIN:
      return {
        ...state,
        signinTask: {
          ...state.signinTask,
          nextRoutes: saveNextRouteAfterSignIn(
            state.signinTask.nextRoutes.concat(action.route),
          ),
        },
      };

    case ActionTypes.SET_PROFILE:
      analytics.setUserId(action.profile.id);
      return { ...state, profile: action.profile };

    case ActionTypes.START_UPDATE_PROFILE:
      return { ...state, updateProfileTask: 'in_progress' };
    case ActionTypes.END_UPDATE_PROFILE:
      return { ...state, updateProfileTask: '' };
    case ActionTypes.ERROR_UPDATE_PROFILE:
      return { ...state, updateProfileTask: 'error' };
    case ActionTypes.SUCCESS_UPDATE_PROFILE:
      return {
        ...state,
        updateProfileTask: 'success',
        profile: {
          ...state.profile,
          name: action.name,
          screenName: action.screenName,
          ...(action.image ? { portraitImage: action.image } : {}),
        },
      };

    case ActionTypes.START_UPDATE_EMAIL:
      return { ...state, updateEmailTask: 'in_progress' };
    case ActionTypes.END_UPDATE_EMAIL:
      return { ...state, updateEmailTask: '' };
    case ActionTypes.ERROR_UPDATE_EMAIL:
      return { ...state, updateEmailTask: 'error' };
    case ActionTypes.SUCCESS_UPDATE_EMAIL:
      return {
        ...state,
        updateEmailTask: 'success',
        profile: { ...state.profile, email: action.email },
      };

    case ActionTypes.SET_UPDATE_PHONE_NUMBER:
      return {
        ...state,
        updatePhoneNumberTask: {
          ...state.updatePhoneNumberTask,
          verificationId: action.verificationId,
          value: action.phoneNumber,
        },
      };
    case ActionTypes.START_UPDATE_PHONE_NUMBER:
      return {
        ...state,
        updatePhoneNumberTask: {
          ...state.updatePhoneNumberTask,
          status: 'in_progress',
        },
      };
    case ActionTypes.END_UPDATE_PHONE_NUMBER:
      return {
        ...state,
        updatePhoneNumberTask: { status: '' },
      };
    case ActionTypes.ERROR_UPDATE_PHONE_NUMBER:
      return {
        ...state,
        updatePhoneNumberTask: {
          ...state.updatePhoneNumberTask,
          status: 'error',
        },
      };
    case ActionTypes.SUCCESS_UPDATE_PHONE_NUMBER:
      return {
        ...state,
        updatePhoneNumberTask: {
          ...state.updatePhoneNumberTask,
          status: 'success',
        },
        profile: {
          ...state.profile,
          phoneNumber: state.updatePhoneNumberTask.value,
        },
      };

    case ActionTypes.START_UPDATE_PASSWORD:
      return { ...state, updatePasswordTask: 'in_progress' };
    case ActionTypes.END_UPDATE_PASSWORD:
      return { ...state, updatePasswordTask: '' };
    case ActionTypes.ERROR_UPDATE_PASSWORD:
      return { ...state, updatePasswordTask: 'error' };
    case ActionTypes.SUCCESS_UPDATE_PASSWORD:
      return { ...state, updatePasswordTask: 'success' };

    case ActionTypes.START_DISCONNECT_TWITTER:
      return { ...state, disconnectTwitterTask: 'in_progress' };
    case ActionTypes.END_DISCONNECT_TWITTER:
      return { ...state, disconnectTwitterTask: '' };
    case ActionTypes.ERROR_DISCONNECT_TWITTER:
      return { ...state, disconnectTwitterTask: 'error' };
    case ActionTypes.SUCCESS_DISCONNECT_TWITTER:
      return {
        ...state,
        disconnectTwitterTask: 'success',
        profile: { ...state.profile, twitterConnected: false },
      };

    default:
      return state;
  }
};

export default reducer;
