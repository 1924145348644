import { ImageRequireSource } from 'react-native';

export const BenefitImage: ImageRequireSource = require('./benefit.png');
export const NoticeImage: ImageRequireSource = require('./notice.png');
export const DefaultPointCoverImage: ImageRequireSource = require('./point_cover_default.png');
export const CoinAnimation: ImageRequireSource = require('./coin.gif');
export const PartyPopperAnimation: ImageRequireSource = require('./party_popper.gif');
export const LineButtonImage: ImageRequireSource = require('./line/android_hdpi_line_60.png');
export const EmptyBenefitsImage: ImageRequireSource = require('./empty_benefits.png');
export const EmptyNoticesImage: ImageRequireSource = require('./empty_notices.png');
export const EmptyTicketsImage: ImageRequireSource = require('./empty_tickets.png');
