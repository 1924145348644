import React, { useMemo } from 'react';
import {
  ImageBackground,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import { Person } from '.';
import { DefaultPointCoverImage } from '../../assets';
import { QuestResource, TokenHolderResource, TokenResource } from '../../mint';
import { Theme, withTheme } from '../../theme';
import { toImageSource } from '../utils';

const useStyles = ({
  theme: { colors },
  viewWidth,
}: {
  theme: Theme;
  viewWidth: number;
}) => {
  const fontSize = useMemo(() => {
    const mag = viewWidth > 450 ? 1.5 : 1;
    return {
      xsmall: 10 * mag,
      small: 12 * mag,
      middle: 14 * mag,
      large: 18 * mag,
      xlarge: 36 * mag,
    };
  }, [viewWidth]);
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          overflow: 'hidden',
          justifyContent: 'space-between',
          width: '100%',
          height: viewWidth * 0.52,
          borderRadius: viewWidth * 0.04,
        },
        topContainer: {
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          paddingTop: viewWidth * 0.04, // =16/390
          paddingLeft: viewWidth * 0.04, // =16/390
        },
        pointName: {
          marginLeft: viewWidth * 0.02, // =8/390
          fontWeight: 'bold',
          fontSize: fontSize.middle,
          color: colors.membershipCardText,
        },
        holderTitle: {
          marginBottom: viewWidth * 0.01,
          fontWeight: 'bold',
          marginLeft: viewWidth * 0.02, // =8/390
          fontSize: fontSize.middle,
          color: colors.membershipCardText,
        },
        middleContainer: {
          height: viewWidth * 0.2, // =76/390
          justifyContent: 'center',
        },
        balancesContainer: {
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          paddingRight: viewWidth * 0.04, // =16/390
          height: viewWidth * 0.1, // =40/390
        },
        balances: {
          fontWeight: 'bold',
          fontSize: fontSize.xlarge,
          color: colors.membershipCardText,
        },
        balancesUnit: {
          marginBottom: 3,
          fontWeight: 'bold',
          fontSize: fontSize.large,
          color: colors.membershipCardText,
        },
        progressBarContainer: {
          flexDirection: 'row',
          width: '100%',
          height: viewWidth * 0.04, // =16/390
        },
        progressBarFilled: {
          height: '100%',
          backgroundColor: colors.membershipCardAccent,
        },
        progressBarUnfilled: {
          height: '100%',
          backgroundColor: colors.membershipCardAccent,
          opacity: 0.6,
        },
        progressNextRank: {
          fontWeight: 'bold',
          alignSelf: 'flex-end',
          paddingRight: viewWidth * 0.04, // =16/390
          fontSize: fontSize.middle,
          color: colors.membershipCardText,
        },
        bottomContainer: {
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
          paddingLeft: viewWidth * 0.04, // =16/390
          paddingBottom: viewWidth * 0.04, // =16/390
          width: '100%',
          height: viewWidth * 0.1, // =40/390
        },
        name: {
          fontSize: fontSize.middle,
          color: colors.membershipCardText,
        },
        screenName: {
          fontSize: fontSize.small,
          color: colors.membershipCardText,
        },
      }),
    [colors, viewWidth, fontSize],
  );
};

const Balances: React.FC<{
  styles: ReturnType<typeof useStyles>;
  point: TokenResource;
  holder: TokenHolderResource;
}> = ({ styles, point, holder }) => (
  <View style={styles.balancesContainer}>
    <Text style={styles.balances}>{holder.balances}</Text>
    <Text style={styles.balancesUnit}>{point.short_unit}</Text>
  </View>
);

const Progress: React.FC<{
  styles: ReturnType<typeof useStyles>;
  point: TokenResource;
  holder: TokenHolderResource;
  nextRankPoint: number;
}> = ({ styles, point, holder, nextRankPoint }) => {
  const bar = useMemo(() => {
    const progress = Math.round((holder.balances / nextRankPoint) * 100);
    return {
      filled: { width: `${progress}%` },
      unfilled: { width: `${100 - progress}%` },
    };
  }, [holder.balances, nextRankPoint]);
  return (
    <View>
      <View style={styles.progressBarContainer}>
        <View style={[styles.progressBarFilled, bar.filled]} />
        <View style={[styles.progressBarUnfilled, bar.unfilled]} />
      </View>
      <Text style={styles.progressNextRank}>
        次のランクまで
        {nextRankPoint - holder.balances}
        {point.short_unit}
      </Text>
    </View>
  );
};

type Props = {
  theme: Theme;
  viewWidth: number;
  point: TokenResource;
  holder: TokenHolderResource;
  quest?: QuestResource;
  containerStyle?: StyleProp<ViewStyle>;
  onPress?: () => void;
};

const Component: React.FC<Props> = ({
  theme,
  viewWidth,
  point,
  holder,
  quest,
  containerStyle,
  onPress,
}) => {
  const styles = useStyles({ theme, viewWidth });
  const personSize = useMemo(() => viewWidth * 0.12, [viewWidth]);
  const nextRankPoint = useMemo(() => {
    if (quest) {
      const currentIndex = quest.objectives.findIndex(
        (o) => o.rewards[0].title_id === holder.title_id,
      );
      const nextIndex =
        currentIndex !== -1
          ? currentIndex + 1
          : quest.objectives.findIndex((o) => o.quantity > holder.balances);
      if (nextIndex !== -1 && quest.objectives.length > nextIndex) {
        return quest.objectives[nextIndex].quantity;
      }
    }
    return null;
  }, [holder, quest]);
  return (
    <Pressable onPress={onPress}>
      <ImageBackground
        style={[styles.container, containerStyle]}
        source={toImageSource(point.cover_image) ?? DefaultPointCoverImage}
        resizeMode='cover'
      >
        <View style={{ width: '100%' }}>
          <View style={styles.topContainer}>
            <Person
              emptyImage='point'
              size={personSize}
              source={toImageSource(point.profile_image)}
            />
            <View style={{ flexDirection: 'column' }}>
              <Text style={styles.pointName}>{point.name}</Text>
              {holder.title_name && (
                <Text style={styles.holderTitle}>{holder.title_name}</Text>
              )}
            </View>
          </View>
          <View style={styles.middleContainer}>
            <Balances styles={styles} point={point} holder={holder} />
            {nextRankPoint && (
              <Progress
                styles={styles}
                point={point}
                holder={holder}
                nextRankPoint={nextRankPoint}
              />
            )}
          </View>
        </View>
        <View style={styles.bottomContainer}>
          {holder.name && (
            <Text numberOfLines={1} ellipsizeMode='tail'>
              <Text style={[styles.name, { fontWeight: 'bold' }]}>
                {holder.name}{' '}
              </Text>
              <Text style={styles.screenName}>{holder.screen_name}</Text>
            </Text>
          )}
          {!holder.name && (
            <Text
              numberOfLines={1}
              ellipsizeMode='tail'
              style={[styles.screenName, { fontWeight: 'bold' }]}
            >
              {holder.screen_name}
            </Text>
          )}
        </View>
      </ImageBackground>
    </Pressable>
  );
};

export type { Props as MembershipCardProps };
export const MembershipCard = withTheme(Component);
