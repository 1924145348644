import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../components/elements';
import { BenefitCompose, BenefitComposeForm } from '../components/pages';
import {
  useAuth,
  useBenefit,
  useGoBack,
  useNavigationHeader,
  usePoint,
} from '../hooks';
import { useModal } from '../hooks/useModal';
import { BenefitComposeNavigation, BenefitComposeRoute } from '../navigation';
import { selectors as accountSelectors } from '../states/account';
import {
  operations as pointOperations,
  selectors as pointSelectors,
} from '../states/point';

type Props = {
  navigation: BenefitComposeNavigation;
  route: BenefitComposeRoute;
};

const Container: React.FC<Props> = ({ navigation, route }) => {
  useAuth();
  const { point } = usePoint();
  const { benefitId } = { benefitId: undefined, ...route.params };
  const dispatch = useDispatch();
  const profile = useSelector(accountSelectors.getProfile);
  const orderTicketTask = useSelector(pointSelectors.orderTicket);
  const { values: defaultValues } = useSelector(
    pointSelectors.ticketDefaultValues,
  );
  const benefit = useBenefit({ id: benefitId });
  const initialized = useMemo(
    () => !!benefit && !!defaultValues,
    [benefit, defaultValues],
  );
  const modal = useModal();
  const goBack = useGoBack(
    ({ goBack }) => {
      dispatch(pointOperations.resetIssueTicket());
      goBack();
    },
    [dispatch],
  );
  const goPortal = useCallback(() => {
    modal.close();
    navigation.reset({ index: 0, routes: [{ name: 'Portal' }] });
  }, [navigation, modal]);
  useNavigationHeader(
    () => ({ title: 'ご連絡先を入力', left: goBack }),
    [goBack],
  );
  useEffect(() => {
    if (profile.loaded) {
      dispatch(pointOperations.getTicketDefaultValues());
    }
  }, [dispatch, profile.loaded]);
  useEffect(() => {
    if (orderTicketTask.status === 'success') {
      dispatch(pointOperations.resetUseTicket());
      modal.openPopUp({
        title: '申込が完了しました',
        body: '特典の到着までもう少々お待ちください',
        primaryButton: { title: 'ホームへ戻る', onPress: goPortal },
        backdrop: { onPress: goPortal },
      });
    }
  }, [dispatch, modal, goPortal, orderTicketTask.status]);
  const compose = useCallback(
    (form: BenefitComposeForm) => {
      modal.openPopUp({ image: 'indicator' as const, title: '通信中です' });
      dispatch(pointOperations.orderTicket(benefitId, form));
    },
    [dispatch, modal, benefitId],
  );
  if (!initialized) {
    return <Loading title='通信中' />;
  }
  return (
    <BenefitCompose
      loading={orderTicketTask.status !== ''}
      point={point}
      benefit={benefit}
      defaultValues={{
        email: defaultValues?.email || profile.email,
        zipcode: defaultValues?.zipcode,
        addressLine1: defaultValues?.address_line1,
        addressLine2: defaultValues?.address_line2,
        addressee: defaultValues?.addressee,
      }}
      onPressCompose={compose}
    />
  );
};

export { Container as BenefitComposeView };
export default Container;
