export function register() {
  const isEnvProduction = process.env.NODE_ENV === 'production';
  if (isEnvProduction && 'serviceWorker' in navigator) {
    if (process.env.PUBLIC_URL) {
      const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
      if (publicUrl.origin !== origin) return;
    }
    window.addEventListener('load', onLoad);
  }
}

async function onLoad() {
  const { origin } = window.location;
  const swUrl = `${origin}/service-worker.js`;
  try {
    if (await checkInvalidSW(swUrl)) {
      navigator.serviceWorker.ready.then((reg) =>
        reg.unregister().then(() => window.location.reload()),
      );
    } else {
      await navigator.serviceWorker.register(swUrl);
    }
  } catch (error) {
    console.error(error);
  }
}

async function checkInvalidSW(swUrl: string) {
  const { hostname } = window.location;
  if (
    hostname === 'localhost' ||
    hostname === '[::1]' ||
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  ) {
    return false;
  }
  const sw = await fetch(swUrl, { headers: { 'Service-Worker': 'script' } });
  const contentType = sw.headers.get('content-type');
  return (
    sw.status === 404 ||
    (contentType != null && contentType.indexOf('javascript') === -1)
  );
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((reg) => reg.unregister())
      .catch((error) => console.error(error.message));
  }
}
