import JsBarcode from 'jsbarcode';
import React, { useEffect, useRef } from 'react';
import { Theme, withTheme } from '../../theme';

type Props = {
  theme: Theme;
  value: string;
  renderer?: 'svg' | 'canvas' | 'img';
  format?: string;
};

const Component: React.FC<Props> = ({
  theme: { colors },
  value,
  renderer,
  format,
}) => {
  const ref = useRef(null);
  useEffect(() => {
    try {
      new JsBarcode(ref.current, value, {
        format,
        width: 2,
        height: 100,
        displayValue: true,
        fontOptions: '',
        font: 'monospace',
        textAlign: 'center',
        textPosition: 'bottom',
        textMargin: 2,
        fontSize: 20,
        background: colors.white as string,
        lineColor: colors.black as string,
        margin: 10,
      });
    } catch (e) {
      // prevent stop the parent process
      console.error(e);
    }
  }, [colors, format, value]);
  switch (renderer) {
    case 'svg':
      return <svg ref={ref} />;
    case 'canvas':
      return <canvas ref={ref} />;
    case 'img':
      return <img ref={ref} />;
    default:
      return <></>;
  }
};

Component.defaultProps = {
  format: 'CODE128',
  renderer: 'svg',
};

export type { Props as BarcodeProps };
export const Barcode = withTheme(Component);
