import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountPhoneVerification } from '../components/pages';
import { useAuth, useGoBack, useNavigationHeader, usePoint } from '../hooks';
import {
  AccountPhoneVerificationNavigation,
  AccountPhoneVerificationRoute,
} from '../navigation';
import {
  operations as accountOperations,
  selectors as accountSelectors,
} from '../states/account';

type Props = {
  navigation: AccountPhoneVerificationNavigation;
  route: AccountPhoneVerificationRoute;
};

const Container: React.FC<Props> = ({ navigation }) => {
  useAuth();
  usePoint();
  const dispatch = useDispatch();
  const task = useSelector(accountSelectors.getUpdatePhoneNumberTask);
  const goBack = useGoBack();
  const resetNavigation = useCallback(() => {
    navigation.reset({
      index: 1,
      routes: [{ name: 'Portal' }, { name: 'AccountSettings' }],
    });
  }, [navigation]);
  useNavigationHeader(
    () => ({ title: '電話番号の変更', left: goBack }),
    [goBack],
  );
  useEffect(() => {
    if (!task.verificationId) {
      resetNavigation();
    }
  }, [resetNavigation, task.verificationId]);
  useEffect(() => {
    if (task.status === 'success') {
      dispatch(accountOperations.endUpdatePhoneNumber());
      resetNavigation();
    }
  }, [resetNavigation, dispatch, task.status]);
  const onPressResend = useCallback(() => {
    dispatch(accountOperations.resendUpdatePhoneNumberVerifier());
  }, [dispatch]);
  const onPressSubmit = useCallback(
    (code) => dispatch(accountOperations.updatePhoneNumber({ code })),
    [dispatch],
  );
  return (
    <AccountPhoneVerification
      loading={task.status === 'in_progress'}
      updated={task.status === 'success'}
      onPressResend={onPressResend}
      onPressSubmit={onPressSubmit}
      phoneNumber={task.value ?? ''}
    />
  );
};

export { Container as AccountPhoneVerificationView };
export default Container;
