import {
  BenefitFeature,
  TokenResource,
  TokenTransactionResource,
} from '../../mint';
import { Ticket } from '../../states/point';
import { Theme } from '../../theme';
import { TicketInfo } from './types';

export function getTransactionTitle({
  tx,
  recv,
  text,
  checkInStampIDs,
  introStampIDs,
}: {
  tx: TokenTransactionResource;
  recv: boolean;
  text: (
    arg: { point: TokenResource; recv: boolean; amount: number } & (
      | { type: 'checkin' }
      | { type: 'intro' }
      | { type: 'benefit' }
      | { type: 'default' }
    ),
  ) => string;
  checkInStampIDs: string[];
  introStampIDs: string[];
}) {
  const arg = { point: tx.token, recv, amount: tx.amount };
  const stampId = tx.meta?.stamp_id;
  if (stampId && checkInStampIDs.indexOf(stampId) !== -1) {
    return text({ type: 'checkin', ...arg });
  }
  if (stampId && introStampIDs.indexOf(stampId) !== -1) {
    return text({ type: 'intro', ...arg });
  }
  if (tx.meta?.benefit_id) {
    return text({ type: 'benefit', ...arg });
  }
  return text({ type: 'default', ...arg });
}

export function toBenefitInfo(feature: BenefitFeature): {
  featureTitle: string;
  issueShortTitle: string;
  issueTitle: string;
  isVoucher: boolean;
} {
  switch (feature) {
    case 'in_place':
    case 'pass':
      return {
        featureTitle: '電子チケット',
        issueShortTitle: '獲得',
        issueTitle: 'チケットを獲得する',
        isVoucher: true,
      };
    case 'remote':
      return {
        featureTitle: 'メールで受け取る',
        issueShortTitle: '申込',
        issueTitle: '申し込む',
        isVoucher: false,
      };
    case 'delivery':
      return {
        featureTitle: '配送で受け取る',
        issueShortTitle: '申込',
        issueTitle: '申し込む',
        isVoucher: false,
      };
    default:
      return {
        featureTitle: '',
        issueShortTitle: '',
        issueTitle: '',
        isVoucher: false,
      };
  }
}

const TICKET_USAGE_TIMELIMIT = 600000;

export function getTicketReceptionTime(usedAt?: Date): number {
  if (!usedAt) {
    return 0;
  }
  const diff = new Date().getTime() - usedAt.getTime();
  const remaining = TICKET_USAGE_TIMELIMIT - diff;
  return remaining > 0 ? remaining : 0;
}

export function toTicketInfo(theme: Theme, t: Ticket): TicketInfo {
  const base = {
    available: false,
    receptionTime: 0,
  };
  switch (t.state) {
    case 'available_voucher_expire_soon':
      return {
        ...base,
        available: true,
        title: '使用期限が迫っています',
        shortTitle: 'もうすぐ',
        color: theme.colors.alertPrimary,
        icon: 'alert-circle',
      };
    case 'available_voucher':
      return {
        ...base,
        available: true,
        title: '使用可能',
        shortTitle: '使用可能',
        color: theme.colors.positivePrimary,
        icon: 'checkmark-circle',
      };
    case 'used_voucher':
      return {
        ...base,
        title: '使用済み',
        shortTitle: '使用済み',
        color: theme.colors.positivePrimary,
        icon: 'checkmark-circle-outline',
        ...(t.feature === 'in_place' &&
          t.usedAt && {
            receptionTime: getTicketReceptionTime(t.usedAt),
          }),
      };
    case 'not_shipped_order':
      return {
        ...base,
        title: '未発送',
        shortTitle: '未発送',
        color: theme.colors.positivePrimary,
        icon: 'remove-circle-outline',
      };
    case 'shipped_order':
      return {
        ...base,
        title: '発送済み',
        shortTitle: '発送済み',
        color: theme.colors.positivePrimary,
        icon: 'checkmark-circle',
      };
    case 'expired':
      return {
        ...base,
        title: '期限切れ',
        shortTitle: '期限切れ',
        color: theme.colors.textCardSecondary,
        icon: 'alert-circle',
      };
    default:
      return {
        ...base,
        title: '不明',
        shortTitle: '不明',
        color: theme.colors.textCardSecondary,
        icon: 'alert-circle',
      };
  }
}
