import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountPhoneEdit } from '../components/pages';
import { useAuth, useGoBack, useNavigationHeader, usePoint } from '../hooks';
import { AccountPhoneNavigation, AccountPhoneRoute } from '../navigation';
import {
  operations as accountOperations,
  selectors as accountSelectors,
} from '../states/account';

type Props = {
  navigation: AccountPhoneNavigation;
  route: AccountPhoneRoute;
};

const Container: React.FC<Props> = ({ navigation }) => {
  useAuth();
  usePoint();
  const dispatch = useDispatch();
  const { phoneNumber } = useSelector(accountSelectors.getProfile);
  const task = useSelector(accountSelectors.getUpdatePhoneNumberTask);
  const goBack = useGoBack();
  useNavigationHeader(
    () => ({ title: '電話番号の変更', left: goBack }),
    [goBack],
  );
  useEffect(() => {
    if (task.verificationId) {
      navigation.push('AccountPhoneVerification');
    }
  }, [navigation, task]);
  const onPressChange = useCallback(
    (v) => {
      dispatch(
        accountOperations.sendUpdatePhoneNumberVerifier({
          phoneNumber: v.phoneNumber,
        }),
      );
    },
    [dispatch],
  );
  return (
    <AccountPhoneEdit
      defaultPhoneNumberValue={
        phoneNumber ? phoneNumber.replace(/^\+81/, '0') : undefined
      }
      onPressChange={onPressChange}
    />
  );
};

export { Container as AccountPhoneEditView };
export default Container;
