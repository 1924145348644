import liff from '@line/liff/dist/lib';
import { Dispatch as ReactDispatch } from 'react';
import { ApplicationError } from '../../lib/error';
import mint, {
  ImageListMedia,
  Problem,
  UserImageResource,
  UserProfile,
  UserProfileMedia,
} from '../../mint';
import * as storage from '../../storage';
import { State as RootState } from '../store';
import actions, { Actions } from './actions';
import { accountSelector } from './selectors';

type Dispatch = ReactDispatch<Actions>;
type GetRootState = () => RootState;

export default {
  initializeAuth: () => async (dispatch: Dispatch) => {
    try {
      await storage.loadAuthTokens();
      dispatch(actions.completeSignin(await loadSignInInfo()));
    } catch {
      dispatch(actions.signout());
    }
  },
  resumeSignin:
    (v: { code?: string; state?: string; passwordlessVerifier?: string }) =>
    async (dispatch: Dispatch) => {
      try {
        const {
          clientId,
          grantType,
          idtokenProvider,
          connection,
          pointName,
          username,
        } = await storage.loadAuthTokenRequest();
        if (v.passwordlessVerifier) {
          dispatch(
            actions.startSignInPasswordless({
              state: v.state,
              verifier: v.passwordlessVerifier,
              connection,
              username,
            }),
          );
          return;
        }

        // get_token
        const tokenParams = { clientId, ...v };
        try {
          storage.saveAuthTokens(await mint.getAuthTokens(tokenParams));
        } catch (err) {
          if (!(err instanceof Problem && err.title === 'signup_required')) {
            throw err;
          }
          if (grantType === 'openid') {
            const idp = idtokenProvider ?? '';
            const idtoken = (() => {
              if (idp.endsWith('@line')) {
                return liff.getIDToken();
              }
              throw new ApplicationError('auth_failure');
            })();
            await mint.userUnauthorized().post('/users/signup', {
              did_agree_privacy_policy_eula: true,
              client_id: clientId,
              scope: 'api:access',
              token_name: pointName,
              idtoken,
              idtoken_provider: idtokenProvider,
            });
            storage.saveAuthTokens(await mint.getAuthTokens(tokenParams));
          } else {
            throw err;
          }
        }

        storage.removeAuthTokenRequest();
        dispatch(actions.completeSignin(await loadSignInInfo()));
      } catch (err) {
        dispatch(actions.cancelSignIn());
        dispatch(actions.setError(err));
      }
    },
  verifyPasswordlessOTP:
    (otp) => async (dispatch: Dispatch, getState: GetRootState) => {
      try {
        const {
          passwordless: { state, verifier: passwordlessVerifier },
        } = accountSelector(getState());

        const { clientId, pointName, connection, username } =
          await storage.loadAuthTokenRequest();
        if (!connection) {
          new ApplicationError('auth_failure');
        }

        const tokenParams = { state, passwordlessVerifier, otp, clientId };
        try {
          storage.saveAuthTokens(await mint.getAuthTokens(tokenParams));
        } catch (err) {
          if (!(err instanceof Problem && err.title === 'signup_required')) {
            throw err;
          }
          await mint.userUnauthorized().post('/users/signup', {
            did_agree_privacy_policy_eula: true,
            client_id: clientId,
            passwordless_verifier: passwordlessVerifier,
            scope: 'api:access',
            token_name: pointName,
            ...(connection === 'sms' && { phone_number: username }),
            ...(connection === 'email' && { email: username }),
          });
          storage.saveAuthTokens(await mint.getAuthTokens(tokenParams));
        }

        storage.removeAuthTokenRequest();
        dispatch(actions.completeSignin(await loadSignInInfo()));
      } catch (err) {
        switch (err instanceof Problem ? err.title : '') {
          case 'passwordless_session_timeout':
            dispatch(
              actions.setSignInVerificationError(
                '認証コードはタイムアウトしました',
              ),
            );
            break;
          case 'invalid_otp_code':
            dispatch(
              actions.setSignInVerificationError('認証コードが一致しません'),
            );
            break;
          default:
            dispatch(actions.cancelSignIn());
            dispatch(actions.setError(err));
        }
      }
    },
  resendPasswordlessOTP:
    () => async (dispatch: Dispatch, getState: GetRootState) => {
      try {
        const {
          passwordless: { verifier },
        } = accountSelector(getState());
        await mint.auth().post('/passwordless/resend_otp', {
          passwordless_verifier: verifier,
        });
      } catch (err) {
        dispatch(actions.setError(err));
      }
    },
  errorSignInCallback: (errTitle: string) => async (dispatch: Dispatch) => {
    dispatch(
      actions.setError(
        new ApplicationError('auth_failure', { userMsg: errTitle }),
      ),
    );
  },
  clearSignInVerificationError: actions.clearSignInVerificationError,
  cancelSignIn: actions.cancelSignIn,
  consumeNextRouteAfterSignIn: actions.consumeNextRouteAfterSignIn,
  signout: () => async (dispatch: Dispatch) => {
    await Promise.all([
      storage.removeAuthTokenRequest(),
      storage.removeAuthTokens(),
    ]);
    for (const key in localStorage) {
      if (key.startsWith('LIFF_STORE:')) {
        liff.logout();
      }
    }
    dispatch(actions.signout());
  },

  addNextRouteAfterSignIn: actions.addNextRouteAfterSignIn,

  updateProfile:
    (v: { name: string; screenName: string; imageUri: string }) =>
    async (dispatch: Dispatch, getState: GetRootState) => {
      dispatch(actions.startUpdateProfile());
      try {
        await mint.user().post('/users/update_profile', {
          name: v.name,
          screen_name: v.screenName,
        });
        let err: any;
        let image: UserImageResource;
        try {
          if (v.imageUri && v.imageUri.startsWith('data:')) {
            image = await uploadImage(
              accountSelector(getState()).profile.portraitImage.id,
              await (await fetch(v.imageUri)).blob(),
            );
          }
        } catch (e) {
          err = e;
        }
        dispatch(
          actions.successUpdateProfile({
            name: v.name,
            screenName: v.screenName,
            ...(image ? { image } : {}),
          }),
        );
        if (err) {
          dispatch(actions.setError(err));
        }
      } catch (err) {
        dispatch(actions.errorUpdateProfile());
        dispatch(actions.setError(err));
      }
    },
  endUpdateProfile: actions.endUpdateProfile,

  updateEmail: (v: { email: string }) => async (dispatch: Dispatch) => {
    dispatch(actions.startUpdateEmail());
    try {
      await mint.user().post('/users/update_email', { email: v.email });
      dispatch(actions.successUpdateEmail(v));
    } catch (err) {
      dispatch(actions.errorUpdateEmail());
      dispatch(actions.setError(err));
    }
  },
  endUpdateEmail: actions.endUpdateEmail,

  sendUpdatePhoneNumberVerifier:
    (v: { phoneNumber: string }) => async (dispatch: Dispatch) => {
      try {
        const {
          data: { user_phone_number_verification_id: verificationId },
        } = await mint
          .user()
          .post<{ user_phone_number_verification_id: string }>(
            '/users/update_phone_number',
            {
              phone_number: v.phoneNumber,
            },
          );
        dispatch(
          actions.setUpdatePhoneNumber({
            verificationId,
            phoneNumber: v.phoneNumber,
          }),
        );
      } catch (err) {
        dispatch(actions.errorUpdatePhoneNumber());
        dispatch(actions.setError(err));
      }
    },
  resendUpdatePhoneNumberVerifier:
    () => async (dispatch: Dispatch, getState: GetRootState) => {
      const {
        updatePhoneNumberTask: { verificationId: id },
      } = accountSelector(getState());
      try {
        await mint
          .userUnauthorized()
          .post('/users/resend_phone_number_verifier', { id });
      } catch (err) {
        dispatch(actions.setError(err));
      }
    },
  updatePhoneNumber:
    (v: { code: string }) =>
    async (dispatch: Dispatch, getState: GetRootState) => {
      const {
        updatePhoneNumberTask: { verificationId, value: phoneNumber },
      } = accountSelector(getState());
      dispatch(actions.startUpdatePhoneNumber());
      try {
        await mint.user().post('/users/verify_phone_number', {
          id: verificationId,
          phone_number: phoneNumber,
          code: v.code,
        });
        dispatch(actions.successUpdatePhoneNumber());
      } catch (err) {
        dispatch(actions.errorUpdatePhoneNumber());
        dispatch(actions.setError(err));
      }
    },
  endUpdatePhoneNumber: actions.endUpdatePhoneNumber,

  updatePassword:
    (v: { currentPassword: string; newPassword: string }) =>
    async (dispatch: Dispatch) => {
      dispatch(actions.startUpdatePassword());
      try {
        await mint.user().post('/users/update_password', {
          ...(v.currentPassword ? { current_password: v.currentPassword } : {}),
          new_password: v.newPassword,
        });
        dispatch(actions.successUpdatePassword());
      } catch (err) {
        dispatch(actions.errorUpdatePassword());
        dispatch(actions.setError(err));
      }
    },
  endUpdatePassword: actions.endUpdatePassword,

  disconnectTwitter: () => async (dispatch: Dispatch) => {
    dispatch(actions.startDissconectTwitter());
    try {
      await mint.user().post('/twitter/disconnect');
      dispatch(actions.successDissconectTwitter());
    } catch (err) {
      dispatch(actions.errorDissconectTwitter());
      dispatch(actions.setError(err));
    }
  },
  endDisconnectTwitter: actions.endDisconnectTwitter,
};

const loadSignInInfo = async () => {
  const { profile } = (
    await mint.user().get<UserProfileMedia>('/users/me?fields=meta')
  ).data;
  const nextRoutes = await storage.loadNextRouteAfterSignIn();
  return {
    profile: {
      loaded: true,
      id: profile.id,
      name: profile.name,
      screenName: profile.screen_name,
      email: profile.email,
      phoneNumber: profile.phone_number,
      portraitImage: profile.portrait_image,
      passwordRegistered: profile.password_registered_at ? true : false,
      twitterConnected: profile.twitter_id ? true : false,
      facebookConnected: profile.facebook_id ? true : false,
    } as UserProfile,
    nextRoutes,
  };
};

const uploadImage = async (
  id: string,
  data: Blob,
): Promise<UserImageResource> => {
  const formData = new FormData();
  formData.append('id', id);
  formData.append('file', data, 'image');
  const {
    data: { images },
  } = await mint.image().post<ImageListMedia>('/images/upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  if (images.length === 0) {
    throw new ApplicationError('image_not_found');
  }
  return images[0];
};
