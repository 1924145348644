import { Ionicons } from '@expo/vector-icons';
import React, { useEffect, useMemo, useRef } from 'react';
import {
  ActivityIndicator,
  Animated,
  Easing,
  Image,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Button } from '.';
import { CoinAnimation, PartyPopperAnimation } from '../../assets';
import { Theme, withTheme } from '../../theme';
import { setForwardRef } from '../utils';

const useStyles = ({ theme: { colors, typography } }: { theme: Theme }) => {
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          alignSelf: 'center',
          position: 'absolute',
          overflow: 'hidden',
          borderRadius: 16,
          top: 200,
          width: 310,
          backgroundColor: colors.card,
        },
        title: {
          ...typography.textXL,
          fontWeight: 'bold',
          textAlign: 'center',
          color: colors.textCardPrimary,
        },
        body: {
          ...typography.paragraphM,
          marginTop: 19,
          marginHorizontal: 24,
          marginBottom: 24,
          color: colors.textCardPrimary,
        },
        coin: {
          width: 180,
          height: 180,
        },
        partyPopper: {
          width: 180,
          height: 180,
        },
        footer: {
          alignItems: 'center',
          paddingTop: 24,
          paddingBottom: 16,
          paddingHorizontal: 19,
          width: '100%',
          backgroundColor: colors.backgroundLight,
        },
        button: {
          paddingHorizontal: 16,
          width: '100%',
          height: 40,
        },
      }),
    [colors, typography],
  );
};

export type ImageType = 'indicator' | 'alert' | 'party-popper' | 'coin';

export type ButtonData = {
  title: string;
  onPress: () => void;
};

type ComponentRef = { hideAnimation: () => Animated.CompositeAnimation };

type Props = {
  theme: Theme;
  image?: ImageType;
  title?: string;
  body?: string;
  primaryButton?: ButtonData;
  secondaryButton?: ButtonData;
};

const Component = React.forwardRef<ComponentRef, Props>(
  ({ theme, ...props }, ref) => {
    const styles = useStyles({ theme });
    const trY = useRef(new Animated.Value(0));
    const tr = useRef(new Animated.Value(0));
    useEffect(() => {
      Animated.timing(tr.current, {
        useNativeDriver: false,
        toValue: 1,
        duration: 300,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      }).start();
    }, []);
    useEffect(() => {
      setForwardRef(ref, {
        hideAnimation: () =>
          Animated.parallel([
            Animated.timing(trY.current, {
              useNativeDriver: false,
              toValue: 1,
              duration: 300,
              easing: Easing.bezier(0.4, 0.0, 0.2, 1),
            }),
            Animated.timing(tr.current, {
              useNativeDriver: false,
              toValue: 0,
              duration: 300,
              easing: Easing.bezier(0.4, 0.0, 0.2, 1),
            }),
          ]),
      });
    }, [ref]);
    const { image, title, body, buttons } = useMemo(() => {
      const { image, title, body, primaryButton, secondaryButton } = props;
      const bool = (v: any) => (v ? true : false);
      return {
        image: { value: image, visible: bool(image) },
        title: { value: title, visible: bool(title) },
        body: { value: body, visible: bool(body) },
        buttons: {
          primary: primaryButton,
          secondary: secondaryButton,
          visible: bool(primaryButton || secondaryButton),
          bothVisible: bool(primaryButton && secondaryButton),
        },
      };
    }, [props]);
    return (
      <Animated.View
        style={[
          styles.container,
          {
            opacity: tr.current.interpolate({
              inputRange: [0, 1],
              outputRange: [0.3, 1],
            }),
            transform: [
              {
                scale: tr.current.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0.7, 1],
                }),
              },
              {
                translateY: trY.current.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, -200],
                }),
              },
            ],
          },
        ]}
      >
        {image.visible && (
          <View
            style={{
              marginTop: 24,
              padding: 8,
              alignItems: 'center',
              width: '100%',
            }}
          >
            {image.value === 'indicator' && (
              <ActivityIndicator size={86} color={theme.colors.primary} />
            )}
            {image.value === 'alert' && (
              <Ionicons
                name='alert-circle'
                size={64}
                color={theme.colors.alertPrimary}
              />
            )}
            {image.value === 'coin' && (
              <Image style={styles.coin} source={CoinAnimation} />
            )}
            {image.value === 'party-popper' && (
              <Image style={styles.partyPopper} source={PartyPopperAnimation} />
            )}
          </View>
        )}
        {title.visible && (
          <Text
            style={[
              styles.title,
              {
                marginTop: image.visible ? 8 : 24,
                marginBottom: body.visible ? 0 : 24,
              },
            ]}
          >
            {title.value}
          </Text>
        )}
        {body.visible && <Text style={styles.body}>{body.value}</Text>}
        {buttons.visible && (
          <View
            style={[
              styles.footer,
              {
                justifyContent: buttons.bothVisible
                  ? 'space-between'
                  : 'center',
              },
            ]}
          >
            {buttons.primary && (
              <Button
                style={[
                  styles.button,
                  { backgroundColor: theme.colors.primary },
                ]}
                titleStyle={{ color: theme.colors.card }}
                title={buttons.primary.title}
                onPress={buttons.primary.onPress}
              />
            )}
            {buttons.secondary && (
              <Button
                style={[
                  styles.button,
                  {
                    marginTop: 14,
                    backgroundColor: theme.colors.backgroundLight,
                  },
                ]}
                titleStyle={{ color: theme.colors.textCardPrimary }}
                onPress={buttons.secondary.onPress}
                title={buttons.secondary.title}
              />
            )}
          </View>
        )}
      </Animated.View>
    );
  },
);

export type {
  Props as PopUpProps,
  ComponentRef as PopUpRef,
  ImageType as PopUpImageType,
  ButtonData as PopUpButtonData,
};
export const PopUp = withTheme(Component);
