import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as accountSelectors } from '../states/account';
import {
  operations as pointOperations,
  selectors as pointSelectors,
} from '../states/point';
import { getPointId as getSiteName } from './util';

const siteName = getSiteName();

export const usePoint = () => {
  const dispatch = useDispatch();
  const getPortal = useSelector(pointSelectors.getPortal);
  const getWallet = useSelector(pointSelectors.getWallet);
  const profile = useSelector(accountSelectors.getProfile);
  const point = useSelector(pointSelectors.point);
  useEffect(() => {
    if (getPortal.status === '') {
      dispatch(pointOperations.getPortal(siteName));
    }
  }, [dispatch, getPortal.status, getPortal.loaded]);
  useEffect(() => {
    if (profile.loaded && getPortal.loaded && getWallet.status === '') {
      dispatch(pointOperations.getWallet(point.id));
    }
  }, [dispatch, point.id, profile.loaded, getPortal.loaded, getWallet.status]);
  return { getPortal, getWallet, point };
};
