import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  BenefitResource,
  TokenNoticeResource,
  TokenTransactionResource,
} from '../mint';
import {
  ListTicketTask,
  selectors as pointSelectors,
  Ticket,
  TicketListType,
} from '../states/point';

export const useTransaction = ({
  id,
}: {
  id: string;
}): TokenTransactionResource => {
  const list = useSelector(pointSelectors.listTransaction);
  return useMemo(() => list.items.find((e) => e.id === id), [id, list]);
};

export const useNotice = ({ id }: { id: string }): TokenNoticeResource => {
  const list = useSelector(pointSelectors.listNotice);
  return useMemo(() => list.items.find((e) => e.id === id), [id, list]);
};

export const useBenefit = ({ id }: { id: string }): BenefitResource => {
  const list = useSelector(pointSelectors.listBenefit);
  return useMemo(() => list.items.find((e) => e.id === id), [id, list]);
};

export const useListVoucher = ({
  type,
}: {
  type: TicketListType;
}): ListTicketTask => {
  const available = useSelector(pointSelectors.listAvailableVoucher);
  const all = useSelector(pointSelectors.listAllVoucher);
  return useMemo(
    () => (type === 'available_voucher' ? available : all),
    [type, available, all],
  );
};

export const useVoucher = ({ id }: { id: string }): Ticket => {
  const x = useSelector(pointSelectors.listAvailableVoucher);
  const y = useSelector(pointSelectors.listAllVoucher);
  return useMemo(
    () => x.items.find((e) => e.id === id) ?? y.items.find((e) => e.id === id),
    [id, x, y],
  );
};

export const useOrder = ({ id }: { id: string }): Ticket => {
  const x = useSelector(pointSelectors.listOrder);
  return useMemo(() => x.items.find((e) => e.id === id), [id, x]);
};
