import { ActionType } from 'redux-actions-type';

export const ActionTypes = {
  SET_CHECK_VERSION_TASK: '@@appinfo/SET_CHECK_VERSION_TASK' as const,
  SUCCESS_CHECK_VERSION_TASK: '@@appinfo/SUCCESS_CHECK_VERSION_TASK' as const,
};

const actions = {
  startCheckVersionTask: () => ({
    type: ActionTypes.SET_CHECK_VERSION_TASK,
    status: 'in_progress' as const,
  }),
  successCheckVersionTask: (version: string) => ({
    type: ActionTypes.SUCCESS_CHECK_VERSION_TASK,
    version,
  }),
  errorCheckVersionTask: () => ({
    type: ActionTypes.SET_CHECK_VERSION_TASK,
    status: 'error' as const,
  }),
};

export type Actions = ActionType<typeof actions>;

export default actions;
