import React from 'react';
import { OrderDetail } from '../components/pages';
import {
  useAuth,
  useGoBack,
  useNavigationHeader,
  useOrder,
  usePoint,
} from '../hooks';
import { OrderDetailNavigation, OrderDetailRoute } from '../navigation';

type Props = {
  navigation: OrderDetailNavigation;
  route: OrderDetailRoute;
};

const Container: React.FC<Props> = ({ route }) => {
  useAuth();
  usePoint();
  const { ticketId } = { ticketId: undefined, ...route.params };
  const ticket = useOrder({ id: ticketId });
  const goBack = useGoBack();
  useNavigationHeader(
    () => ({
      title:
        ticket?.feature === 'delivery' ? '配送で受け取る' : 'メールで受け取る',
      left: goBack,
    }),
    [goBack, ticket],
  );
  if (!ticket) {
    return <Loading title='通信中' />;
  }
  return <OrderDetail ticket={ticket} />;
};

export { Container as OrderDetailView };
export default Container;
