import { TextStyle } from 'react-native';
import { ThemeColors, ThemeSettings } from './types';

export const DEFAULT_LIGHT_THEME_COLORS: ThemeColors = {
  black: 'black',
  white: 'white',
  primary: '#11D3D7',
  accent: '#11D3D7',
  link: '#FF9900',
  border: '#E7E7E7',
  card: '#FFFFFF',
  positivePrimary: '#1CB069',
  positiveSecondary: '#D1E7DD',
  alertPrimary: '#EB1616',
  alertSecondary: '#F5C2C7',
  alertBackground: '#F8D7DA',
  alertText: '#842029',
  warnPrimary: '#FFECB5',
  warnSecondary: '#FFECB5',
  negativePrimary: '#7A7A7A',
  negativeSecondary: '#D3D3D3',
  membershipCardAccent: '#FFFFFF',
  membershipCardBackground: '#11D3D7',
  membershipCardText: '#FFFFFF',
  backgroundLight: '#F6F8FA',
  backgroundDark: '#000000',
  textCardPrimary: '#263240',
  textCardSecondary: '#646464',
  textCardHidden: '#B6B6B6',
  textBackgroundPrimary: '#263240',
  textBackgroundSecondary: '#646464',
  textBackgroundHidden: '#B6B6B6',
  itemBackgroundEmpty: '#D8D2B8',
};

function textStyle(px: number): TextStyle {
  return { fontSize: px };
}

function paragraphStyle(px: number): TextStyle {
  return { fontSize: px, lineHeight: px * 1.8 };
}

function titleStyle(px: number): TextStyle {
  return { fontSize: px, lineHeight: px * 1.6, fontWeight: 'bold' };
}

export const DEFAULT_THEME_SETTINGS: ThemeSettings = {
  colors: {
    light: DEFAULT_LIGHT_THEME_COLORS,
    dark: DEFAULT_LIGHT_THEME_COLORS,
  },
  typography: {
    textSmaller: textStyle(8),
    textS: textStyle(12),
    textM: textStyle(14),
    textL: textStyle(16),
    textXL: textStyle(18),
    paragraphS: paragraphStyle(12),
    paragraphM: paragraphStyle(14),
    paragraphL: paragraphStyle(16),
    paragraphXL: paragraphStyle(18),
    titleM: titleStyle(20),
    titleL: titleStyle(22),
    balanceL: {
      fontSize: 24,
      lineHeight: 43, // 180%
      fontWeight: 'bold',
    },
    timer: {
      fontSize: 34,
      fontFamily: 'monospace',
      fontWeight: 'bold',
    },
  },
};
