import { Camera as ExpoCamera } from 'expo-camera';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { TakePictureAsync } from '../../states/point';
import { Theme, withTheme } from '../../theme';
import { Button } from '../elements';

const useStyles = ({ theme: { colors } }: { theme: Theme }) => {
  return useMemo(() => {
    return StyleSheet.create({
      container: {
        flex: 1,
      },
      camera: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        backgroundColor: colors.black,
      },
      button: {
        width: '75%',
        marginBottom: 50,
      },
    });
  }, [colors]);
};

type Props = {
  theme: Theme;
  onPress: (f: TakePictureAsync) => void;
};

const Component: React.FC<Props> = ({ theme, onPress }) => {
  const styles = useStyles({ theme });
  const ref = useRef<ExpoCamera>(null);
  const [cameraReady, setCameraReady] = useState(false);
  return (
    <View style={styles.container}>
      <ExpoCamera
        ref={ref}
        style={styles.camera}
        type={ExpoCamera.Constants.Type.back}
        onCameraReady={useCallback(
          () => setCameraReady(true),
          [setCameraReady],
        )}
      >
        <Button
          disabled={!cameraReady}
          onPress={useCallback(() => {
            onPress(() => ref.current.takePictureAsync({ exif: true }));
          }, [onPress])}
          title={'撮影'}
          style={styles.button}
        />
      </ExpoCamera>
    </View>
  );
};

export type { Props as CameraProps };
export const Camera = withTheme(Component);
