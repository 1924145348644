import React, { useCallback, useMemo, useState } from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Svg, { Polyline } from 'react-native-svg';
import { Theme, withTheme } from '../../theme';
import { RGBA } from '../utils';

const useShapeStyles = ({
  theme: { colors },
  checked,
}: {
  theme: Theme;
  checked: boolean;
}) =>
  useMemo(() => {
    return StyleSheet.create({
      checkbox: {
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        width: 33,
        height: 33,
        backgroundColor: checked
          ? new RGBA(colors.primary).setAlpha(0.2).toString()
          : colors.card,
      },
    });
  }, [colors, checked]);

type ShapeProps = {
  theme: Theme;
  checked: boolean;
};

const Shape = withTheme<ShapeProps>(({ theme, checked }) => {
  const styles = useShapeStyles({ theme, checked });
  const { colors } = theme;
  return (
    <View style={styles.checkbox}>
      <Svg width='19px' height='15px'>
        <Polyline
          fill='none'
          stroke={(checked ? colors.primary : colors.textCardHidden) as string}
          strokeWidth='3'
          points='3,7 8,12 16,3'
        />
      </Svg>
    </View>
  );
});

const useStyles = ({ theme: { colors, typography } }: { theme: Theme }) =>
  useMemo(() => {
    return StyleSheet.create({
      container: {
        flexDirection: 'row',
        alignItems: 'center',
      },
      title: {
        ...typography.textM,
        marginLeft: 16,
        color: colors.textCardPrimary,
      },
    });
  }, [colors, typography]);

type Props = {
  theme: Theme;
  title: string;
  checked?: boolean;
  onChange?: (value: boolean) => void;
  containerStyle?: StyleProp<ViewStyle>;
};

const Component = withTheme<Props>(({ theme, title, onChange, ...props }) => {
  const styles = useStyles({ theme });
  const [checked, setChecked] = useState(
    props.checked !== undefined ? props.checked : false,
  );
  const onPress = useCallback(() => {
    const newValue = !checked;
    setChecked(newValue);
    onChange && onChange(newValue);
  }, [checked, onChange]);
  return (
    <TouchableOpacity
      style={[styles.container, props.containerStyle]}
      onPress={onPress}
    >
      <Shape checked={checked} />
      <Text style={styles.title}>{title}</Text>
    </TouchableOpacity>
  );
});

export type { Shape as CheckBoxShapeProps, Props as CheckBoxProps };
export { Shape as CheckBoxShape, Component as CheckBox };
