import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NoticeList } from '../components/pages';
import {
  useAuth,
  useGoBack,
  useListData,
  useNavigationHeader,
  usePoint,
} from '../hooks';
import { TokenNoticeResource } from '../mint';
import { NoticeListNavigation, NoticeListRoute } from '../navigation';
import {
  operations as pointOperations,
  selectors as pointSelectors,
} from '../states/point';

type ContainerProps = {
  navigation: NoticeListNavigation;
  route: NoticeListRoute;
};

const Container: React.FC<ContainerProps> = ({ navigation }) => {
  useAuth();
  usePoint();
  const dispatch = useDispatch();
  const { status, items } = useSelector(pointSelectors.listNotice);
  const goBack = useGoBack();
  useNavigationHeader(() => ({ title: 'おしらせ', left: goBack }), [goBack]);
  const onPressItem = useCallback(
    ({ id: noticeId }: TokenNoticeResource) => {
      navigation.push('NoticeDetail', { noticeId });
      dispatch(pointOperations.markAsReadNotice({ id: noticeId }));
    },
    [navigation, dispatch],
  );
  const onLoad = useCallback(
    (v?: { more: boolean }) => dispatch(pointOperations.listNotice(v)),
    [dispatch],
  );
  const data = useListData({ data: items, status, onLoad });
  return <NoticeList data={data} onPressItem={onPressItem} />;
};

export { Container as NoticeListView };
export default Container;
