function now() {
  return new Date();
}

export function formatDate(
  format: 'Date' | 'DateTime' | 'ApproximateTime',
  date: Date,
) {
  if (!date) {
    return '';
  }
  switch (format) {
    case 'Date':
      return toDateString(date);
    case 'DateTime':
      return toDateTimeString(date);
    case 'ApproximateTime':
      return toApproximateTime(date);
  }
}

function toDateString(date: Date): string {
  return (
    `${date.getFullYear()}年` +
    `${date.getMonth() + 1}月` +
    `${date.getDate()}日`
  );
}

function toDateTimeString(date: Date): string {
  return (
    toDateString(date) +
    ` ${date.getHours()}:${('0' + date.getMinutes()).slice(-2)}`
  );
}

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const MONTH = 30 * DAY;
const YEAR = 365 * DAY;

function toApproximateTime(date: Date): string {
  const t = Math.floor(now().getTime() - date.getTime());
  if (t < MINUTE) {
    return `${t}秒前`;
  }
  if (t < HOUR) {
    return `${Math.ceil(t / MINUTE)}分`;
  }
  if (t < DAY) {
    return `${Math.ceil(t / HOUR)}時間`;
  }
  if (t < MONTH) {
    return `${Math.ceil(t / DAY)}日`;
  }
  if (t < YEAR) {
    return `${date.getMonth() + 1}月` + `${date.getDate()}日`;
  }
  return toDateString(date);
}

export function diffDate(x: Date, y: Date): number {
  return Math.floor((x.getTime() - y.getTime()) / 86400000);
}
