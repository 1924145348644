/* eslint-disable @typescript-eslint/ban-types */
import liff from '@line/liff/dist/lib';
import { Linking } from 'react-native';
import env from '../env';
import { ApplicationError } from '../lib/error';
import {
  loadAuthTokenRequest,
  loadAuthTokens,
  saveAuthTokenRequest,
} from '../storage';
import axios from './axios';
import { AuthTokens } from './types';

const randomString = (len) => {
  const chars =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz-._~';
  const l = chars.length;
  let str = '';
  for (let i = 0; i < len; i++) {
    str += chars[Math.floor(Math.random() * l)];
  }
  return str;
};

const toURLSearchParams = (params: Object) => {
  const p = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    p.append(key, String(params[key]));
  });
  return p;
};

// FIXME: WEB依存、constantsを使うかplatform判定する、nativeの場合はdeeplinkを使う
const redirectUri = new URL(document.URL).origin + '/auth/callback';

export default {
  createAuthorizeURL: ({
    clientId,
    pointName,
    username,
  }: {
    clientId: string;
    pointName: string;
    username: string;
  }) => {
    const scope = 'api:access';
    const state = createState();
    const { codeVerifier, codeChallenge, codeChallengeMethod } = createPKCE();
    saveAuthTokenRequest({
      grantType: 'authorization_code',
      redirectUri,
      scope,
      state,
      codeVerifier,
      clientId,
      pointName,
      username,
    });
    return (
      `${env.server}/api/auth/v0/authorize` +
      '?response_type=code' +
      `&client_id=${clientId}` +
      `&redirect_uri=${redirectUri}` +
      `&scope=${scope}` +
      `&state=${state}` +
      `&code_challenge=${codeChallenge}` +
      `&code_challenge_method=${codeChallengeMethod}` +
      `&email=${username.replace(/\+/g, '%2B')}`
    );
  },

  createStartPasswordlessURL: ({
    clientId,
    pointName,
    connection,
    username,
  }: {
    clientId: string;
    pointName: string;
    connection: 'sms' | 'email';
    username: string;
  }) => {
    const scope = 'api:access';
    const state = createState();
    const { codeVerifier, codeChallenge, codeChallengeMethod } = createPKCE();
    saveAuthTokenRequest({
      grantType: 'https://api.themint.jp/oauth/grant-type/passwordless/otp',
      redirectUri,
      scope,
      state,
      codeVerifier,
      connection,
      username,
      clientId,
      pointName,
    });
    return (
      `${env.server}/api/auth/v0/passwordless/start` +
      `?client_id=${clientId}` +
      `&redirect_uri=${redirectUri}` +
      `&auth_scope=${scope}` +
      `&state=${state}` +
      `&code_challenge=${codeChallenge}` +
      `&code_challenge_method=${codeChallengeMethod}` +
      `&connection=${connection}` +
      (connection === 'email'
        ? `&email=${username.replace(/\+/g, '%2B')}`
        : '') +
      (connection === 'sms' ? `&phone_number=${username}` : '')
    );
  },

  getTwitterLoginURL: (f: (url: string) => void) =>
    loadAuthTokens().then((a) =>
      Linking.getInitialURL().then((url) =>
        f(
          `${env.server}/api/user/v0/twitter/login_forward` +
            `?access_token=${a.accessToken}` +
            `&redirect_uri=${new URL(url).origin}/account`,
        ),
      ),
    ),

  loginWithLine: ({
    clientId,
    pointId,
    pointName,
  }: {
    clientId: string;
    pointId: string;
    pointName: string;
  }): { isLoggedIn: boolean } => {
    saveAuthTokenRequest({
      grantType: 'openid',
      scope: 'api:access',
      redirectUri,
      clientId,
      pointName,
      idtokenProvider: `${pointId}@line`,
    });
    // liff.ready.then(() => !liff.isLoggedIn() && );
    if (liff.isLoggedIn()) {
      return { isLoggedIn: true };
    }
    liff.login({});
    return { isLoggedIn: false };
  },

  getAuthTokens: async (v: {
    clientId: string;
    code?: string;
    state?: string;
    passwordlessVerifier?: string;
    otp?: string;
  }): Promise<AuthTokens> => {
    const {
      grantType,
      redirectUri,
      scope,
      state,
      codeVerifier,
      idtokenProvider,
      connection: realm,
      username,
    } = await loadAuthTokenRequest();
    if (state && state !== v.state) {
      throw new ApplicationError('auth_failure');
    }
    const res = await axios
      .create({ baseURL: `${env.server}/api/auth/v0` })
      .post(
        '/token',
        {
          grant_type: grantType,
          client_id: v.clientId,
          redirect_uri: redirectUri,
          scope,
          ...(grantType === 'authorization_code' && {
            code: v.code,
            code_verifier: codeVerifier,
          }),
          ...(grantType === 'openid' && {
            idToken: liff.getIDToken(),
            idtoken_provider: idtokenProvider,
          }),
          ...(grantType ===
            'https://api.themint.jp/oauth/grant-type/passwordless/otp' && {
            realm,
            username,
            passwordless_verifier: v.passwordlessVerifier,
            otp: v.otp,
            code_verifier: codeVerifier,
          }),
        },
        {
          headers: { Accept: 'application/json' },
        },
      );
    return {
      clientId: v.clientId,
      accessToken: res.data.access_token,
      refreshToken: res.data.refresh_token,
    };
  },

  auth: () => axios.create({ baseURL: `${env.server}/api/auth/v0` }),

  status: () =>
    axios.create({
      baseURL: `${env.server}/api/status/v0`,
      responseType: 'json',
    }),

  image: () =>
    axios.createAuthorized({
      baseURL: `${env.server}/api/image/v0`,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      responseType: 'json',
    }),

  userUnauthorized: () =>
    axios.create({
      baseURL: `${env.server}/api/user/v0`,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      responseType: 'json',
    }),

  user: () =>
    axios.createAuthorized({
      baseURL: `${env.server}/api/user/v0`,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      responseType: 'json',
    }),

  token: () =>
    axios.createAuthorized({
      baseURL: `${env.server}/api/token/v0`,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      responseType: 'json',
    }),

  benefit: () =>
    axios.createAuthorized({
      baseURL: `${env.server}/api/benefit/v0`,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      responseType: 'json',
    }),

  quest: () =>
    axios.createAuthorized({
      baseURL: `${env.server}/api/quest/v0`,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      responseType: 'json',
    }),

  portal: () =>
    axios.create({
      baseURL: `${env.server}/api/portal/v0`,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      responseType: 'json',
    }),

  lottery: (options?: { auth?: boolean }) => {
    const o = { auth: true, ...(options ? options : {}) };
    return (o.auth ? axios.createAuthorized : axios.create)({
      baseURL: `${env.server}/api/lottery/v0`,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      responseType: 'json',
    });
  },

  listAll: async <T>(
    params: Object,
    get: (p: URLSearchParams) => Promise<T[]>,
  ) => {
    const limit: number = params['limit'] ? Number(params['limit']) : 100;
    let list: T[] = [];
    let add: T[];
    // eslint-disable-next-line no-constant-condition
    while (true) {
      add = await get(
        toURLSearchParams({
          limit,
          ...params,
          ...(list.length > 0 ? { since_id: list.slice(-1)[0]['id'] } : {}),
        }),
      );
      list = list.concat(add);
      if (add.length < limit) {
        break;
      }
    }
    return list;
  },
};

export * from './types';

function createState(): string {
  return randomString(32);
}

type PKCE = {
  codeVerifier: string;
  codeChallenge: string;
  codeChallengeMethod: 'plain' | 'S256';
};

function createPKCE(): PKCE {
  const verifier = randomString(128);
  return {
    codeVerifier: verifier,
    codeChallenge: verifier,
    codeChallengeMethod: 'plain',
  };
}
