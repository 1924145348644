import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Hyperlink from 'react-native-hyperlink';
import { useViewWidth } from '../../hooks';
import { TokenNoticeResource } from '../../mint';
import { Theme, withTheme } from '../../theme';
import { ItemImage, NoticeAmount, Wrapper } from '../elements';
import { formatDate, toImageSource } from '../utils';

const useStyles = ({ theme: { colors, typography } }: { theme: Theme }) => {
  return useMemo(
    () =>
      StyleSheet.create({
        imageContainer: {
          width: '100%',
          paddingTop: 16,
          paddingHorizontal: 24,
          paddingBottom: 24,
          backgroundColor: colors.card,
        },
        image: {
          borderRadius: 16,
        },
        bodyContainer: {
          overflow: 'hidden',
          borderRadius: 16,
          backgroundColor: colors.card,
        },
        body: {
          ...typography.paragraphM,
          paddingVertical: 16,
          paddingHorizontal: 16,
          color: colors.textCardPrimary,
        },
        amountContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          overflow: 'hidden',
          marginTop: 16,
          paddingVertical: 8,
          paddingHorizontal: 16,
          borderRadius: 16,
          backgroundColor: colors.card,
        },
        amount: {
          ...typography.paragraphM,
        },
        time: {
          ...typography.paragraphM,
          color: colors.textCardSecondary,
        },
        link: {
          margin: 0,
          padding: 0,
          color: colors.link,
        },
      }),
    [colors, typography],
  );
};

type Props = {
  theme: Theme;
  notice: TokenNoticeResource;
};

const Component: React.FC<Props> = ({ theme, notice }) => {
  const viewWidth = useViewWidth();
  const styles = useStyles({ theme });
  const deliveredAt = useMemo(
    () => formatDate('DateTime', new Date(notice.created_at)),
    [notice],
  );
  return (
    <Wrapper>
      {notice.has_image && (
        <View style={styles.imageContainer}>
          <ItemImage
            containerStyle={styles.image}
            size={viewWidth * 0.88}
            rounded
            source={toImageSource(notice.image)}
          />
        </View>
      )}
      <View
        style={{
          paddingTop: 24,
          paddingHorizontal: 24,
          paddingBottom: 40,
        }}
      >
        <View style={styles.bodyContainer}>
          <Hyperlink linkDefault linkStyle={styles.link}>
            <Text style={styles.body}>{notice.body}</Text>
          </Hyperlink>
        </View>
        <View style={styles.amountContainer}>
          <NoticeAmount
            style={styles.amount}
            iconSize={20}
            amount={notice.amount}
          />
          <Text style={styles.time}>{deliveredAt}</Text>
        </View>
      </View>
    </Wrapper>
  );
};

export type { Props as NoticeDetailProps };
export const NoticeDetail = withTheme(Component);
