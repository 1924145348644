import * as React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';

function Component(props: SvgProps) {
  return (
    <Svg width='32px' height='32px' viewBox='0 0 142 88' {...props}>
      <G fill='none' fillRule='evenodd'>
        <Path
          d='M61.367 63a1 1 0 01-1 1h-6.473a1 1 0 01-1-1V42.277c0-3.096-2.53-5.605-5.649-5.605-3.12 0-5.649 2.51-5.649 5.605V63a1 1 0 01-1 1h-6.473a1 1 0 01-1-1V42.277c0-3.096-2.529-5.605-5.648-5.605-3.12 0-5.649 2.51-5.649 5.605V63a1 1 0 01-1 1H14a1 1 0 01-1-1V30.314a1 1 0 011-1h6.826a1 1 0 011 1v2.521a11.483 11.483 0 018.296-3.521c4.21 0 7.889 2.249 9.886 5.602a11.485 11.485 0 019.885-5.602c6.337 0 11.474 5.098 11.474 11.387V63zm5.236-33.686h6.473a1 1 0 011 1V63a1 1 0 01-1 1h-6.473a1 1 0 01-1-1V30.314a1 1 0 011-1zM100.907 63V43.328c0-3.483-2.845-6.306-6.355-6.306-3.51 0-6.354 2.823-6.354 6.306V63a1 1 0 01-1 1h-6.473a1 1 0 01-1-1V30.31a1 1 0 011-1l6.473.004a1 1 0 011 1v4.315a11.486 11.486 0 019.708-5.315c6.337 0 11.474 5.098 11.474 11.387V63a1 1 0 01-1 1h-6.473a1 1 0 01-1-1zm20.83-26.376V29.31h9.06a1 1 0 011 1v5.314a1 1 0 01-1 1h-9.06z'
          fill='#0B0C1E'
        />
        <Path
          d='M67.038 26.972a16.963 16.963 0 011.285-14.225A16.963 16.963 0 0180 4.52a16.963 16.963 0 01-1.285 14.226 16.963 16.963 0 01-11.677 8.225z'
          fill='#1CDEE2'
        />
        <Path
          d='M131 56.251h-2.396c-3.456 0-6.259-2.838-6.259-6.34V25.867c0-.533-.393-.866-.982-.866h-6.879c-.3.18-.484.51-.484.866v26.687C114 58.875 119.06 64 125.301 64H131a1 1 0 001-1v-5.749a1 1 0 00-1-1z'
          fill='#0B0C1E'
        />
      </G>
    </Svg>
  );
}

export type { SvgProps as MintLogoProps };
export { Component as MintLogo };
