import {
  CaptureLotteryTask,
  GetLotteryTask,
  GetPortalTask,
  GetWalletTask,
  IssueTicketTask,
  ListBenefitTask,
  ListNoticeTask,
  ListTicketTask,
  ListTransactionTask,
  PointAcquisitionTask,
  UpdateHolderNotificationTask,
  UseTicketTask,
} from '.';
import {
  TokenHolderResource,
  TokenImageResource,
  TokenNoticeResource,
  TokenResource,
  TokenTransactionPersonResource,
  TokenTransactionResource,
} from '../../mint';
import { OrderTicketTask, TicketDefaultValuesTask } from './types';

export const emptyTimestamps = { created_at: '', updated_at: '' };

export const emptyImage: TokenImageResource = {
  id: '',
  url: '',
  uploaded: false,
};

export const emptyToken: TokenResource = {
  id: '',
  name: '',
  description: '',
  amount: 0,
  balances: 0,
  unit: 'ポイント',
  short_unit: 'pt',
  number_of_holders: 0,
  owner: {
    id: '',
    name: '',
    description: '',
    profile_image: emptyImage,
    created_at: '',
    updated_at: '',
  },
  profile_image: emptyImage,
  cover_image: emptyImage,
  ...emptyTimestamps,
};

export const emptyTokenTXPerson: TokenTransactionPersonResource = {
  wallet: '',
  name: '',
  balances: 0,
};

export const emptySelectedNotice: TokenNoticeResource = {
  id: '',
  token: emptyToken,
  organizer: '',
  body: '',
  amount: 0,
  has_image: false,
  release_time: '',
  ...emptyTimestamps,
};

export const emptySelectedTransaction: TokenTransactionResource = {
  id: '',
  amount: 0,
  from: emptyTokenTXPerson,
  to: emptyTokenTXPerson,
  created_at: '',
  token: null,
};

export const emptyHolder: TokenHolderResource = {
  token: '',
  wallet: '',
  balances: 0,
  name: '',
  screen_name: '',
  email_notifications_enabled: false,
  profile_image: emptyImage,
  ...emptyTimestamps,
};

export const emptyPortal: GetPortalTask = {
  status: '',
  loaded: false,
};

export const emptyWallet: GetWalletTask = {
  status: '',
};

export const emptyUpdateHolderEmailNotifications: UpdateHolderNotificationTask =
  {
    status: '',
  };

export const emptyListNotice: ListNoticeTask = {
  status: '',
  unread: 0,
  items: [],
};

export const emptyListTransaction: ListTransactionTask = {
  status: '',
  items: [],
};

export const emptyListBenefit: ListBenefitTask = {
  status: '',
  items: [],
};

export const emptyListAvailableVoucher: ListTicketTask = {
  type: 'available_voucher' as const,
  status: '',
  items: [],
};

export const emptyListAllVoucher: ListTicketTask = {
  type: 'all_voucher' as const,
  status: '',
  items: [],
};

export const emptyListOrder: ListTicketTask = {
  type: 'order' as const,
  status: '',
  items: [],
};

export const emptyTicketDefaultValues: TicketDefaultValuesTask = {
  status: '',
};

export const emptyPointAcquisition: PointAcquisitionTask = {
  status: '',
};

export const emptyIssueTicket: IssueTicketTask = {
  status: '',
};

export const emptyUseTicket: UseTicketTask = {
  status: '',
};

export const emptyOrderTicket: OrderTicketTask = {
  status: '',
};

export const emptyLottery: GetLotteryTask = {
  status: '',
};

export const emptyCaptureLottery: CaptureLotteryTask = {
  status: '',
  isWinning: false,
};
