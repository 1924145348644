import { Ionicons } from '@expo/vector-icons';
import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useViewWidth } from '../../hooks';
import { Ticket } from '../../states/point';
import { Theme, withTheme } from '../../theme';
import { Button, ItemImage, Wrapper } from '../elements';
import { formatDate, RGBA, toImageSource, toTicketInfo } from '../utils';
import { TicketInfo } from '../utils/types';

const useFooterStyles = ({
  theme: { colors, typography },
}: {
  theme: Theme;
}) => {
  return useMemo(() => {
    return StyleSheet.create({
      container: {
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 12,
        width: '100%',
        height: 80,
        backgroundColor: colors.card,
      },
      buttonContainer: {
        borderWidth: 1,
        borderRadius: 190,
        width: '100%',
        height: 56,
        backgroundColor: colors.card,
      },
      availableButtonContainer: {
        borderWidth: 0,
        backgroundColor: colors.primary,
      },
      buttonTitle: {
        ...typography.textL,
        fontWeight: 'bold',
        color: colors.card,
      },
    });
  }, [colors, typography]);
};

const Footer = withTheme<{
  theme: Theme;
  info: TicketInfo;
  onPress: () => void;
}>(({ theme, info, onPress }) => {
  const styles = useFooterStyles({ theme });
  return (
    <View style={styles.container}>
      <Button
        style={[
          styles.buttonContainer,
          info.available
            ? styles.availableButtonContainer
            : { borderColor: info.color },
        ]}
        disabled={!info.available}
        onPress={onPress}
      >
        <Text style={styles.buttonTitle}>
          {info.available ? (
            'チケットを使う'
          ) : (
            <Text style={{ color: info.color }}>
              <Ionicons name={info.icon} size={18} color={info.color} />
              {' ' + info.title}
            </Text>
          )}
        </Text>
      </Button>
    </View>
  );
});

const useStyles = ({ theme: { colors, typography } }: { theme: Theme }) => {
  return useMemo(() => {
    return StyleSheet.create({
      wrapper: {
        backgroundColor: new RGBA(colors.primary).setAlpha(0.2).toString(),
      },
      container: {
        alignItems: 'center',
        marginTop: 16,
        marginHorizontal: 24,
        marginBottom: 24,
        padding: 16,
        borderRadius: 16,
        backgroundColor: colors.card,
      },
      image: {
        borderRadius: 16,
      },
      title: {
        ...typography.titleL,
        marginTop: 16,
        color: colors.textCardPrimary,
      },
      deadlineContainer: {
        alignItems: 'center',
        height: 35,
        backgroundColor: '#f8cacc',
      },
      deadlineTitle: {
        ...typography.textM,
        marginTop: 8,
        color: colors.alertPrimary,
      },
      featureHint: {
        ...typography.textM,
        fontWeight: 'bold',
        color: colors.primary,
        marginTop: 8,
      },
      description: {
        ...typography.paragraphM,
        marginTop: 16,
        color: colors.textCardPrimary,
      },
      expireDateRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 16,
        paddingTop: 16,
        borderTopWidth: 1,
        width: '100%',
        borderTopColor: colors.border,
      },
      expireDate: {
        ...typography.paragraphM,
        color: colors.textCardPrimary,
      },
    });
  }, [colors, typography]);
};

type ModalState = 'loading' | 'confirm';

type ComponentRef = {
  setModalState: (s: ModalState) => void;
  hideModal: () => void;
};

type Props = {
  theme: Theme;
  ticket: Ticket;
  onPressUse: () => void;
};

const Component: React.FC<Props> = ({ theme, ticket, onPressUse }) => {
  const viewWidth = useViewWidth();
  const info = useMemo(() => toTicketInfo(theme, ticket), [theme, ticket]);
  const styles = useStyles({ theme });
  return (
    <Wrapper
      style={styles.wrapper}
      FooterComponent={<Footer info={info} onPress={onPressUse} />}
    >
      {ticket.state === 'available_voucher_expire_soon' && (
        <View style={styles.deadlineContainer}>
          <Text style={[styles.deadlineTitle]}>
            <Ionicons
              name={info.icon}
              size={16}
              color={theme.colors.alertPrimary}
            />
            {' ' + info.title}
          </Text>
        </View>
      )}
      <View style={styles.container}>
        <ItemImage
          containerStyle={styles.image}
          emptyImage='benefit'
          size={viewWidth * 0.8}
          rounded
          source={toImageSource(ticket.profileImage)}
        />
        <Text style={styles.title} numberOfLines={2}>
          {ticket.name}
        </Text>
        <Text style={styles.featureHint}>
          {ticket.feature === 'in_place' ? '1回だけ使える' : '何回でも使える'}
        </Text>
        <Text style={styles.description}>{ticket.description}</Text>
        <View style={styles.expireDateRow}>
          {!ticket.usedAt && (
            <>
              <Text style={styles.expireDate}>使用期限</Text>
              <Text style={styles.expireDate}>
                {ticket.expireTime
                  ? formatDate('DateTime', ticket.expireTime)
                  : 'なし'}
              </Text>
            </>
          )}
          {ticket.usedAt && (
            <>
              <Text style={styles.expireDate}>使用日時</Text>
              <Text style={styles.expireDate}>
                {formatDate('DateTime', ticket.usedAt)}
              </Text>
            </>
          )}
        </View>
      </View>
    </Wrapper>
  );
};

export type { ComponentRef as VoucherDetailRef, Props as VoucherDetailProps };
export const VoucherDetail = withTheme(Component);
