import React from 'react';
import { ActivityIndicator, Text, View } from 'react-native';
import { Theme, withTheme } from '../../theme';

type Props = {
  theme: Theme;
  title: string;
};

const Component: React.FC<Props> = ({
  theme: { colors, typography },
  title,
}) => (
  <View
    style={{
      flex: 1,
      justifyContent: 'center',
      backgroundColor: colors.white,
    }}
  >
    <Text
      style={{
        ...typography.textXL,
        textAlign: 'center',
        color: colors.black,
      }}
    >
      {title}
    </Text>
    <ActivityIndicator size={86} color={colors.primary} />
  </View>
);

export type { Props as LoadingProps };
export const Loading = withTheme(Component);
