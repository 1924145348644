import liff from '@line/liff/dist/lib';
import { Dispatch as ReactDispatch } from 'react';
import { TicketListType } from '.';
import * as analytics from '../../analytics';
import env from '../../env';
import { ApplicationError } from '../../lib/error';
import { getCurrentPosition } from '../../lib/geolocation';
import mint, {
  BenefitListMedia,
  BenefitTicketListMedia,
  BenefitTicketMedia,
  LoginProvider,
  LotteryTicketMedia,
  PortalMedia,
  Problem,
  QuestListMedia,
  TicketDefaultValuesResource,
  TicketResource,
  TokenHolderMedia,
  TokenNoticeListMedia,
  TokenResource,
  TokenStampListMedia,
  TokenStampMedia,
  TokenTransactionListMedia,
  TokenTransactionMedia,
  TokenTransactionResource,
  TokenWalletListMedia,
  TokenWalletMedia,
  TokenWalletResource,
} from '../../mint';
import { State as RootState } from '../store';
import actions, { Actions } from './actions';
import selectors from './selectors';
import { TakePictureAsync } from './types';
import {
  omitEmptyObjectAttribute as omitEmptyAttribute,
  toTicket,
} from './util';

type Dispatch = ReactDispatch<Actions>;
type GetRootState = () => RootState;

export default {
  getPortal:
    (tokenId: string) => async (dispatch: Dispatch, getState: GetRootState) => {
      const { status, loaded } = selectors.getPortal(getState());
      if (loaded || status === 'in_progress') {
        return;
      }
      analytics.setUserProperty('point_id', tokenId);
      dispatch(actions.startGetPoint());
      dispatch(actions.startListTransaction());
      dispatch(actions.startListNotice());
      dispatch(actions.startListBenefit());
      dispatch(actions.startListTicket({ type: 'available_voucher' }));
      dispatch(actions.startListTicket({ type: 'all_voucher' }));
      dispatch(actions.startListTicket({ type: 'order' }));
      try {
        const {
          token,
          benefits,
          stamps,
          site: { client_id: clientId, ui_settings: siteui },
        } = await mint
          .portal()
          .get<PortalMedia>(`/portals/${tokenId}`, {
            headers: { Accept: 'application/json' },
          })
          .then((res) => res.data);
        analytics.setUserProperty('point_id', token.id);
        if (token.profile_image.uploaded) {
          const favicon16 =
            document.querySelector('link[rel="icon"][sizes="16x16"]') ||
            document.createElement('link');
          favicon16.setAttribute(
            'href',
            token.profile_image.url.replace(
              /\/upload\//,
              '/upload/c_fill,h_16,w_16/',
            ),
          );
          const favicon32 =
            document.querySelector('link[rel="icon"][sizes="32x32"]') ||
            document.createElement('link');
          favicon32.setAttribute(
            'href',
            token.profile_image.url.replace(
              /\/upload\//,
              '/upload/c_fill,h_32,w_32/',
            ),
          );
        }
        await initLineLIFF(siteui.login.providers);
        dispatch(
          actions.successGetPoint({
            clientId,
            token,
            introStamp: stamps.filter((s) => s.spec === 'intro').pop(),
            siteui,
            benefits,
          }),
        );
      } catch (err) {
        dispatch(actions.setError(err));
        // dispatch(actions.resetGetPoint());
      }
    },
  resetGetPoint: actions.resetGetPoint,

  getWallet:
    (tokenId: string) => async (dispatch: Dispatch, getState: GetRootState) => {
      const { status } = selectors.getWallet(getState());
      if (status === 'in_progress') {
        return;
      }
      dispatch(actions.startGetWallet());
      try {
        const {
          pointAcquisition,
          tokenHolder,
          tokenNotices,
          tokenUnreadNoticeCount,
          tokenTransactions,
          tokenCheckInStampIDs,
          tokenIntroStampIDs,
          availableVouchers,
          allVouchers,
          orders,
          quests,
        } = await getPointWallet(tokenId)
          .then((existsWallet) => {
            if (existsWallet) {
              return toLoadTokenWalletResult(existsWallet);
            }
            return createTokenWallet(tokenId).then((newWallet) =>
              doFirstTimeAction(newWallet).then((res) =>
                toLoadTokenWalletResult(newWallet, res.transaction),
              ),
            );
          })
          .then((tokenRes) =>
            getHolderResources(getState, { walletId: tokenRes.id }).then(
              (holderRes) => ({
                token: tokenRes.token,
                pointAcquisition: tokenRes.acquisition,
                ...holderRes,
              }),
            ),
          );
        dispatch(
          actions.successGetWallet({
            acquisitionInfo: pointAcquisition,
            holder: tokenHolder,
            notices: tokenNotices,
            unreadNoticeCount: tokenUnreadNoticeCount,
            transactions: tokenTransactions,
            availableVouchers,
            allVouchers,
            orders,
            quests,
            checkinStampIDs: tokenCheckInStampIDs,
            introStampIDs: tokenIntroStampIDs,
          }),
        );
      } catch (err) {
        dispatch(actions.setError(err));
        dispatch(actions.resetGetWallet());
      }
    },
  initWallet: actions.initWallet,

  resetPointAcquisition: actions.resetPointAcquisition,

  updateHolderNotification:
    (v: { walletId: string; enabled: boolean }) =>
    async (dispatch: Dispatch) => {
      try {
        dispatch(actions.startUpdateHolderNotification());
        await mint
          .token()
          .post<TokenHolderMedia>(
            `/holders/${v.walletId}/update_email_notifications`,
            {
              enabled: v.enabled,
            },
          );
        dispatch(actions.successUpdateHolderNotification(v.enabled));
      } catch (err) {
        dispatch(actions.setError(err));
        dispatch(actions.resetUpdateHolderNotification());
      }
    },
  resetUpdateHolderEmailNotifications: actions.resetUpdateHolderNotification,

  listTransaction:
    (options?: { more?: boolean }) =>
    async (dispatch: Dispatch, getState: GetRootState) => {
      const { status, items } = selectors.listTransaction(getState());
      if (status === 'in_progress') {
        return;
      }
      dispatch(actions.startListTransaction());
      try {
        const { id: tokenId } = selectors.point(getState());
        const more = options && options.more && items.length >= 20;
        const params = new URLSearchParams([
          ['token', tokenId],
          ['order_by', '-created_at'],
          ['limit', '20'],
        ]);
        if (more) {
          params.append('since_id', items[items.length - 1].id);
        }
        dispatch(
          actions.successListTransaction(
            await mint
              .token()
              .get<TokenTransactionListMedia>('/journals?' + params)
              .then(({ data: { journals } }) =>
                more ? items.concat(journals) : journals,
              ),
          ),
        );
      } catch (err) {
        dispatch(actions.setError(err));
        dispatch(actions.resetListTransaction());
      }
    },

  listNotice:
    (options?: { more?: boolean }) =>
    async (dispatch: Dispatch, getState: GetRootState) => {
      const { status, items } = selectors.listNotice(getState());
      if (status === 'in_progress') {
        return;
      }
      dispatch(actions.startListNotice());
      try {
        const { id: tokenId } = selectors.point(getState());
        const more = options && options.more && items.length >= 20;
        const params = new URLSearchParams([
          ['token', tokenId],
          ['order_by', '-created_at'],
          ['limit', '20'],
        ]);
        if (more) {
          params.append('since_id', items[items.length - 1].id);
        }
        dispatch(
          actions.successListNotice(
            await mint
              .token()
              .get<TokenNoticeListMedia>('/news?' + params)
              .then(({ data: { news, unread } }) => ({
                notices: more ? items.concat(news) : news,
                unread,
              })),
          ),
        );
      } catch (err) {
        dispatch(actions.setError(err));
        dispatch(actions.resetListNoticeTask());
      }
    },
  markAsReadNotice:
    ({ id }: { id: string }) =>
    async (dispatch: Dispatch, getState: GetRootState) => {
      const { items } = selectors.listNotice(getState());
      const notice = items.find((e) => e.id === id);
      if (!notice || notice.read_at) {
        return;
      }
      dispatch(actions.markAsReadNotice({ id }));
      try {
        await mint
          .token()
          .post<TokenNoticeListMedia>(`/news/${id}/mark_as_read`);
      } catch (err) {
        dispatch(actions.setError(err));
        dispatch(actions.markAsUnreadNotice({ id }));
      }
    },

  capture:
    (
      data:
        | { type: 'id'; value: string }
        | { type: 'qrcode'; value: string }
        | { type: 'camera'; takePicture: TakePictureAsync },
    ) =>
    async (dispatch: Dispatch, getState: GetRootState) => {
      const tokenId = selectors.point(getState()).id;
      const siteui = selectors.siteUI(getState());
      dispatch(actions.startPointAcquisition());
      try {
        let stampId = '';
        const params: {
          images?: string[];
          latitude?: number;
          longitude?: number;
        } = {};
        switch (data.type) {
          case 'id':
            stampId = data.value;
            break;
          case 'qrcode':
            stampId = parseStampIdUrl(data.value);
            if (!stampId) {
              throw new ApplicationError('invalid_qr_format');
            }
            break;
          case 'camera': {
            const cameraConfig = [
              ...siteui.portal.menu.main,
              ...(siteui.portal.menu.more || []),
            ].find((item) => item.type === 'captureWithCamera') as {
              defaultStampId: string;
            };
            if (!cameraConfig || !cameraConfig.defaultStampId) {
              throw new ApplicationError('camera_settings_not_set');
            }
            stampId = cameraConfig.defaultStampId;
            params.images = [(await data.takePicture()).uri];
            break;
          }
        }
        // Get stamp
        const stamp = await mint
          .token()
          .get<TokenStampMedia>(`/stamps/${stampId}`)
          .then(({ data: { stamp } }) => stamp);
        if (stamp.token.id !== tokenId) {
          throw new ApplicationError('invalid_qr');
        }
        // Get current position
        if (stamp.is_require_location) {
          const { latitude, longitude } = await (
            await getCurrentPosition()
          ).coords;
          params.latitude = latitude;
          params.longitude = longitude;
        }
        // Capture stamp
        const captureRes = await mint
          .token()
          .post<TokenTransactionMedia>(`/stamps/${stampId}/capture`, params)
          .catch((pb: Problem) => {
            switch (pb.title) {
              case 'uncapturable_stamp':
                if (pb.detail === 'already captured') {
                  return Promise.reject(
                    new ApplicationError('out_of_photo_location'),
                  );
                }
                return Promise.reject(
                  new ApplicationError('unavailable_photo_spot'),
                );
              case 'out_of_location':
                return Promise.reject(
                  new ApplicationError('out_of_photo_location'),
                );
              case 'location_not_specified':
                return Promise.reject(
                  new ApplicationError('location_not_specified'),
                );
            }
            return Promise.reject(pb);
          });
        dispatch(actions.successPointAcquisition(captureRes.data.journal));
      } catch (err) {
        dispatch(
          actions.errorPointAcquisition(
            err instanceof Problem ? err : new Problem(err),
          ),
        );
      }
    },

  checkin: () => async (dispatch: Dispatch, getState: GetRootState) => {
    const { id: tokenId, name } = selectors.point(getState());
    dispatch(actions.startPointAcquisition());
    try {
      // Get current position
      const geoRes = await getCurrentPosition();
      const { latitude, longitude } = geoRes.coords;
      // Get stamps (Not far away geolocation stamps)
      const p = new URLSearchParams();
      p.append('token', tokenId);
      p.append('order_by', 'distance');
      p.append('latitude', `${latitude}`);
      p.append('longitude', `${longitude}`);
      const stampRes = await mint
        .token()
        .get<TokenStampListMedia>('/stamps?' + p);
      if (stampRes.data.stamps.length === 0) {
        throw new ApplicationError('checkin_stamp_not_found', {
          userMsg:
            `トップページにある「${name}とは」から` +
            'チェックインスポットをご確認ください。',
        });
      }
      // Capture stamp
      const stamp = stampRes.data.stamps[0];
      const captureRes = await mint
        .token()
        .post<TokenTransactionMedia>(`/stamps/${stamp.id}/capture`, {
          latitude,
          longitude,
        })
        .catch((pb: Problem) => {
          switch (pb.title) {
            case 'uncapturable_stamp':
              if (pb.detail === 'already captured') {
                return Promise.reject(
                  new ApplicationError('already_checked_in'),
                );
              }
              return Promise.reject(
                new ApplicationError('unavailable_checkin_spot'),
              );
            case 'out_of_location':
              return Promise.reject(
                new ApplicationError('out_of_checkin_location', {
                  userMsg: stamp.name
                    ? `現在位置から離れているため”${stamp.name}”にチェックインできません`
                    : '現在位置から離れているためチェックインできません',
                }),
              );
            case 'location_not_specified':
              return Promise.reject(
                new ApplicationError('location_not_specified'),
              );
          }
          return Promise.reject(pb);
        });
      dispatch(actions.successPointAcquisition(captureRes.data.journal));
    } catch (err) {
      dispatch(
        actions.errorPointAcquisition(
          err instanceof Problem ? err : new Problem(err),
        ),
      );
    }
  },

  listBenefit:
    (options?: { more?: boolean }) =>
    async (dispatch: Dispatch, getState: GetRootState) => {
      const { status, items } = selectors.listBenefit(getState());
      if (status === 'in_progress') {
        return;
      }
      dispatch(actions.startListBenefit());
      try {
        const { id: tokenId } = selectors.point(getState());
        const more = options && options.more && items.length >= 20;
        const params = new URLSearchParams([
          ['org', tokenId],
          ['order_by', '-updated_at'],
          ['limit', '20'],
        ]);
        if (more) {
          params.append('since_id', items[items.length - 1].id);
        }
        dispatch(
          actions.successListBenefit(
            await mint
              .portal()
              .get<BenefitListMedia>('/benefits?' + params)
              .then(({ data: { benefits } }) =>
                more ? items.concat(benefits) : benefits,
              ),
          ),
        );
      } catch (err) {
        dispatch(actions.setError(err));
        dispatch(actions.resetListBenefit());
      }
    },

  listTicket:
    ({ type, ...options }: { type: TicketListType; more?: boolean }) =>
    async (dispatch: Dispatch, getState: GetRootState) => {
      const { status, items } =
        type === 'available_voucher'
          ? selectors.listAvailableVoucher(getState())
          : selectors.listAllVoucher(getState());
      if (status === 'in_progress') {
        return;
      }
      dispatch(actions.startListTicket({ type }));
      try {
        const { id: tokenId } = selectors.point(getState());
        const more = options && options.more && items.length >= 20;
        const params = new URLSearchParams([
          ['org', tokenId],
          ['group', type === 'order' ? 'order' : 'voucher'],
          ['order_by', '-updated_at'],
          ['limit', '20'],
        ]);
        if (type === 'available_voucher') {
          params.append('state', 'issued');
        }
        if (more) {
          params.append('since_id', items[items.length - 1].id);
        }
        dispatch(
          actions.successListTicket({
            type,
            tickets: await mint
              .benefit()
              .get<BenefitTicketListMedia>('/tickets?' + params)
              .then(({ data: { tickets } }) => tickets.map(toTicket))
              .then((tickets) => (more ? items.concat(tickets) : tickets)),
          }),
        );
      } catch (err) {
        dispatch(actions.setError(err));
        dispatch(actions.resetListTicket({ type }));
      }
    },

  issueTicket: (benefitId: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.startIssueTicket());
      const {
        data: { ticket },
      } = await mint.benefit().post<BenefitTicketMedia>('/tickets/issue', {
        benefit: benefitId,
      });
      dispatch(actions.successIssueTicket(toTicket(ticket)));
    } catch (err) {
      dispatch(actions.setError(err));
      dispatch(actions.resetIssueTicket());
    }
  },
  resetIssueTicket: actions.resetIssueTicket,
  useTicket:
    (ticketId: string, option?: { email: string; message: string }) =>
    async (dispatch: Dispatch) => {
      try {
        dispatch(actions.startUseTicket());
        // const geoRes = await getCurrentPosition();
        // const { latitude, longitude } = geoRes.coords;
        const {
          data: { ticket },
        } = await mint
          .benefit()
          .post<BenefitTicketMedia>(`/tickets/${ticketId}/use`, {
            email: 'test@example.com', // FIXME:
            // latitude,
            // longitude,
            ...option,
          });
        dispatch(actions.successUseTicket(toTicket(ticket)));
      } catch (err) {
        dispatch(actions.setError(err));
        dispatch(actions.resetUseTicket());
      }
    },
  resetUseTicket: actions.resetUseTicket,
  orderTicket:
    (
      benefitId: string,
      form: {
        email: string;
        zipcode?: string;
        addressLine1?: string;
        addressLine2?: string;
        addressee?: string;
        message?: string;
        saveAsDefaultValues?: boolean;
      },
    ) =>
    async (dispatch: Dispatch) => {
      try {
        dispatch(actions.startUseTicket());
        const {
          data: { ticket },
        } = await mint.benefit().post<BenefitTicketMedia>(`/tickets/order`, {
          benefit: benefitId,
          email: form.email,
          ...omitEmptyAttribute({
            zipcode: form.zipcode,
            address_line1: form.addressLine1,
            address_line2: form.addressLine2,
            addressee: form.addressee,
            message: form.message,
            save_as_default_values: form.saveAsDefaultValues,
          }),
        });
        dispatch(actions.successOrderTicket(toTicket(ticket)));
      } catch (err) {
        dispatch(actions.setError(err));
        dispatch(actions.resetOrderTicket());
      }
    },
  resetOrderTicket: actions.resetOrderTicket,

  getTicketDefaultValues:
    () => async (dispatch: Dispatch, getState: GetRootState) => {
      const { status } = selectors.ticketDefaultValues(getState());
      if (status === 'in_progress') {
        return;
      }
      dispatch(actions.startGetTicketDefaultVAlues());
      try {
        const { id: tokenId } = selectors.point(getState());
        const params = new URLSearchParams([['org', tokenId]]);
        dispatch(
          actions.successGetTicketDefaultVAlues(
            await mint
              .benefit()
              .get<TicketDefaultValuesResource>(
                '/tickets/default_values?' + params,
              )
              .then(({ data }) => ({ values: data })),
          ),
        );
      } catch (err) {
        dispatch(actions.setError(err));
        dispatch(actions.resetGetTicketDefaultVAlues());
      }
    },

  getLottery: (id: string) => async (dispatch: Dispatch) => {
    dispatch(actions.startGetLottery());
    try {
      const {
        data: { ticket },
      } = await mint
        .lottery({ auth: true })
        .get<LotteryTicketMedia>(`/tickets/${id}`);
      dispatch(actions.successGetLottery(ticket));
    } catch (err) {
      dispatch(actions.setError(err));
      dispatch(actions.errorGetLottery());
    }
  },

  captureLottery: (id: string) => async (dispatch: Dispatch) => {
    dispatch(actions.startCaptureLottery());
    try {
      const startTime = new Date().getTime();
      // start communication
      const {
        data: {
          ticket: { benefit_ticket_id: ticket_id, transaction_id, won },
        },
      } = await mint
        .lottery({ auth: true })
        .post<LotteryTicketMedia>(`/tickets/${id}/capture`);
      let ticket: TicketResource = undefined;
      let transaction: TokenTransactionResource = undefined;
      if (ticket_id) {
        const { data } = await mint
          .benefit()
          .get<BenefitTicketMedia>(`/tickets/${ticket_id}`);
        ticket = data.ticket;
      }
      if (transaction_id) {
        const { data } = await mint
          .token()
          .get<TokenTransactionMedia>(`/journals/${transaction_id}`);
        transaction = data.journal;
      }
      // end communication
      const elapse = new Date().getTime() - startTime;
      if (elapse < 6000) {
        await new Promise((resolve) => setTimeout(resolve, 6000 - elapse));
      }
      dispatch(
        actions.successCaptureLottery({
          isWinning: won,
          ticket: ticket ? toTicket(ticket) : null,
          transaction,
        }),
      );
    } catch (err) {
      dispatch(actions.setError(err));
      dispatch(actions.errorCaptureLottery());
    }
  },
};

const first = <T>(list: T[]): T | null => (list.length > 0 ? list[0] : null);

type LoadTokenWalletResult = {
  id: string;
  token: TokenResource;
  acquisition?: TokenTransactionResource;
};

const toLoadTokenWalletResult = (
  w: TokenWalletResource,
  acquisition?: TokenTransactionResource,
): LoadTokenWalletResult => ({
  id: w.id,
  token: w.token,
  acquisition,
});

const getPointWallet = (tokenId: string) =>
  mint
    .token()
    .get<TokenWalletListMedia>(`/wallets?token=${tokenId}`, {
      headers: { Accept: 'application/json' },
    })
    .then((res) => first(res.data.wallets));

const createTokenWallet = (tokenId: string) =>
  mint
    .token()
    .post<TokenWalletMedia>(
      '/wallets/create',
      { token: tokenId },
      { headers: { Accept: 'application/json' } },
    )
    .then((res) => res.data.wallet);

// 登録時自動処理
const doFirstTimeAction = (w: TokenWalletResource) => {
  return Promise.all([
    // イントロスタンプのキャプチャー
    mint
      .token()
      .post<TokenTransactionMedia>(`/stamps/${w.token.intro_stamp}/capture`)
      .then(({ data: { journal: transaction } }) => ({ transaction }))
      .catch((p: Problem) =>
        p.title === 'not_found'
          ? Promise.resolve({ transaction: null })
          : Promise.reject(p),
      ),
    // Eメール通知をON
    mint.token().post(`/holders/${w.id}/update_email_notifications`, {
      enabled: true,
    }),
  ]).then((res) => ({
    transaction: res[0].transaction,
    ticket: null,
  }));
};

const getHolderResources = (
  getState: GetRootState,
  options: { walletId: string },
) => {
  const { id: tokenId } = selectors.point(getState());
  const tokenGet = <T>(url: string) => mint.token().get<T>(url);
  const benefitGet = <T>(url: string) => mint.benefit().get<T>(url);
  const questGet = <T>(url: string) => mint.quest().get<T>(url);
  return Promise.all([
    tokenGet<TokenHolderMedia>(`/holders/${options.walletId}`).then(
      ({ data: { holder } }) => holder,
    ),
    tokenGet<TokenTransactionListMedia>(`/journals?token=${tokenId}`).then(
      ({ data: { journals } }) => journals,
    ),
    tokenGet<TokenNoticeListMedia>(`/news?token=${tokenId}`).then(
      ({ data: { news, unread } }) => ({ notices: news, unread }),
    ),
    tokenGet<TokenStampListMedia>(`/stamps?token=${tokenId}&limit=500`).then(
      ({ data: { stamps } }) => stamps,
    ),
    benefitGet<BenefitTicketListMedia>(
      '/tickets?' +
        new URLSearchParams([
          ['org', tokenId],
          ['group', 'voucher'],
          ['state', 'issued'],
          ['order_by', '-created_at'],
          ['limit', '20'],
        ]),
    ).then(({ data: { tickets } }) => tickets),
    benefitGet<BenefitTicketListMedia>(
      '/tickets?' +
        new URLSearchParams([
          ['org', tokenId],
          ['group', 'voucher'],
          ['order_by', '-updated_at'],
          ['limit', '20'],
        ]),
    ).then(({ data: { tickets } }) => tickets),
    benefitGet<BenefitTicketListMedia>(
      '/tickets?' +
        new URLSearchParams([
          ['org', tokenId],
          ['group', 'order'],
          ['order_by', '-updated_at'],
          ['limit', '20'],
        ]),
    ).then(({ data: { tickets } }) => tickets),
    questGet<QuestListMedia>(
      '/quests?' + new URLSearchParams([['org', tokenId]]),
    ).then(({ data: { quests } }) => quests),
  ]).then(
    ([
      holder,
      transactions,
      notices,
      stamps,
      availableVouchers,
      allVouchers,
      orders,
      quests,
    ]) => ({
      tokenHolder: holder,
      tokenTransactions: transactions,
      tokenNotices: notices.notices,
      tokenUnreadNoticeCount: notices.unread,
      tokenCheckInStampIDs: stamps
        .filter((s) => s.spec === 'community_store_checkin')
        .map((s) => s.id),
      tokenIntroStampIDs: stamps
        .filter((s) => s.spec === 'community_store_intro')
        .map((s) => s.id),
      availableVouchers: availableVouchers.map(toTicket),
      allVouchers: allVouchers.map(toTicket),
      orders: orders.map(toTicket),
      quests,
    }),
  );
};

async function initLineLIFF(providers: LoginProvider[]) {
  if (!providers) {
    return;
  }
  const p = providers.find((p) => p.type === 'line');
  if (!p) {
    return;
  }
  await liff.init({ liffId: (p as { liff_id: string }).liff_id });
}

const parseStampIdUrl = (data: string) => {
  const server = env.server.replace(/^https{0,1}:\/\//, '');
  const s = data.replace(/^https{0,1}:\/\//, '');
  if (s.startsWith(`${server}/pt/`)) {
    return atob(
      s.replace(`${server}/pt/`, '').replace(/_/g, '/').replace(/-/g, '+'),
    )
      .split('')
      .map((c) => ('0' + (c.charCodeAt(0) & 0xff).toString(16)).slice(-2))
      .join('')
      .replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5');
  }
  return null;
};
