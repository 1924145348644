import { Route } from '../states/account';
import { storage } from './storage';

const key = 'nextRouteAfterSignIn';

export const saveNextRouteAfterSignIn = (routes: Route[]) => {
  if (routes.length > 0) {
    storage.save({
      key,
      data: routes.map(({ name, params }) => ({ name, params })),
    });
  } else {
    storage.remove({ key });
  }
  return routes;
};

export const loadNextRouteAfterSignIn = async () => {
  try {
    const route = await storage.load<Route[]>({ key });
    storage.remove({ key });
    return route;
  } catch (error) {
    if (error['name'] !== 'NotFoundError') {
      console.error(error);
    }
  }
  return [];
};
