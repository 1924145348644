import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Animated,
  FlatList,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle
} from 'react-native';
import { RefreshControl } from 'react-native-web-refresh-control';
import { EmptyTicketsImage } from '../../assets';
import { useViewWidth } from '../../hooks';
import { Ticket } from '../../states/point';
import { Theme, withTheme } from '../../theme';
import { Button, TicketListItem, Wrapper } from '../elements';
import { RGBA } from '../utils';
import { ListData } from './types';

const useTabButtonStyles = ({
  theme: { colors, typography },
}: {
  theme: Theme;
}) => {
  return useMemo(() => {
    return StyleSheet.create({
      container: { flex: 1, justifyContent: 'center', alignItems: 'center' },
      title: {
        ...typography.textM,
        fontWeight: 'bold',
        color: colors.textCardSecondary,
      },
      titleActive: {
        color: colors.primary,
      },
    });
  }, [colors, typography]);
};

type LR = 0 | 1;

const LEFT = 0;
const RIGHT = 1;

const TabButton = withTheme<{
  theme: Theme;
  lr: LR;
  title: string;
  active: boolean;
  onLayoutX: (x: number) => void;
  onPress: (lr: LR) => void;
}>(({ theme, lr, title, active, onLayoutX, onPress }) => {
  const styles = useTabButtonStyles({ theme });
  return (
    <TouchableOpacity
      style={styles.container}
      onLayout={({ nativeEvent: { layout } }) => onLayoutX(layout.x)}
      onPress={() => onPress(lr)}
    >
      <Text style={[styles.title, active && styles.titleActive]}>{title}</Text>
    </TouchableOpacity>
  );
});

const useTabBarStyles = ({
  theme: { colors },
  viewWidth,
}: {
  theme: Theme;
  viewWidth: number;
}) => {
  return useMemo(() => {
    return StyleSheet.create({
      container: {
        height: 93,
        alignItems: 'center',
      },
      tabBar: {
        marginTop: 16,
        position: 'relative',
        flexDirection: 'row',
        borderRadius: 126,
        width: viewWidth - 32,
        height: 53,
        backgroundColor: colors.card,
      },
      active: {
        position: 'absolute',
        margin: 8,
        width: viewWidth / 2 - 32,
        height: 37,
        top: 0,
        left: 0,
        borderRadius: 142,
        backgroundColor: new RGBA(colors.primary).setAlpha(0.1).toString(),
      },
    });
  }, [colors, viewWidth]);
};

type TabButtonData = {
  title: string;
  onLayoutX: (x: number) => void;
};

const TabBar = withTheme<{
  theme: Theme;
  viewWidth: number;
  activeStyle: Animated.WithAnimatedObject<ViewStyle>;
  active: LR;
  leftButton: TabButtonData;
  rightButton: TabButtonData;
  onPress: (tab: LR) => void;
}>(
  ({
    theme,
    viewWidth,
    active,
    activeStyle,
    leftButton,
    rightButton,
    onPress,
  }) => {
    const styles = useTabBarStyles({ theme, viewWidth });
    return (
      <View style={styles.container}>
        <View style={styles.tabBar}>
          <Animated.View style={[styles.active, activeStyle]} />
          <TabButton
            lr={LEFT}
            title={leftButton.title}
            active={LEFT === active}
            onLayoutX={leftButton.onLayoutX}
            onPress={onPress}
          />
          <TabButton
            lr={RIGHT}
            title={rightButton.title}
            active={RIGHT === active}
            onLayoutX={rightButton.onLayoutX}
            onPress={onPress}
          />
        </View>
      </View>
    );
  },
);

const useScreenStyles = () => {
  return useMemo(() => {
    return StyleSheet.create({
      container: {
        height: '100%',
      },
    });
  }, []);
};

const Screen = withTheme<{
  theme: Theme;
  lr: LR;
  style: Animated.WithAnimatedObject<ViewStyle>;
  children: React.ReactNode;
  onLayoutHeight?: (height: number) => void;
}>(({ lr, style, children, onLayoutHeight }) => {
  const styles = useScreenStyles();
  return (
    <Animated.View
      style={[styles.container, style]}
      onLayout={({ nativeEvent: { layout } }) => {
        if (lr === LEFT && onLayoutHeight) {
          onLayoutHeight(layout.height);
        }
      }}
    >
      {children}
    </Animated.View>
  );
});

const useEmptyStyles = ({
  theme: { colors, typography },
}: {
  theme: Theme;
}) => {
  return useMemo(() => {
    return StyleSheet.create({
      container: {
        flex: 1,
        alignItems: 'center',
      },
      title: {
        ...typography.paragraphXL,
        marginTop: 43,
        fontWeight: 'bold',
        textAlign: 'center',
        color: colors.textCardPrimary,
      },
      button: {
        marginTop: 32,
        width: 140,
        height: 45,
      },
    });
  }, [colors, typography]);
};

const Empty = withTheme<{
  theme: Theme;
  viewWidth: number;
  onPress: () => void;
}>(({ theme, viewWidth, onPress }) => {
  const styles = useEmptyStyles({ theme });
  const { paddingTop, width, height } = useMemo(
    () => ({
      paddingTop: viewWidth * 0.1,
      width: viewWidth * 0.51,
      height: viewWidth * 0.44,
    }),
    [viewWidth],
  );
  return (
    <View style={[styles.container, { paddingTop }]}>
      <Image
        style={{ width, height }}
        resizeMode='contain'
        source={EmptyTicketsImage}
      />
      <Text style={styles.title}>
        {'使用できる特典がありません\nまずは特典に交換してみましょう'}
      </Text>
      <Button style={styles.button} title='交換する' onPress={onPress} />
    </View>
  );
});

const List: React.FC<
  {
    viewWidth: number;
    onPressItem: (t: Ticket) => void;
    onPressEmpty: () => void;
  } & ListData<Ticket>
> = ({
  viewWidth,
  data,
  refreshing,
  onPressItem,
  onPressEmpty,
  onRefresh,
  onEndReached,
}) => (
  <FlatList<Ticket>
    contentContainerStyle={{ marginHorizontal: 16 }}
    refreshControl={
      <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
    }
    data={data}
    renderItem={({ item }) => (
      <TicketListItem
        viewWidth={viewWidth}
        ticket={item}
        onPress={() => onPressItem(item)}
      />
    )}
    ListEmptyComponent={() => (
      <Empty viewWidth={viewWidth} onPress={onPressEmpty} />
    )}
    keyExtractor={(item) => item.id}
    ItemSeparatorComponent={() => <View style={{ height: 16 }} />}
    ListFooterComponent={() => <View style={{ height: 100 }} />}
    onEndReached={onEndReached}
    onEndReachedThreshold={0.1}
  />
);

type Props = {
  availableData: ListData<Ticket>;
  allData: ListData<Ticket>;
  onPressItem: (t: Ticket) => void;
  onPressEmpty: () => void;
};

const Component: React.FC<Props> = ({
  availableData,
  allData,
  onPressItem,
  onPressEmpty,
}) => {
  const width = useViewWidth();
  const [active, setActive] = useState<LR>(LEFT);
  const [leftX, setLeftX] = useState(0);
  const [rightX, setRightX] = useState(0);
  const [trRightScreenY, setTrRightScreenY] = useState(0);
  const trTabX = useRef(new Animated.Value(0));
  const trLeftScreenX = useRef(new Animated.Value(0));
  const trRightScreenX = useRef(new Animated.Value(width));
  const moveTab = useCallback(
    (to: LR) => {
      setActive(to);
      Animated.parallel([
        Animated.spring(trTabX.current, {
          useNativeDriver: false,
          toValue: to === RIGHT ? rightX : leftX,
          friction: 10,
          tension: 100,
        }),
        Animated.timing(trLeftScreenX.current, {
          useNativeDriver: false,
          toValue: to === RIGHT ? -width : 0,
          duration: 100,
        }),
        Animated.timing(trRightScreenX.current, {
          useNativeDriver: false,
          toValue: to === RIGHT ? 0 : width,
          duration: 200,
        }),
      ]).start();
    },
    [rightX, leftX, width],
  );
  return (
    <Wrapper scrollDisabled>
      <TabBar
        viewWidth={width}
        active={active}
        activeStyle={{ transform: [{ translateX: trTabX.current }] }}
        leftButton={{ title: '使用可能', onLayoutX: setLeftX }}
        rightButton={{ title: 'すべて', onLayoutX: setRightX }}
        onPress={moveTab}
      />
      <View style={{ flex: 1 }}>
        <Screen
          lr={LEFT}
          style={{ transform: [{ translateX: trLeftScreenX.current }] }}
          onLayoutHeight={setTrRightScreenY}
        >
          <List
            viewWidth={width}
            onPressItem={onPressItem}
            onPressEmpty={onPressEmpty}
            {...availableData}
          />
        </Screen>
        <Screen
          lr={RIGHT}
          style={{
            transform: [
              { translateX: trRightScreenX.current },
              { translateY: -trRightScreenY },
            ],
          }}
        >
          <List
            viewWidth={width}
            onPressItem={onPressItem}
            onPressEmpty={onPressEmpty}
            {...allData}
          />
        </Screen>
      </View>
    </Wrapper>
  );
};

export type { Props as VoucherListProps };
export { Component as VoucherList };

