import * as Analytics from 'expo-firebase-analytics';

/**
 * https://firebase.google.com/docs/reference/js/firebase.analytics.Analytics#log-event
 */
export const logEvent = (
  eventName: string,
  eventParams?: { [key: string]: any },
) => {
  if (__DEV__) {
    console.info('Analytics.logEvent', { eventName, eventParams });
    return;
  }
  Analytics.logEvent(eventName, eventParams).catch(console.error);
};

/**
 * https://firebase.google.com/docs/reference/js/firebase.analytics.Analytics#set-analytics-collection-enabled
 */
export const setAnalyticsCollectionEnabled = (isEnabled: boolean) => {
  if (__DEV__) {
    console.info('Analytics.setAnalyticsCollectionEnabled', { isEnabled });
    return;
  }
  Analytics.setAnalyticsCollectionEnabled(isEnabled).catch(console.error);
};

/**
 * https://firebase.google.com/docs/reference/js/firebase.analytics.Analytics#set-current-screen
 */
export const setCurrentScreen = (
  screenName?: string,
  screenClassOverride?: string,
) => {
  logEvent('screen_view', {
    screen_name: screenName,
    screen_class: screenClassOverride,
  });
};

/**
 * https://firebase.google.com/docs/reference/js/firebase.analytics.Analytics#set-user-id
 */
export const setUserId = (userId: string | null) => {
  if (__DEV__) {
    console.info('Analytics.setUserId', { userId });
    return;
  }
  Analytics.setUserId(userId).catch(console.error);
};

export const setUserProperty = (name: string, value: string) => {
  if (__DEV__) {
    console.info('Analytics.setUserProperty', { name, value });
    return;
  }
  Analytics.setUserProperty(name, value).catch(console.error);
};

/**
 * https://firebase.google.com/docs/reference/js/firebase.analytics.Analytics#set-user-properties
 */
export const setUserProperties = (userProperties: { [key: string]: any }) => {
  if (__DEV__) {
    console.info('Analytics.setUserProperties', { userProperties });
    return;
  }
  Analytics.setUserProperties(userProperties).catch(console.error);
};

/**
 * Clears all analytics data for this instance.
 */
export const resetAnalyticsData = () => {
  if (__DEV__) {
    console.info('Analytics.resetAnalyticsData');
    return;
  }
  Analytics.resetAnalyticsData().catch(console.error);
};

/**
 * Enables or disabled debug mode.
 */
export const setDebugModeEnabled = (isEnabled: boolean) => {
  if (__DEV__) {
    console.info('Analytics.setDebugModeEnabled', { isEnabled });
    return;
  }
  Analytics.setDebugModeEnabled(isEnabled).catch(console.error);
};
