import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BenefitDetail } from '../components/pages';
import {
  useAuth,
  useBenefit,
  useGoBack,
  useNavigationHeader,
  usePoint,
} from '../hooks';
import { useModal } from '../hooks/useModal';
import { BenefitDetailNavigation, BenefitDetailRoute } from '../navigation';
import {
  operations as pointOperations,
  selectors as pointSelectors,
} from '../states/point';

type Props = {
  navigation: BenefitDetailNavigation;
  route: BenefitDetailRoute;
};

const Container: React.FC<Props> = ({ navigation, route }) => {
  const modal = useModal();
  useAuth();
  const { point } = usePoint();
  const { benefitId } = { benefitId: undefined, ...route.params };
  const dispatch = useDispatch();
  const holder = useSelector(pointSelectors.holder);
  const benefit = useBenefit({ id: benefitId });
  const task = useSelector(pointSelectors.issueTicket);
  const goBack = useGoBack(
    ({ goBack }) => {
      dispatch(pointOperations.resetIssueTicket());
      goBack();
    },
    [dispatch],
  );
  useNavigationHeader(
    () => ({ title: '特典ショップ', left: goBack }),
    [goBack],
  );
  const onIssued = useCallback(() => {
    navigation.reset({
      index: 1,
      routes: [
        { name: 'Portal' },
        { name: 'VoucherDetail', params: { ticketId: task.ticket.id } },
      ],
    });
    modal.close();
  }, [navigation, modal, task]);
  useEffect(() => {
    if (task.status === 'success') {
      dispatch(pointOperations.resetIssueTicket());
      modal.openPopUp({
        image: 'party-popper',
        title: 'チケットを獲得しました',
        body: `”${benefit?.name}”を獲得しました`,
        secondaryButton: { title: '閉じる', onPress: onIssued },
        backdrop: { onPress: onIssued },
      });
    }
  }, [dispatch, navigation, modal, task, benefit, onIssued]);
  const onPressIssue = useCallback(() => {
    if (benefit.feature === 'remote' || benefit.feature === 'delivery') {
      navigation.push('BenefitCompose', { benefitId: benefit.id });
      return;
    }
    modal.openPopUp({
      title: 'ポイントを交換しますか？',
      body:
        `「交換する」を押すと${benefit?.amount}${point?.short_unit}が消費され` +
        `${holder.balances - benefit?.amount}${point?.short_unit}` +
        `になります。交換しますか？`,
      primaryButton: {
        title: '交換する',
        onPress: () => {
          modal.openPopUp({
            image: 'indicator' as const,
            title: '通信中です',
          });
          dispatch(pointOperations.issueTicket(benefit.id));
        },
      },
      secondaryButton: {
        title: 'キャンセル',
        onPress: () => modal.close({ animated: true }),
      },
      backdrop: {
        onPress: () => modal.close({ animated: true }),
      },
    });
  }, [navigation, dispatch, modal, point, holder, benefit]);
  return (
    <BenefitDetail
      point={point}
      holder={holder}
      benefit={benefit}
      onPressIssue={onPressIssue}
    />
  );
};

export { Container as BenefitDetailView };
export default Container;
