import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { VoucherList } from '../components/pages';
import {
  useAuth,
  useGoBack,
  useListData,
  useListVoucher,
  useNavigationHeader,
  usePoint,
} from '../hooks';
import { VoucherListNavigation, VoucherListRoute } from '../navigation';
import { operations as pointOperations, TicketListType } from '../states/point';

const useVoucherListData = ({ type }: { type: TicketListType }) => {
  const dispatch = useDispatch();
  const { status, items: data } = useListVoucher({ type });
  const onLoad = useCallback(
    (type: TicketListType) => (v?: { more: boolean }) =>
      dispatch(pointOperations.listTicket({ type, ...v })),
    [dispatch],
  );
  return useListData({ data, status, onLoad: onLoad(type) });
};

type Props = {
  navigation: VoucherListNavigation;
  route: VoucherListRoute;
};

const Container: React.FC<Props> = ({ navigation }) => {
  useAuth();
  usePoint();
  const goBack = useGoBack();
  useNavigationHeader(
    () => ({ title: '電子チケットをつかう', left: goBack }),
    [goBack],
  );
  const availableData = useVoucherListData({ type: 'available_voucher' });
  const allData = useVoucherListData({ type: 'all_voucher' });
  const onPressItem = useCallback(
    ({ id }) => navigation.push('VoucherDetail', { ticketId: id }),
    [navigation],
  );
  const onPressEmpty = useCallback(() => {
    navigation.reset({ index: 0, routes: [{ name: 'BenefitList' }] });
  }, [navigation]);
  return (
    <VoucherList
      availableData={availableData}
      allData={allData}
      onPressItem={onPressItem}
      onPressEmpty={onPressEmpty}
    />
  );
};

export { Container as VoucherListView };
export default Container;
