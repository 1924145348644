import { TicketResource } from '../../mint';
import { Ticket } from './types';

export function omitEmptyObjectAttribute<T>(o: T): T {
  Object.keys(o).forEach((k) => {
    if (o[k] !== false && !o[k]) {
      delete o[k];
    }
  });
  return o;
}

export function toTicket(r: TicketResource): Ticket {
  const now = new Date().getTime();
  const exp = r.expire_time && new Date(r.expire_time).getTime();
  const t = <Ticket>{
    id: r.id,
    benefitId: r.benefit,
    group:
      r.feature === 'in_place' || r.feature === 'pass' ? 'voucher' : 'order',
    name: r.name,
    amount: r.amount,
    feature: r.feature,
    description: r.description,
    email: r.email,
    zipcode: r.zipcode,
    addressLine1: r.address_line1,
    addressLine2: r.address_line2,
    addressee: r.addressee,
    message: r.message,
    profileImage: r.profile_image,
    usedAt: r.used_at && new Date(r.used_at),
    expireTime: r.expire_time && new Date(r.expire_time),
    _state: r.state,
    state: 'unknown',
  };
  switch (t.group) {
    case 'voucher':
      if (exp && exp < now) {
        t.state = 'expired';
      } else if (t._state === 'issued') {
        t.state =
          exp && Math.floor((exp - now) / 86400000) < 6
            ? 'available_voucher_expire_soon'
            : 'available_voucher';
      } else {
        t.state = 'used_voucher';
      }
      break;
    case 'order':
      t.state = t._state === 'used' ? 'not_shipped_order' : 'shipped_order';
      break;
  }
  return t;
}
