import { Reducer } from 'redux';
import env from '../../env';
import { TaskStatus } from '../../lib/task';
import { Actions, ActionTypes } from './actions';

interface State {
  sourceVersion: string;
  checkVersionTask: {
    version: string;
    status: TaskStatus;
    timestamp: number;
  };
}

const initialState: State = {
  sourceVersion: env.sourceVersion,
  checkVersionTask: {
    version: '',
    status: '',
    timestamp: 0,
  },
};

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_CHECK_VERSION_TASK:
      return {
        ...state,
        checkVersionTask: {
          ...state.checkVersionTask,
          status: action.status,
          timestamp: new Date().getTime(),
        },
      };
    case ActionTypes.SUCCESS_CHECK_VERSION_TASK:
      return {
        ...state,
        checkVersionTask: {
          version: action.version,
          status: 'success',
          timestamp: new Date().getTime(),
        },
      };
    default:
      return state;
  }
};

export default reducer;
