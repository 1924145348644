import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { Linking } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { AuthStart } from '../components/pages';
import { usePoint } from '../hooks';
import mint, { LoginProvider } from '../mint';
import {
  AuthStartNavigation,
  AuthStartRoute,
  SignInActions,
} from '../navigation';
import {
  operations as accountOperations,
  selectors as accountSelectors,
} from '../states/account';
import { selectors as pointSelectors } from '../states/point';

const MINT_URL = 'https://themint.jp';
const MINT_POLICY_URL = 'https://themint.jp/about/policies.html';

type Props = {
  navigation: AuthStartNavigation;
  route: AuthStartRoute;
};

const Container: React.FC<Props> = ({ navigation }) => {
  const isFocused = navigation.isFocused();
  const {
    getPortal: { loaded },
    point,
  } = usePoint();
  const dispatch = useDispatch();
  const signinTask = useSelector(accountSelectors.getSigninTask);
  const introStamp = useSelector(pointSelectors.introStamp);
  const siteui = useSelector(pointSelectors.siteUI);
  const clientId = useSelector(pointSelectors.clientId);
  useLayoutEffect(() => {
    navigation.setOptions({ title: 'ログイン', headerShown: false });
  }, [navigation]);
  useEffect(() => {
    if (!isFocused || !loaded) {
      return;
    }
    switch (signinTask.status) {
      case '':
        dispatch(accountOperations.initializeAuth());
        break;
      case 'signed_in':
        navigation.dispatch(SignInActions.next(signinTask));
        break;
    }
  }, [navigation, isFocused, loaded, signinTask.status, signinTask, dispatch]);
  const onPressNext = useCallback(
    (connection: 'sms' | 'email', username: string) => {
      window.open(
        mint.createStartPasswordlessURL({
          clientId,
          pointName: point.name,
          connection,
          username,
        }),
        '_self',
      );
    },
    [clientId, point.name],
  );
  const onPressPasswordLogin = useCallback(
    (username: string) => {
      window.open(
        mint.createAuthorizeURL({ clientId, pointName: point.name, username }),
        '_self',
      );
    },
    [clientId, point.name],
  );
  const onPressSocialLogin = useCallback(
    (p: LoginProvider) => {
      switch (p.type) {
        case 'line':
          if (
            mint.loginWithLine({
              clientId,
              pointId: point.id,
              pointName: point.name,
            }).isLoggedIn
          ) {
            navigation.push('AuthCallback');
          }
          break;
      }
    },
    [clientId, navigation, point.id, point.name],
  );
  return (
    <AuthStart
      point={loaded ? point : null}
      introStamp={introStamp}
      siteui={siteui}
      isLoading={!loaded}
      onPressMintjp={() => Linking.openURL(MINT_URL)}
      onPressNext={onPressNext}
      onPressPasswordLogin={onPressPasswordLogin}
      onPressSocialLogin={onPressSocialLogin}
      onPressPolicy={() => Linking.openURL(MINT_POLICY_URL)}
    />
  );
};

export { Container as AuthStartView };
export default Container;
