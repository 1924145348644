import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrderList } from '../components/pages';
import {
  useAuth,
  useGoBack,
  useListData,
  useNavigationHeader,
  usePoint,
} from '../hooks';
import { OrderListNavigation, OrderListRoute } from '../navigation';
import {
  operations as pointOperations,
  selectors as pointSelectors,
} from '../states/point';

type Props = {
  navigation: OrderListNavigation;
  route: OrderListRoute;
};

const Container: React.FC<Props> = ({ navigation }) => {
  const dispatch = useDispatch();
  useAuth();
  usePoint();
  const goBack = useGoBack();
  useNavigationHeader(
    () => ({ title: '配送・メールで受け取る', left: goBack }),
    [goBack],
  );
  const { items, status } = useSelector(pointSelectors.listOrder);
  const onLoad = useCallback(
    () => (v?: { more: boolean }) =>
      dispatch(pointOperations.listTicket({ type: 'order', ...v })),
    [dispatch],
  );
  const data = useListData({ data: items, status, onLoad });
  const onPressItem = useCallback(
    ({ id }) => navigation.push('OrderDetail', { ticketId: id }),
    [navigation],
  );
  const onPressEmpty = useCallback(() => {
    navigation.reset({ index: 0, routes: [{ name: 'BenefitList' }] });
  }, [navigation]);
  return (
    <OrderList
      data={data}
      onPressItem={onPressItem}
      onPressEmpty={onPressEmpty}
    />
  );
};

export { Container as OrderListView };
export default Container;
