import { useEffect, useMemo, useState } from 'react';
import { Dimensions, ScaledSize } from 'react-native';
import { MAX_VIEW_WIDTH } from '../constants';

export function useWindowSize() {
  const defaultSize = useMemo(() => Dimensions.get('window'), []);
  const [size, setSize] = useState(defaultSize);
  useEffect(() => {
    const handler = ({ window: size }: { window: ScaledSize }) => {
      setSize(size);
    };
    Dimensions.addEventListener('change', handler);
    return () => {
      Dimensions.removeEventListener('change', handler);
    };
  }, []);
  return size;
}

export function useViewWidth() {
  const { width: w } = useWindowSize();
  return useMemo(() => (w > MAX_VIEW_WIDTH ? MAX_VIEW_WIDTH : w), [w]);
}
