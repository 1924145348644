import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, Text, View } from 'react-native';
import regexp from '../../lib/regexp';
import { BenefitResource, TokenResource } from '../../mint';
import { Theme, withTheme } from '../../theme';
import { Button, CheckBox, TextInput, Wrapper } from '../elements';

const useStyles = ({ theme: { colors, typography } }: { theme: Theme }) => {
  return useMemo(() => {
    return StyleSheet.create({
      messageContainer: {
        marginTop: 20,
        paddingHorizontal: 16,
      },
      messageText: {
        ...typography.textL,
        color: colors.textCardPrimary,
      },
      primary: {
        width: 208,
      },
      back: {
        marginTop: 32,
        width: 208,
        backgroundColor: colors.card,
        color: colors.textCardSecondary,
      },
      backTitle: {
        color: colors.textCardSecondary,
      },
    });
  }, [colors, typography]);
};

type FormState = {
  email: string;
  zipcode: string;
  addressLine1: string;
  addressLine2: string;
  addressee: string;
  message: string;
  saveAsDefaultValues: boolean;
};

type Props = {
  theme: Theme;
  loading: boolean;
  point: TokenResource;
  benefit: BenefitResource;
  defaultValues: Omit<FormState, 'message' | 'saveAsDefaultValues'>;
  onPressCompose: (form: FormState) => void;
};

const Component: React.FC<Props> = ({
  theme,
  benefit,
  defaultValues,
  ...props
}) => {
  const styles = useStyles({ theme });
  const isDelivery = useMemo(() => benefit?.feature === 'delivery', [benefit]);
  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: {
      errors: { zipcode: zipcodeErr },
    },
  } = useForm<FormState>({
    mode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: false,
    defaultValues: {
      ...defaultValues,
      message: '',
      saveAsDefaultValues: false,
    },
  });
  const [confiramation, setConfirmation] = useState(false);
  const zipcode = watch('zipcode');
  useEffect(() => {
    if (zipcode.length === 7 && !zipcodeErr) {
      axios
        .post('https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + zipcode)
        .then(({ data }) => {
          if (data.results[0]) {
            const { address1, address2, address3 } = data.results[0];
            setValue('addressLine1', address1 + address2 + address3);
          }
        })
        .catch(console.error);
    }
  }, [zipcode, zipcodeErr, setValue]);
  const confirm = handleSubmit(() => setConfirmation(true));
  const back = useCallback(() => setConfirmation(false), [setConfirmation]);
  const compose = useCallback(
    () => props.onPressCompose(getValues()),
    [props, getValues],
  );
  return (
    <Wrapper style={{ paddingHorizontal: 16 }}>
      <View style={styles.messageContainer}>
        <Text style={styles.messageText}>
          特典を送付するために、下記の情報についてご入力ください。
          住所などの誤入力による誤配送の責任は負いかねますので、ご注意ください。
        </Text>
      </View>
      <Controller
        name='email'
        control={control}
        rules={{
          required: 'メールアドレスを入力してください',
          pattern: {
            value: regexp.email,
            message: 'メールアドレスが正しくありません',
          },
          maxLength: {
            value: 254,
            message: 'メールアドレスは254文字以内で入力してください',
          },
        }}
        render={({ field, formState: { errors } }) => (
          <TextInput
            label='メールアドレス'
            placeholder='メールアドレス'
            keyboardType='email-address'
            containerStyle={{ marginTop: 32 }}
            editable={!props.loading}
            confirmation={confiramation}
            onEdit={() => setConfirmation(false)}
            // defaultValue={defaultValues.email}
            errorMessage={errors.email?.message}
            {...field}
          />
        )}
      />
      {isDelivery && (
        <>
          <Controller
            name='zipcode'
            control={control}
            rules={{
              required: '郵便番号を入力してください',
              pattern: {
                value: regexp.zipcodeJp,
                message:
                  '郵便番号は7桁の数字（ハイフン抜き）で入力してください',
              },
              minLength: {
                value: 7,
                message: '郵便番号は7桁の数字で入力してください',
              },
              maxLength: {
                value: 7,
                message: '郵便番号は7桁の数字で入力してください',
              },
            }}
            render={({ field, formState: { errors } }) => (
              <TextInput
                label='郵便番号'
                placeholder='郵便番号'
                keyboardType='number-pad'
                containerStyle={{ marginTop: 24 }}
                editable={!props.loading}
                confirmation={confiramation}
                onEdit={() => setConfirmation(false)}
                // defaultValue={defaultValues.zipcode}
                errorMessage={errors.zipcode?.message}
                {...field}
              />
            )}
          />
          <Controller
            name='addressLine1'
            control={control}
            rules={{
              required: '都道府県・市区町村・番地を入力してください',
              maxLength: {
                value: 256,
                message:
                  '都道府県・市区町村・番地は256文字以内で入力してください',
              },
            }}
            render={({ field, formState: { errors } }) => (
              <TextInput
                label='都道府県・市区町村・番地'
                placeholder='都道府県・市区町村・番地'
                containerStyle={{ marginTop: 24 }}
                editable={!props.loading}
                confirmation={confiramation}
                onEdit={() => setConfirmation(false)}
                // defaultValue={defaultValues.addressLine1}
                errorMessage={errors.addressLine1?.message}
                {...field}
              />
            )}
          />
          <Controller
            name='addressLine2'
            control={control}
            rules={{
              required: '建物名・号室を入力してください',
              maxLength: {
                value: 256,
                message: '建物名・号室は256文字以内で入力してください',
              },
            }}
            render={({ field, formState: { errors } }) => (
              <TextInput
                label='建物名・号室'
                placeholder='建物名・号室'
                containerStyle={{ marginTop: 24 }}
                editable={!props.loading}
                confirmation={confiramation}
                onEdit={() => setConfirmation(false)}
                // defaultValue={defaultValues.addressLine2}
                errorMessage={errors.addressLine2?.message}
                {...field}
              />
            )}
          />
          <Controller
            name='addressee'
            control={control}
            rules={{
              required: '宛名を入力してください',
              maxLength: {
                value: 128,
                message: '宛名は128文字以内で入力してください',
              },
            }}
            render={({ field, formState: { errors } }) => (
              <TextInput
                label='宛名'
                placeholder='宛名'
                containerStyle={{ marginTop: 24 }}
                editable={!props.loading}
                confirmation={confiramation}
                onEdit={() => setConfirmation(false)}
                // defaultValue={defaultValues.addressee}
                errorMessage={errors.addressee?.message}
                {...field}
              />
            )}
          />
        </>
      )}
      <Controller
        name='message'
        control={control}
        rules={{
          maxLength: {
            value: 280,
            message: 'メッセージは280文字以内で入力してください',
          },
        }}
        render={({ field, formState: { errors } }) => (
          <TextInput
            label='発行者へのメッセージ'
            placeholder={
              `“${props.point.owner.name}”へのメッセージを入力してください。\n` +
              '上記の項目以外に伝える情報がある場合はこちらに記入しましょう。'
            }
            multiline={true}
            numberOfLines={5}
            containerStyle={{ marginTop: 24 }}
            editable={!props.loading}
            confirmation={confiramation}
            onEdit={() => setConfirmation(false)}
            errorMessage={errors.message?.message}
            {...field}
          />
        )}
      />
      <Controller
        name='saveAsDefaultValues'
        control={control}
        render={({ field }) => (
          <CheckBox
            containerStyle={{ marginTop: 24 }}
            title='入力した情報を保存する'
            {...field}
          />
        )}
      />
      <View
        style={{
          marginTop: 48,
          marginBottom: 48,
          width: '100%',
          alignItems: 'center',
        }}
      >
        {!confiramation && (
          <Button
            style={styles.primary}
            title='次へ'
            loading={props.loading}
            // disabled={isValid}
            onPress={confirm}
          />
        )}
        {confiramation && (
          <>
            <Button
              style={styles.primary}
              title='申込を確定する'
              onPress={compose}
            />
            <Button
              style={styles.back}
              titleStyle={styles.backTitle}
              title='戻る'
              onPress={back}
            />
          </>
        )}
      </View>
    </Wrapper>
  );
};

export type { Props as TicketConmposeProps, FormState as BenefitComposeForm };
export const BenefitCompose = withTheme(Component);
