import React, { useEffect, useMemo } from 'react';
import { View } from 'react-native';
import {
  CustomBlockRenderer,
  CustomRendererProps,
  defaultHTMLElementModels,
  HTMLContentModel,
  HTMLElementModel,
  TBlock,
} from 'react-native-render-html';

const availableSites = ['https://www.youtube.com', 'https://www.instagram.com'];

const Component: React.FC<CustomRendererProps<TBlock>> = ({ style, tnode }) => {
  const id = useMemo(() => {
    if (
      !tnode.attributes.src ??
      !availableSites.find((site) => tnode.attributes.src.startsWith(site))
    ) {
      return null;
    }
    return tnode.attributes.src
      .replace(/^https:/, '')
      .replace(/\?.*$/, '')
      .replaceAll(/[./]/g, '_');
  }, [tnode]);
  useEffect(() => {
    if (id) {
      const e = document.querySelector(`#${id}`);
      if (e) {
        e.innerHTML =
          '<iframe scrolling="no" frameborder="no" ' +
          Object.keys(tnode.attributes)
            .map((key) => `${key}="${tnode.attributes[key]}"`)
            .join('') +
          '></iframe>';
      }
    }
  });
  if (!id) {
    return <></>;
  }
  return <View nativeID={id} style={style} />;
};

const Renderer: CustomBlockRenderer = (props) =>
  React.createElement(Component, props);

export const iframeModel: HTMLElementModel<'iframe', HTMLContentModel.block> =
  defaultHTMLElementModels.iframe.extend({
    contentModel: HTMLContentModel.block,
    isOpaque: true,
  });

export { Renderer as IframeRenderer };
