import { Camera } from 'expo-camera';
import Constants from 'expo-constants';
import * as ImagePicker from 'expo-image-picker';
import { ImagePickerResult } from 'expo-image-picker/build/ImagePicker.types';
import { ApplicationError } from '../error';

export const launchImageLibrary = async (): Promise<ImagePickerResult> => {
  if (Constants.platform.ios) {
    const { status } = await Camera.requestCameraPermissionsAsync();
    if (status !== ImagePicker.PermissionStatus.GRANTED) {
      throw new ApplicationError('camera_roll_permission_denied');
    }
  }
  try {
    return await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
  } catch (error) {
    console.error(error);
    throw new ApplicationError('camera_roll_error');
  }
};
