import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../components/elements';
import { VoucherDetail } from '../components/pages';
import {
  useAuth,
  useGoBack,
  useNavigationHeader,
  usePoint,
  useVoucher,
} from '../hooks';
import { useModal } from '../hooks/useModal';
import { VoucherDetailNavigation, VoucherDetailRoute } from '../navigation';
import {
  operations as pointOperations,
  selectors as pointSelectors,
} from '../states/point';

type Props = {
  navigation: VoucherDetailNavigation;
  route: VoucherDetailRoute;
};

const Container: React.FC<Props> = ({ navigation, route }) => {
  const modal = useModal();
  useAuth();
  usePoint();
  const { ticketId } = { ticketId: undefined, ...route.params };
  const dispatch = useDispatch();
  const ticket = useVoucher({ id: ticketId });
  const useTicketTask = useSelector(pointSelectors.useTicket);
  const goBack = useGoBack();
  useNavigationHeader(
    () => ({ title: '電子チケットをつかう', left: goBack }),
    [goBack],
  );
  useEffect(() => {
    if (useTicketTask.status === 'success') {
      modal.close();
      navigation.navigate('VoucherPresent', { ticketId: ticket.id });
      dispatch(pointOperations.resetUseTicket());
    }
  }, [navigation, dispatch, modal, useTicketTask.status, ticket]);
  const onPressUse = useCallback(() => {
    if (ticket.feature === 'pass') {
      navigation.navigate('VoucherPresent', { ticketId: ticket.id });
      return;
    }
    modal.openPopUp({
      title: '使用の直前に押してください',
      body:
        '「いますぐつかう」を押すと10分間のタイマーが開始します。' +
        'ボタンを押した後に表示される画面を発行者に見せて特典を受け取りましょう。',
      primaryButton: {
        title: '使用する',
        onPress: () => {
          modal.openPopUp({ image: 'indicator' as const, title: '通信中です' });
          dispatch(pointOperations.useTicket(ticket.id));
        },
      },
      secondaryButton: {
        title: 'キャンセル',
        onPress: () => modal.close({ animated: true }),
      },
      backdrop: { onPress: () => modal.close({ animated: true }) },
    });
  }, [navigation, dispatch, modal, ticket]);
  if (!ticket) {
    return <Loading title='通信中' />;
  }
  return <VoucherDetail ticket={ticket} onPressUse={onPressUse} />;
};

export { Container as VoucherDetailView };
export default Container;
