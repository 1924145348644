import { MaterialIcons } from '@expo/vector-icons';
import React, { useEffect, useMemo, useRef } from 'react';
import {
  Animated,
  Easing,
  Linking,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { SiteFooterItem, UserProfile } from '../../mint';
import { Theme, withTheme } from '../../theme';
import { MintLogo, Person } from '../elements';
import { setForwardRef, toImageSource } from '../utils';

const useItemStyles = ({ theme: { colors, typography } }: { theme: Theme }) =>
  useMemo(() => {
    return StyleSheet.create({
      container: {
        justifyContent: 'center',
        alignSelf: 'flex-end',
        paddingLeft: 16,
        width: '100%',
        height: 47,
        borderTopWidth: 1,
        borderTopColor: colors.border,
      },
      title: {
        ...typography.textM,
        color: colors.textBackgroundPrimary,
      },
    });
  }, [colors, typography]);

const Item = withTheme<{ theme: Theme; title: string; onPress: () => void }>(
  ({ theme, title, onPress }) => {
    const styles = useItemStyles({ theme });
    return (
      <TouchableOpacity style={styles.container} onPress={onPress}>
        <Text style={styles.title}>{title}</Text>
      </TouchableOpacity>
    );
  },
);

const useProfileStyles = ({
  theme: { colors, typography },
}: {
  theme: Theme;
}) =>
  useMemo(() => {
    return StyleSheet.create({
      container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: '83px',
        paddingLeft: 16,
        paddingRight: 16,
      },
      title: {
        color: colors.textBackgroundPrimary,
      },
      emptyPerson: { backgroundColor: colors.textBackgroundHidden },
      name: {
        ...typography.textM,
        color: colors.textBackgroundPrimary,
      },
      screenName: {
        ...typography.textS,
        color: colors.textBackgroundSecondary,
      },
    });
  }, [colors, typography]);

const Profile = withTheme<{
  theme: Theme;
  profile: UserProfile;
  onPressClose: () => void;
}>(({ theme, profile, onPressClose }) => {
  const styles = useProfileStyles({ theme });
  return (
    <View style={styles.container}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Person
          size={50}
          emptyImage='person'
          emptyStyle={styles.emptyPerson}
          source={toImageSource(profile.portraitImage)}
        />
        <View style={{ flexDirection: 'column', marginLeft: 10 }}>
          <Text style={styles.name}>{profile.name}</Text>
          <Text style={styles.screenName}>ID: {profile.screenName}</Text>
        </View>
      </View>
      <TouchableOpacity onPress={onPressClose}>
        <MaterialIcons
          name='close'
          size={24}
          color={theme.colors.textBackgroundHidden}
        />
      </TouchableOpacity>
    </View>
  );
});

const useFooterStyles = ({ theme: { colors, typography } }: { theme: Theme }) =>
  useMemo(() => {
    return StyleSheet.create({
      container: {
        padding: '16px',
        backgroundColor: colors.textBackgroundHidden,
      },
      text: {
        ...typography.textS,
        color: colors.card,
      },
      powerdByContainer: {
        flexDirection: 'row',
        marginTop: 6,
        marginBottom: 16,
      },
      mintLink: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 35,
      },
    });
  }, [colors, typography]);

const Footer = withTheme<{
  theme: Theme;
  ownerName: string;
  additionalFooters: SiteFooterItem[];
  onPressPolicy: () => void;
  onPressInquiry: () => void;
  onPressMintjp: () => void;
}>(
  ({
    theme,
    ownerName,
    additionalFooters,
    onPressPolicy,
    onPressInquiry,
    onPressMintjp,
  }) => {
    const styles = useFooterStyles({ theme });
    return (
      <View style={styles.container}>
        <Text style={styles.text} onPress={onPressPolicy}>
          プライバシーポリシー
        </Text>
        {additionalFooters.map((item) => {
          switch (item.type) {
            case 'link':
              return (
                <Text
                  style={[styles.text, { marginTop: '16px' }]}
                  onPress={() => Linking.openURL(item.href)}
                >
                  {item.label}
                </Text>
              );
          }
        })}
        <Text
          style={[styles.text, { marginTop: '16px' }]}
          onPress={onPressInquiry}
        >
          よくある質問・お問い合わせ
        </Text>
        <Text style={[styles.text, { marginTop: '16px' }]}>@{ownerName}</Text>
        <View style={styles.powerdByContainer}>
          <TouchableOpacity style={styles.mintLink} onPress={onPressMintjp}>
            <Text style={styles.text}>Powerd by </Text>
            <MintLogo />
          </TouchableOpacity>
        </View>
      </View>
    );
  },
);

const useStyles = ({
  theme: { colors },
  width,
}: {
  theme: Theme;
  width: number;
}) =>
  useMemo(() => {
    return StyleSheet.create({
      container: {
        width,
        height: '100%',
        backgroundColor: colors.backgroundLight,
        justifyContent: 'space-between',
      },
    });
  }, [colors, width]);

type ComponentRef = {
  hideAnimation: () => Animated.CompositeAnimation;
};

type Props = {
  theme: Theme;
  viewWidth: number;
  profile: UserProfile;
  ownerName: string;
  additionalFooters: SiteFooterItem[];
  onPressPolicy: () => void;
  onPressInquiry: () => void;
  onPressMintjp: () => void;
  onPressSettings: () => void;
  onPressClose: () => void;
};

const Component = React.forwardRef<ComponentRef, Props>(
  ({ theme, profile, viewWidth, ...props }, ref) => {
    const width = useMemo(() => viewWidth * 0.8, [viewWidth]);
    const styles = useStyles({ theme, width });
    const trX = useRef(new Animated.Value(0));
    useEffect(() => {
      Animated.timing(trX.current, {
        useNativeDriver: false,
        toValue: 1,
        duration: 300,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      }).start();
    }, []);
    useEffect(() => {
      setForwardRef(ref, {
        hideAnimation: () =>
          Animated.timing(trX.current, {
            useNativeDriver: false,
            toValue: 0,
            duration: 300,
            easing: Easing.bezier(0.4, 0.0, 0.2, 1),
          }),
      });
    }, [ref]);
    return (
      <Animated.View
        style={[
          styles.container,
          {
            transform: [
              {
                translateX: trX.current.interpolate({
                  inputRange: [0, 1],
                  outputRange: [-width, 0],
                }),
              },
            ],
          },
        ]}
      >
        <View>
          <Profile profile={profile} onPressClose={props.onPressClose} />
          <Item title='アカウント設定' onPress={props.onPressSettings} />
        </View>
        <Footer
          ownerName={props.ownerName}
          additionalFooters={props.additionalFooters}
          onPressPolicy={props.onPressPolicy}
          onPressInquiry={props.onPressInquiry}
          onPressMintjp={props.onPressMintjp}
        />
      </Animated.View>
    );
  },
);

export type { ComponentRef as DrawerMenuRef };
export const DrawerMenu = withTheme(Component);
