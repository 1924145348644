import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useCallback, useEffect, useMemo } from 'react';
import { Linking } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootParamList } from '../navigation';
import {
  operations as pointOperations,
  selectors as pointSelectors,
} from '../states/point';
import { useModal } from './useModal';

const useAcquisitionPopUp = (
  factory?: () => {
    onPressClose: () => void;
  },
  deps?: React.DependencyList,
) => {
  const navigation = useNavigation<StackNavigationProp<RootParamList>>();
  const dispatch = useDispatch();
  const acq = useSelector(pointSelectors.pointAcquisition);
  const isFocused = navigation.isFocused();
  const modal = useModal();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const options = useMemo(() => (factory ? factory() : null), deps);
  const close = useCallback(() => {
    if (options) {
      options.onPressClose();
    }
    modal.close({ animated: true });
  }, [options, modal]);
  useEffect(() => {
    if (!isFocused) return;
    switch (acq.status) {
      case 'success':
        dispatch(pointOperations.resetPointAcquisition());
        modal.openPopUp({
          image: 'coin',
          title:
            `${acq.transaction?.amount}` +
            `${acq.transaction?.token?.short_unit}もらいました`,
          body: acq.transaction?.meta?.message,
          primaryButton: acq.transaction?.meta?.message && {
            title: '確認する',
            onPress: () => {
              modal.close();
              navigation.reset({
                index: 1,
                routes: [
                  { name: 'Portal' },
                  {
                    name: 'HistoryDetail',
                    params: {
                      transactionId: acq.transaction?.id,
                    },
                  },
                ],
              });
            },
          },
          secondaryButton: { title: '閉じる', onPress: close },
          backdrop: { onPress: close },
        });
        break;
      case 'error':
        dispatch(pointOperations.resetPointAcquisition());
        modal.openPopUp({
          image: 'alert',
          title: acq.error.userTitle,
          body: acq.error.userMsg,
          primaryButton: acq.error.title ===
            'geolocation_permission_denied' && {
            title: '方法を確認',
            onPress: () =>
              Linking.openURL(
                'https://themint.zendesk.com/hc/ja/articles/4414110834969',
              ),
          },
          secondaryButton: { title: '閉じる', onPress: close },
          backdrop: { onPress: close },
        });
        break;
    }
  }, [isFocused, navigation, dispatch, modal, close, acq]);
};

export { useAcquisitionPopUp };
export default useAcquisitionPopUp;
