import React from 'react';
import { useColorScheme } from 'react-native';
import { DEFAULT_THEME_SETTINGS } from './constants';
import { ThemeContext } from './context';
import { Theme } from './types';

export const ThemeProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <ThemeContext.Provider value={DEFAULT_THEME_SETTINGS}>
    {children}
  </ThemeContext.Provider>
);

export function withTheme<P extends { theme: Theme }>(
  Component: React.ComponentType<P>,
) {
  return React.forwardRef(function (props: Omit<P, 'theme'>, ref?) {
    const colorScheme = useColorScheme();
    return (
      <ThemeContext.Consumer>
        {function (settings) {
          const theme: Theme = {
            colors:
              colorScheme === 'dark'
                ? settings.colors.dark
                : settings.colors.light,
            typography: settings.typography,
          };
          return <Component ref={ref} {...{ ...(props as P), theme }} />;
        }}
      </ThemeContext.Consumer>
    );
  });
}
