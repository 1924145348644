import React from 'react';
import { useSelector } from 'react-redux';
import { Loading } from '../components/elements';
import { Membership } from '../components/pages';
import { useAuth, useGoBack, useNavigationHeader, usePoint } from '../hooks';
import { MembershipNavigation, MembershipRoute } from '../navigation';
import { selectors as pointSelectors } from '../states/point';

type Props = {
  navigation: MembershipNavigation;
  route: MembershipRoute;
};

const Container: React.FC<Props> = () => {
  useAuth();
  const {
    point,
    getPortal: { loaded },
  } = usePoint();
  const holder = useSelector(pointSelectors.holder);
  const quest = useSelector(pointSelectors.holderTitleQuest);
  const goBack = useGoBack();
  useNavigationHeader(
    () => ({ title: quest && quest.name, left: goBack }),
    [goBack, quest],
  );
  if (!loaded || !holder.wallet || !quest) {
    return <Loading title='通信中' />;
  }
  return <Membership point={point} holder={holder} quest={quest} />;
};

export { Container as MembershipView };
export default Container;
