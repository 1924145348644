import { BarCodeScanner } from 'expo-barcode-scanner';
import { BarCodeScanningResult, Camera } from 'expo-camera';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Theme, withTheme } from '../../theme';

const useStyles = ({ theme: { colors } }: { theme: Theme }) => {
  return useMemo(() => {
    return StyleSheet.create({
      container: {
        flex: 1,
      },
      camera: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.black,
      },
      cornerContainer: {
        width: '75vw',
        height: '75vw',
        justifyContent: 'space-between',
      },
      cornerRowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      corner: {
        width: 25,
        height: 25,
        borderColor: colors.white,
      },
      cornerTopLeft: {
        borderTopLeftRadius: 12,
        borderTopWidth: 6,
        borderLeftWidth: 6,
      },
      cornerTopRight: {
        borderTopRightRadius: 12,
        borderTopWidth: 6,
        borderRightWidth: 6,
      },
      cornerBottomLeft: {
        borderBottomLeftRadius: 12,
        borderBottomWidth: 6,
        borderLeftWidth: 6,
      },
      cornerBottomRight: {
        borderBottomRightRadius: 12,
        borderBottomWidth: 6,
        borderRightWidth: 6,
      },
    });
  }, [colors]);
};

type Props = {
  theme: Theme;
  onScanned: (event: { type: string; data: string }) => void;
};

const Component: React.FC<Props> = ({ theme, onScanned }) => {
  const styles = useStyles({ theme });
  // FIXME: ネイティブアプリにする際はパーミッションチェックをする
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    setDisabled(false);
    return () => setDisabled(true);
  }, []);
  const onBarCodeScanned = useCallback(
    (result: BarCodeScanningResult) => {
      if (disabled) return;
      setDisabled(true);
      onScanned(result);
    },
    [disabled, onScanned],
  );
  return (
    <View style={styles.container}>
      <Camera
        style={styles.camera}
        type={Camera.Constants.Type.back}
        barCodeScannerSettings={
          disabled
            ? null
            : {
                barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
              }
        }
        onBarCodeScanned={onBarCodeScanned}
      >
        <View style={styles.cornerContainer}>
          <View style={styles.cornerRowContainer}>
            <View style={[styles.corner, styles.cornerTopLeft]} />
            <View style={[styles.corner, styles.cornerTopRight]} />
          </View>
          <View style={styles.cornerRowContainer}>
            <View style={[styles.corner, styles.cornerBottomLeft]} />
            <View style={[styles.corner, styles.cornerBottomRight]} />
          </View>
        </View>
      </Camera>
    </View>
  );
};

export type { Props as ScannerProps };
export const Scanner = withTheme(Component);
