import { createURL } from 'expo-linking';
import env from '../env';

export function getPointId(): string {
  const host = new URL(createURL('/')).hostname;
  if (host === 'localhost') {
    return env.defaultTokenId;
  }
  return host;
}
