import { DocumentTitleOptions } from '@react-navigation/native';
import { TokenResource } from '../mint';

const DOCUMENT_TITLES = new Map<string, string | null>([
  ['AuthStart', null],
  ['AuthLogout', null],
  ['AuthVerification', 'ログイン'],
  ['AuthCallback', 'ログイン'],
  ['Portal', null],
]);

export function getDocumentTitleOptions(
  point: TokenResource,
): DocumentTitleOptions {
  return {
    formatter: (options, route) => {
      const pn = point?.name;
      const title = DOCUMENT_TITLES.has(route?.name)
        ? DOCUMENT_TITLES.get(route?.name)
        : options?.title;
      return pn && title ? `${title} - ${pn}` : pn ?? title ?? 'mint';
    },
  };
}
