import React, { useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { TokenHolderResource, UserProfile } from '../../mint';
import { Theme, withTheme } from '../../theme';
import { Person, Wrapper } from '../elements';
import { toImageSource } from '../utils';

const useStyles = ({ theme: { colors, typography } }: { theme: Theme }) => {
  return useMemo(
    () =>
      StyleSheet.create({
        emptyPerson: {
          backgroundColor: colors.textBackgroundHidden,
        },
        name: {
          ...typography.textM,
          color: colors.textBackgroundPrimary,
        },
        screenName: {
          ...typography.textS,
          marginTop: 5,
          color: colors.textBackgroundSecondary,
        },
        itemContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: '50px',
          paddingHorizontal: 16,
        },
        itemLabel: {
          ...typography.textM,
          width: '100%',
          opacity: 0.8,
          color: colors.textBackgroundPrimary,
        },
        itemValue: {
          ...typography.textS,
          width: '100%',
          opacity: 0.8,
          color: colors.textBackgroundSecondary,
        },
        itemBorder: {
          width: '100%',
          height: 1,
          borderBottomWidth: 1,
          borderBottomColor: colors.border,
        },
        buttonContainer: {
          justifyContent: 'center',
          alignItems: 'center',
          height: 30,
          paddingHorizontal: 10,
          paddingVertical: 6,
          borderWidth: 1,
          borderRadius: 15,
          borderColor: colors.textBackgroundHidden,
        },
        buttonLabel: {
          ...typography.textS,
          color: colors.textBackgroundSecondary,
        },
      }),
    [colors, typography],
  );
};

type ItemProps = {
  styles: ReturnType<typeof useStyles>;
  label: string;
  value?: string;
  buttonLabel?: string;
  onPress: () => void;
};

const Item: React.FC<ItemProps> = ({ styles, ...props }) => (
  <>
    <TouchableOpacity style={styles.itemContainer} onPress={props.onPress}>
      <View
        style={{
          justifyContent: 'center',
        }}
      >
        <Text style={styles.itemLabel} numberOfLines={1}>
          {props.label}
        </Text>
        {props.value && (
          <Text style={styles.itemValue} numberOfLines={1}>
            {props.value}
          </Text>
        )}
      </View>
      {props.buttonLabel && (
        <View style={styles.buttonContainer}>
          <Text style={styles.buttonLabel}>{props.buttonLabel}</Text>
        </View>
      )}
    </TouchableOpacity>
    <View style={styles.itemBorder} />
  </>
);

type PressEvent = {
  name: 'profile' | 'email' | 'phone' | 'password' | 'twitter' | 'logout';
};

type Props = {
  theme: Theme;
  profile: UserProfile;
  holder: TokenHolderResource;
  onPress: (event: PressEvent) => void;
};

const Component: React.FC<Props> = ({ theme, profile, holder, onPress }) => {
  const styles = useStyles({ theme });
  return (
    <Wrapper>
      <TouchableOpacity
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: '83px',
          paddingHorizontal: 16,
          borderBottomWidth: 1,
          borderBottomColor: theme.colors.border,
        }}
        onPress={() => onPress({ name: 'profile' })}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Person
            size={50}
            emptyImage='person'
            emptyStyle={styles.emptyPerson}
            source={toImageSource(profile.portraitImage)}
          />
          <View style={{ flexDirection: 'column', marginLeft: 16 }}>
            <Text style={styles.name}>{profile.name}</Text>
            <Text style={styles.screenName}>ID: {profile.screenName}</Text>
          </View>
        </View>
        <View style={styles.buttonContainer}>
          <Text style={styles.buttonLabel}>編集</Text>
        </View>
      </TouchableOpacity>
      <Item
        styles={styles}
        label='メールアドレス'
        value={
          profile.email
            ? `${profile.email}, ` +
              `通知:${holder.email_notifications_enabled ? 'ON' : 'OFF'}`
            : '未設定'
        }
        onPress={() => onPress({ name: 'email' })}
      />
      <Item
        styles={styles}
        label='電話番号'
        value={
          profile.phoneNumber
            ? '*******' + profile.phoneNumber.slice(-4)
            : '未設定'
        }
        onPress={() => onPress({ name: 'phone' })}
      />
      <Item
        styles={styles}
        label='パスワード'
        value={profile.passwordRegistered ? '設定済み' : '未設定'}
        onPress={() => onPress({ name: 'password' })}
      />
      <Item
        styles={styles}
        label='Twitter連携'
        {...(profile.twitterConnected
          ? { buttonLabel: '連携解除', value: '連携中' }
          : { buttonLabel: '連携する' })}
        value={profile.twitterConnected ? '接続済み' : '未接続'}
        onPress={() => onPress({ name: 'twitter' })}
      />
      <Item
        styles={styles}
        label='ログアウト'
        onPress={() => onPress({ name: 'logout' })}
      />
    </Wrapper>
  );
};

export type {
  Props as AccountSettingsProps,
  PressEvent as AccountSettingsPressEvent,
};
export const AccountSettings = withTheme(Component);
