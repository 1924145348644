import React, { useEffect, useLayoutEffect } from 'react';
import { ActivityIndicator, Text, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { useAuth, usePoint } from '../hooks';
import { AuthLogoutNavigation, AuthLogoutRoute } from '../navigation';
import { operations as accountOperations } from '../states/account';
import { Theme, withTheme } from '../theme';

const Component = withTheme<{ theme: Theme }>(
  ({ theme: { colors, typography } }) => (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        backgroundColor: colors.white,
      }}
    >
      <Text
        style={{
          ...typography.textXL,
          textAlign: 'center',
          color: colors.black,
        }}
      >
        ログアウト中
      </Text>
      <ActivityIndicator size={86} color={colors.primary} />
    </View>
  ),
);

type Props = {
  navigation: AuthLogoutNavigation;
  route: AuthLogoutRoute;
};

const Container: React.FC<Props> = ({ navigation }) => {
  useAuth();
  usePoint();
  const isFocused = navigation.isFocused();
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    navigation.setOptions({ title: 'ログアウト', headerShown: false });
  }, [navigation]);
  useEffect(() => {
    if (isFocused) dispatch(accountOperations.signout());
  }, [dispatch, isFocused]);
  return <Component />;
};

export { Container as AuthLogoutView };
export default Container;
