import React, { useCallback, useMemo, useState } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { DefaultPointCoverImage } from '../../assets';
import { useViewWidth } from '../../hooks';
import {
  LoginMethod,
  LoginProvider,
  SiteUISettings,
  TokenResource,
  TokenStampResource,
} from '../../mint';
import { Theme, withTheme } from '../../theme';
import {
  Button,
  MintLogo,
  Person,
  SocialLoginButton,
  TextInput,
  Wrapper,
} from '../elements';
import { toImageSource } from '../utils';

const useStyles = ({
  theme: { colors, typography },
  viewWidth,
}: {
  theme: Theme;
  viewWidth: number;
}) => {
  return useMemo(
    () =>
      StyleSheet.create({
        empty: {
          flex: 1,
          backgroundColor: colors.backgroundLight,
        },
        headerContainer: {
          overflow: 'hidden',
          height: viewWidth * 0.95,
        },
        headerCover: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: viewWidth * 0.565,
        },
        headerPointIconContainer: {
          position: 'absolute',
          left: 0,
          right: 0,
          width: null,
          paddingBottom: 5,
          overflow: 'hidden',
          justifyContent: 'flex-end',
          alignItems: 'center',
          top: viewWidth * 0.34,
        },
        headerPointName: {
          ...typography.titleM,
          marginTop: 20,
          textAlign: 'center',
          color: colors.textBackgroundPrimary,
        },
        headerPointTextContainer: { marginTop: 10, alignItems: 'center' },
        headerPointText: {
          ...typography.paragraphM,
          color: colors.textBackgroundPrimary,
        },
        dividerContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: 21,
          marginTop: 24,
        },
        dividerLine: {
          height: 1,
          flexGrow: 1,
          backgroundColor: colors.border,
        },
        dividerText: {
          ...typography.textM,
          width: 80,
          textAlign: 'center',
          color: colors.textBackgroundHidden,
        },
        privacyContainer: {
          ...typography.paragraphS,
          marginTop: 25,
          color: colors.textBackgroundPrimary,
        },
        privacyLink: {
          ...typography.textS,
          color: colors.link,
        },
        passwordLoginHint: {
          ...typography.textS,
          color: colors.textBackgroundSecondary,
        },
        passwordLoginHintLink: {
          ...typography.textS,
          color: colors.link,
        },
        descriptionContainer: {
          justifyContent: 'center',
          borderRadius: 15,
          marginTop: 24,
          paddingVertical: 20,
          paddingHorizontal: 16,
          backgroundColor: colors.card,
        },
        descriptionTitle: {
          ...typography.titleM,
          color: colors.textCardPrimary,
        },
        descriptionText: {
          ...typography.paragraphM,
          marginTop: 16,
          color: colors.textCardPrimary,
        },
        mintText: {
          ...typography.textM,
          color: colors.textBackgroundSecondary,
        },
      }),
    [colors, typography, viewWidth],
  );
};

const Header: React.FC<{
  styles: ReturnType<typeof useStyles>;
  point: TokenResource;
  introStamp?: TokenStampResource;
  width: number;
}> = ({ styles, point, introStamp, width }) => (
  <View style={styles.headerContainer}>
    <Image
      style={styles.headerCover}
      resizeMode='cover'
      source={toImageSource(point.cover_image) ?? DefaultPointCoverImage}
    />
    <View style={styles.headerPointIconContainer}>
      <Person
        emptyImage='point'
        size={width * 0.3}
        source={toImageSource(point.profile_image)}
      />
      <Text style={styles.headerPointName}>{point.name}</Text>
      <View style={styles.headerPointTextContainer}>
        <Text style={styles.headerPointText}>
          登録・ログインをして{point.unit}を貯めよう
        </Text>
        {introStamp && (
          <Text style={styles.headerPointText}>
            初回登録で{introStamp.amount}
            {point.short_unit}もらえます
          </Text>
        )}
      </View>
    </View>
  </View>
);

const Divider: React.FC<{
  styles: ReturnType<typeof useStyles>;
}> = ({ styles }) => (
  <View style={styles.dividerContainer}>
    <View style={styles.dividerLine} />
    <Text style={styles.dividerText}>または</Text>
    <View style={styles.dividerLine} />
  </View>
);

const PrivacyCaption: React.FC<{
  styles: ReturnType<typeof useStyles>;
  usePasswordless: boolean;
  useLine: boolean;
  onPress: () => void;
}> = ({ styles, onPress, usePasswordless, useLine }) => (
  <Text style={styles.privacyContainer}>
    このポイントはmintを使って作成されています。
    {usePasswordless && '「次へ」'}
    {usePasswordless && useLine && 'または'}
    {useLine && '「LINEでログイン」'}
    ボタンを押すことにより、
    <TouchableOpacity onPress={onPress}>
      <Text style={styles.privacyLink}>プライバシーポリシー</Text>
    </TouchableOpacity>
    に同意したとみなされます。
  </Text>
);

const PasswordLoginLink: React.FC<{
  styles: ReturnType<typeof useStyles>;
  onPress: () => void;
}> = ({ styles, onPress }) => (
  <Text>
    <Text style={styles.passwordLoginHint}>パスワードでログインする場合は</Text>
    <TouchableOpacity onPress={onPress}>
      <Text style={styles.passwordLoginHintLink}>こちら</Text>
    </TouchableOpacity>
  </Text>
);

const Description: React.FC<{
  styles: ReturnType<typeof useStyles>;
  point: TokenResource;
}> = ({ styles, point }) => (
  <View style={styles.descriptionContainer}>
    <Text style={styles.descriptionTitle}>{point.name}とは</Text>
    <Text style={styles.descriptionText}>{point.description}</Text>
  </View>
);

const MintCaption: React.FC<{
  styles: ReturnType<typeof useStyles>;
  onPress: () => void;
}> = ({ styles, onPress }) => (
  <View
    style={{
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 24,
      marginBottom: 24,
    }}
  >
    <Text style={styles.mintText}>このポイントは</Text>
    <TouchableOpacity style={{ paddingBottom: 3 }} onPress={onPress}>
      <MintLogo width='40px' height='24px' />
    </TouchableOpacity>
    <Text style={styles.mintText}>で作成されています</Text>
  </View>
);

function useInputOptions(methods: LoginMethod[]): {
  label: string;
  placeholder: string;
  autocomplete?: 'email';
  usePassword: boolean;
} {
  return useMemo(
    () =>
      methods.filter((m) => m.type === 'email').length > 0
        ? methods.filter((m) => m.type === 'phoneNumber').length > 0
          ? {
              label: '携帯電話番号/メールアドレス',
              placeholder: '例）09012345678',
              autocomplete: 'email',
              usePassword: true,
            }
          : {
              label: 'メールアドレス',
              placeholder: '例）mail@example.com',
              autocomplete: 'email',
              usePassword: true,
            }
        : {
            label: '携帯電話番号',
            placeholder: '例）09012345678',
            usePassword: false,
          },
    [methods],
  );
}

type Props = {
  theme: Theme;
  introStamp?: TokenStampResource;
  siteui: SiteUISettings;
  isLoading: boolean;
  point?: TokenResource;
  onPressMintjp: () => void;
  onPressNext: (connection: 'sms' | 'email', loginHint: string) => void;
  onPressPasswordLogin: (loginHint: string) => void;
  onPressSocialLogin: (p: LoginProvider) => void;
  onPressPolicy: () => void;
};

const Component: React.FC<Props> = ({ theme, ...props }) => {
  const viewWidth = useViewWidth();
  const styles = useStyles({ theme, viewWidth });
  const {
    point,
    introStamp,
    siteui: {
      login: { methods, providers },
    },
  } = props;
  const { label, placeholder, autocomplete, usePassword } =
    useInputOptions(methods);
  const [loginHint, setLoginHint] = useState('');
  const [errorHint, setErrorHint] = useState<string | null>(null);
  const onChangeText = useCallback(
    (text) => {
      setErrorHint(null);
      setLoginHint(text);
    },
    [setErrorHint, setLoginHint],
  );
  const onPressNext = useCallback(() => {
    const useEmail = methods.filter((m) => m.type === 'email').length > 0;
    const usePhone = methods.filter((m) => m.type === 'phoneNumber').length > 0;
    if (useEmail && usePhone) {
      if (isEmail(loginHint)) {
        props.onPressNext('email', loginHint);
      } else if (isPhoneNumber(loginHint)) {
        props.onPressNext('sms', loginHint);
      } else {
        setErrorHint(
          '有効な電話番号（ハイフンなし）、またはメールアドレスを入力してください',
        );
      }
    } else if (useEmail) {
      if (isEmail(loginHint)) {
        props.onPressNext('email', loginHint);
      } else {
        setErrorHint('有効なメールアドレスを入力してください');
      }
    } else if (usePhone) {
      if (isPhoneNumber(loginHint)) {
        props.onPressNext('sms', loginHint);
      } else {
        setErrorHint('有効な電話番号（ハイフンなし）を入力してください');
      }
    }
  }, [methods, loginHint, props]);
  if (!point) {
    return <View style={styles.empty} />;
  }
  return (
    <Wrapper>
      <Header
        styles={styles}
        point={point}
        introStamp={introStamp}
        width={viewWidth}
      />
      <View
        style={{
          width: '100%',
          paddingLeft: 16,
          paddingRight: 16,
          paddingBottom: 40,
        }}
      >
        {methods.length > 0 && (
          <>
            <View style={{ width: '100%', marginTop: 4, paddingTop: 4 }}>
              <TextInput
                label={label}
                placeholder={placeholder}
                autoCompleteType={autocomplete}
                error={errorHint && true}
                errorMessage={errorHint}
                onChangeText={onChangeText}
              />
            </View>
            <View
              style={{ width: '100%', alignItems: 'center', marginTop: 24 }}
            >
              <Button
                title='次へ'
                style={{ width: 128 }}
                onPress={onPressNext}
              />
            </View>
            {usePassword && (
              <View
                style={{ width: '100%', alignItems: 'center', marginTop: 24 }}
              >
                <PasswordLoginLink
                  styles={styles}
                  onPress={() => props.onPressPasswordLogin(loginHint)}
                />
              </View>
            )}
            {providers.length === 0 && (
              <PrivacyCaption
                styles={styles}
                usePasswordless={methods.length > 0}
                useLine={providers.length > 0}
                onPress={props.onPressPolicy}
              />
            )}
          </>
        )}
        {methods.length > 0 && providers.length > 0 && (
          <Divider styles={styles} />
        )}
        {providers.length > 0 && (
          <>
            <View style={{ marginTop: 24, alignItems: 'center' }}>
              {providers.map((p) => (
                <SocialLoginButton
                  key={p.type}
                  width={223}
                  provider={p}
                  onPress={() => props.onPressSocialLogin(p)}
                />
              ))}
            </View>
            <PrivacyCaption
              styles={styles}
              usePasswordless={methods.length > 0}
              useLine={providers.length > 0}
              onPress={props.onPressPolicy}
            />
          </>
        )}
        <Description styles={styles} point={point} />
        <MintCaption styles={styles} onPress={props.onPressMintjp} />
      </View>
    </Wrapper>
  );
};

export type { Props as AuthStartProps };
export const AuthStart = withTheme(Component);

function isPhoneNumber(s: string): boolean {
  return s.match(/^0\d{9,10}$/) ? true : false;
}

function isEmail(s: string): boolean {
  return s.match(/^[\w\-._]+@[\w\-._]+\.[A-Za-z]+$/) ? true : false;
}
