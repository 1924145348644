const errors = {
  auth_require: { title: 'ログインしてください', message: null },
  auth_failure: { title: '認証に失敗しました', message: null },
  geolocation_permission_denied: {
    title: '位置情報の利用を許可してください',
    message:
      'チェックイン機能を使用するには、位置情報の許可が必要です。' +
      '端末の設定より、お使いのブラウザの位置情報を許可してください。',
  },
  geolocation_position_unavailable: {
    title: '位置情報の取得に失敗しました',
    message: null,
  },
  geolocation_timeout: {
    title: '位置情報の取得中にタイムアウトしました',
    message: null,
  },
  camera_roll_permission_denied: {
    title: '画像の利用が許可されていません',
    message: null,
  },
  camera_roll_error: {
    title: '画像選択中にエラーが発生しました',
    message: null,
  },
  image_not_found: {
    title: 'アップロード完了した画像がありません',
    message: null,
  },
  buildinfo_not_found: {
    title: 'ビルド情報が取得できません',
    message: null,
  },
  location_not_specified: {
    title: '位置情報の送信に失敗しました',
    message: null,
  },
  invalid_qr: {
    title: 'このQRコードは利用できません',
    message: null,
  },
  invalid_qr_format: {
    title: 'このQRコードの形式はサポートされていません',
    message: null,
  },
  camera_settings_not_set: {
    title:
      '撮影用のスタンプが設定されていません、ポイントオーナーに確認してください',
    message: null,
  },
  already_checked_in: {
    title: 'すでにチェックインしています',
    message:
      'すでにチェックインが完了しています。このスポットのチェックインは1日1回までとなっています。',
  },
  already_photo: {
    title: 'すでにポイント獲得済みです',
    message:
      'すでにポイント獲得しています。この撮影ポイントの獲得は1日1回までとなっています。',
  },
  unavailable_checkin_spot: {
    title: 'チェックインできません',
    message: null,
  },
  unavailable_photo_spot: {
    title: 'ポイント獲得できません',
    message: null,
  },
  checkin_stamp_not_found: {
    title: 'チェックイン先が見つかりません',
    message: 'チェックインスポットをご確認ください。',
  },
  out_of_checkin_location: {
    title: 'チェックインできません',
    message: '現在位置から離れているためチェックインできません',
  },
  out_of_photo_location: {
    title: '現在位置ではポイント獲得できません',
    message: null,
  },
};

type ErrorTitle = keyof typeof errors;

export class ApplicationError extends Error {
  title: ErrorTitle;
  userTitle: string;
  userMsg?: string;
  constructor(title: ErrorTitle, options?: { userMsg?: string }) {
    super(title);
    this.title = title;
    this.userTitle = errors[title].title;
    this.userMsg = options?.userMsg ?? errors[title].message;
  }
}
