import { CommonActions } from '@react-navigation/native';
import { SignInTask } from '../states/account';
import { Route } from './types';

export const SignInActions = {
  next: ({ nextRoutes }: SignInTask) => {
    const routes: Route[] = [{ name: 'Portal' }];
    if (nextRoutes.length > 0) {
      return CommonActions.reset({
        index: 1,
        routes: routes.concat(nextRoutes[0]),
      });
    }
    return CommonActions.reset({ index: 0, routes });
  },
};
