import { AuthTokenRequest } from '../mint';
import { storage } from './storage';

const key = 'authTokenRequest';

export const saveAuthTokenRequest = (req: AuthTokenRequest) => {
  storage.save({ key, data: req });
};

export const loadAuthTokenRequest = async () => {
  return await storage.load<AuthTokenRequest>({ key });
};

export const removeAuthTokenRequest = () =>
  storage.remove({ key }).catch(console.error);
