import { createURL } from 'expo-linking';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { Linking, Share } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItemProps, Portal, toMenuItemProps } from '../components/pages';
import {
  useAcquisitionPopUp,
  useAuth,
  useListVoucher,
  usePoint,
} from '../hooks';
import { useModal } from '../hooks/useModal';
import { TokenNoticeResource } from '../mint';
import { PortalNavigation, PortalRoute } from '../navigation';
import { selectors as accountSelectors } from '../states/account';
import {
  operations as pointOperations,
  selectors as pointSelectors,
} from '../states/point';

type Props = {
  navigation: PortalNavigation;
  route: PortalRoute;
};

const Container: React.FC<Props> = ({ navigation }) => {
  useAuth();
  const { getPortal, point } = usePoint();
  const dispatch = useDispatch();
  const modal = useModal();
  const siteui = useSelector(pointSelectors.siteUI);
  const holder = useSelector(pointSelectors.holder);
  const { items: vouchers } = useListVoucher({ type: 'available_voucher' });
  const { items: notices, unread: unreadNoticeCount } = useSelector(
    pointSelectors.listNotice,
  );
  const { id: userId } = useSelector(accountSelectors.getProfile);
  const { items: benefits } = useSelector(pointSelectors.listBenefit);
  const holderTitleQuest = useSelector(pointSelectors.holderTitleQuest);
  const pointAcquisition = useSelector(pointSelectors.pointAcquisition);
  const [checkingIn, setCheckingIn] = useState(false);
  useAcquisitionPopUp();
  useLayoutEffect(
    () => navigation.setOptions({ headerShown: false }),
    [navigation],
  );
  useEffect(() => {
    if (pointAcquisition.status === '') {
      setCheckingIn(false);
    }
  }, [pointAcquisition.status]);
  const srcMenu = useMemo<MenuItemProps[]>(() => {
    const {
      portal: {
        menu: { main, more },
      },
    } = siteui;
    return main
      .concat(more)
      .filter((e) => !!e)
      .map((item) => toMenuItemProps(item));
  }, [siteui]);
  const menu = useMemo<MenuItemProps[]>(() => {
    return srcMenu.map((item) => ({
      ...item,
      loading: item.type === 'checkin' ? checkingIn : null,
      showBadge:
        item.type === 'vouchers'
          ? vouchers.length > 0
          : item.type === 'notices'
          ? unreadNoticeCount > 0
          : false,
    }));
  }, [srcMenu, unreadNoticeCount, vouchers.length, checkingIn]);
  const onPressMembership = useCallback(
    () => navigation.push('Membership'),
    [navigation],
  );
  const onPressDrawerMenu = useCallback(() => modal.openDrawer(), [modal]);
  const onPressPortalMenu = useCallback(
    (item: MenuItemProps) => {
      switch (item.type) {
        case 'scan':
          navigation.push('Scanner');
          break;
        case 'share':
          Share.share({
            title: point.name,
            message: point.name,
            url: new URL(createURL('/')).origin,
          }).catch(console.error);
          break;
        case 'checkin':
          dispatch(pointOperations.checkin());
          setCheckingIn(true);
          break;
        case 'notices':
          navigation.push('NoticeList');
          break;
        case 'history':
          navigation.push('HistoryList');
          break;
        case 'benefits':
          navigation.push('BenefitList');
          break;
        case 'vouchers':
          navigation.push('VoucherList');
          break;
        case 'orders':
          navigation.push('OrderList');
          break;
        case 'link':
          Linking.openURL(item.href.replace(/{user_id}/, userId));
          break;
        case 'captureWithCamera':
          navigation.push('Camera');
          break;
      }
    },
    [navigation, dispatch, point, userId],
  );
  const onPressNoticeItem = useCallback(
    ({ id: noticeId }: TokenNoticeResource) => {
      navigation.push('NoticeDetail', { noticeId });
      dispatch(pointOperations.markAsReadNotice({ id: noticeId }));
    },
    [navigation, dispatch],
  );
  const onPressNoticeListMore = useCallback(
    () => navigation.push('NoticeList'),
    [navigation],
  );
  const onPressBenefitItem = useCallback(
    ({ id: benefitId }) => navigation.push('BenefitDetail', { benefitId }),
    [navigation],
  );
  const onPressBenefitListMore = useCallback(
    () => navigation.push('BenefitList'),
    [navigation],
  );
  return (
    <Portal
      loading={!getPortal.loaded}
      point={point}
      holder={holder}
      holderTitleQuest={holderTitleQuest}
      notices={notices}
      benefits={benefits}
      menu={menu}
      onPressMembership={holderTitleQuest && onPressMembership}
      onPressDrawerMenu={onPressDrawerMenu}
      onPressPortalMenu={onPressPortalMenu}
      onPressNoticeItem={onPressNoticeItem}
      onPressNoticeListMore={onPressNoticeListMore}
      onPressBenefitItem={onPressBenefitItem}
      onPressBenefitListMore={onPressBenefitListMore}
    />
  );
};

export { Container as PortalView };
export default Container;
