import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import regexp from '../../lib/regexp';
import { Button, TextInput, Wrapper } from '../elements';

const styles = StyleSheet.create({
  button: {
    marginTop: '25px',
  },
});

type FormState = {
  phoneNumber: string;
  error?: string;
};

type Props = {
  defaultPhoneNumberValue?: string;
  onPressChange: (form: FormState) => void;
};

const Component: React.FC<Props> = ({ ...props }) => {
  const initFormState = useMemo(
    (): FormState => ({
      phoneNumber: props.defaultPhoneNumberValue,
    }),
    [props],
  );
  const [form, setForm] = useState({ ...initFormState });
  const setPhoneNumber = useCallback(
    (value: string) =>
      setForm({ ...form, phoneNumber: value, error: validate(value) }),
    [form],
  );
  const onPressChange = useCallback(() => {
    props.onPressChange(form);
    setForm(initFormState);
  }, [form, initFormState, props]);
  return (
    <Wrapper style={{ paddingHorizontal: 16 }}>
      <TextInput
        label='電話番号'
        value={form.phoneNumber}
        containerStyle={{ marginTop: '25px' }}
        onChangeText={setPhoneNumber}
        errorMessage={form.error}
        keyboardType='number-pad'
        maxLength={11}
      />
      <Button
        disabled={form.phoneNumber === '' || (form.error ? true : false)}
        title='次へ'
        style={styles.button}
        onPress={onPressChange}
      />
    </Wrapper>
  );
};

Component.defaultProps = { defaultPhoneNumberValue: '' };

export type {
  Props as AccountPhoneEditProps,
  FormState as AccountPhoneEditState,
};
export { Component as AccountPhoneEdit };

const validate = (value: string): string | undefined => {
  if (value !== '' && !regexp.phoneNumberJp.test(value)) {
    return '電話番号が正しくありません';
  }
  return undefined;
};
