import { Ionicons } from '@expo/vector-icons';
import React from 'react';
import { StyleProp, Text, TextStyle, View } from 'react-native';
import { Theme, withTheme } from '../../theme';

type Props = {
  theme: Theme;
  amount: number;
  iconSize?: number;
  style?: StyleProp<TextStyle>;
};

const Component: React.FC<Props> = ({
  theme: { colors },
  amount,
  iconSize,
  style,
}) => (
  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
    <Ionicons
      color={amount > 0 ? colors.accent : colors.textCardSecondary}
      size={iconSize ?? 16}
      name={amount > 0 ? 'md-paper-plane' : 'earth'}
    />
    <Text style={[{ paddingLeft: 6, color: colors.textCardSecondary }, style]}>
      {amount > 0 ? `${amount}pt以上限定` : '全体公開'}
    </Text>
  </View>
);

export type { Props as NoticeAmountProps };
export const NoticeAmount = withTheme(Component);
