import { ActionType } from 'redux-actions-type';

export const ActionTypes = {
  SET_ERROR: '@@error/SET_ERROR' as const,
  CLEAR_ERROR: '@@error/CLEAR_ERROR' as const,
};

const actions = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setError: (error: any) => ({
    type: ActionTypes.SET_ERROR,
    error,
  }),
  clearError: () => ({
    type: ActionTypes.CLEAR_ERROR,
  }),
};

export type Actions = ActionType<typeof actions>;

export default actions;
