import React from 'react';
import { ScrollView, StyleProp, View, ViewStyle } from 'react-native';
import { MAX_VIEW_WIDTH } from '../../constants';
import { Theme, withTheme } from '../../theme';

type Props = {
  // ref?: MutableRefObject<WrapperRef>;
  theme: Theme;
  style?: StyleProp<ViewStyle>;
  children: React.ReactNode;
  scrollDisabled?: boolean;
  FooterComponent?: React.ReactNode;
};

const Component: React.FC<Props> = ({
  theme,
  style,
  children,
  scrollDisabled,
  FooterComponent,
}) => {
  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        backgroundColor: theme.colors.white,
        width: '100%',
        height: '100%',
      }}
    >
      <View
        style={{
          flex: 1,
          backgroundColor: theme.colors.backgroundLight,
          width: '100%',
          maxWidth: MAX_VIEW_WIDTH,
          minWidth: 320,
        }}
      >
        {scrollDisabled ? (
          children
        ) : (
          <ScrollView
            style={{ width: '100%' }}
            contentContainerStyle={[{ flexGrow: 1 }, style]}
          >
            {children}
          </ScrollView>
        )}
        {FooterComponent}
      </View>
    </View>
  );
};

export type { Props as WrapperProps };
export const Wrapper = withTheme(Component);
