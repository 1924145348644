import { Ionicons } from '@expo/vector-icons';
import React, { useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Ticket } from '../../states/point';
import { Theme, withTheme } from '../../theme';
import { ItemImage } from '../elements';
import { formatDate, toImageSource, toTicketInfo } from '../utils';
import { TicketInfo } from '../utils/types';

const useStyles = ({
  theme: { colors, typography },
  viewWidth,
  info,
}: {
  theme: Theme;
  viewWidth: number;
  info: TicketInfo;
}) => {
  return useMemo(() => {
    return StyleSheet.create({
      container: {
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 16,
        padding: 16,
        backgroundColor: colors.card,
      },
      image: { borderRadius: 12 },
      info: { width: viewWidth * 0.6, marginLeft: 20 },
      title: { fontSize: 15, fontWeight: 'bold' },
      pass: {
        ...typography.textS,
        color: colors.textCardSecondary,
        marginTop: 8,
      },
      dateContainer: {
        marginTop: 8,
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      date: {
        ...typography.textS,
        color: colors.textCardSecondary,
      },
      dateHint: {
        ...typography.textS,
        color: info.color,
      },
    });
  }, [colors, typography, viewWidth, info]);
};

type Props = {
  theme: Theme;
  viewWidth: number;
  ticket: Ticket;
  onPress: (event: { ticketId: string }) => void;
};

const Component = withTheme<Props>(({ theme, viewWidth, ticket, onPress }) => {
  const info = useMemo(() => toTicketInfo(theme, ticket), [theme, ticket]);
  const styles = useStyles({ theme, viewWidth, info: info });
  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => onPress({ ticketId: ticket.id })}
    >
      <ItemImage
        containerStyle={styles.image}
        emptyImage='benefit'
        size={64}
        rounded
        source={toImageSource(ticket.profileImage)}
        info={
          ticket.group === 'voucher' &&
          !info.available && {
            title: info.title,
            icon: info.icon,
          }
        }
      />
      <View style={styles.info}>
        <Text style={styles.title} numberOfLines={2} ellipsizeMode='tail'>
          {ticket.name}
        </Text>
        {ticket.feature === 'pass' && (
          <Text style={styles.pass}>何回でも使える</Text>
        )}
        <View style={styles.dateContainer}>
          <Text style={styles.date}>
            {ticket.group === 'voucher' &&
              '使用期限: ' +
                (ticket.expireTime
                  ? formatDate('Date', ticket.expireTime)
                  : 'なし')}
            {ticket.group === 'order' &&
              '申込日: ' + formatDate('Date', ticket.usedAt)}
          </Text>
          <Text style={styles.dateHint}>
            <Ionicons name={info.icon} size={14} color={info.color} />
            {' ' + info.shortTitle}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
});

export type { Props as TicketListItemProps };
export { Component as TicketListItem };
