import { createSelector } from 'reselect';
import { State as RootState } from '../store';

export const errorSelector = (state: RootState) => state.error;

export default {
  getError: createSelector([errorSelector], (error) => {
    return { title: error.title, message: error.message || '' };
  }),
};
