import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useViewWidth } from '../../hooks';
import { Ticket } from '../../states/point';
import { Theme, withTheme } from '../../theme';
import { Button, ItemImage, Wrapper } from '../elements';
import { getTicketReceptionTime, toImageSource, toTicketInfo } from '../utils';

const useFooterStyles = ({
  theme: { colors, typography },
}: {
  theme: Theme;
}) => {
  return useMemo(() => {
    return StyleSheet.create({
      container: {
        alignItems: 'center',
        paddingTop: 41,
        width: '100%',
        height: 155,
        backgroundColor: colors.card,
      },
      hint: {
        ...typography.textXL,
        fontWeight: 'bold',
        color: colors.textCardSecondary,
      },
      button: {
        marginTop: 24,
        width: 278,
        height: 40,
      },
    });
  }, [colors, typography]);
};

const Footer = withTheme<{
  theme: Theme;
  onPress: () => void;
}>(({ theme, onPress }) => {
  const styles = useFooterStyles({ theme });
  return (
    <View style={styles.container}>
      <Text style={styles.hint}>この画面を見せて特典を受け取ろう</Text>
      <Button style={styles.button} title='閉じる' onPress={onPress} />
    </View>
  );
});

const useStyles = ({
  theme: { colors, typography },
  viewWidth,
}: {
  theme: Theme;
  viewWidth: number;
}) => {
  return useMemo(() => {
    return StyleSheet.create({
      wrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.backgroundLight,
      },
      container: {
        alignItems: 'center',
        // marginTop: viewWidth * 0.44,
        paddingTop: 24,
        paddingHorizontal: 16,
        width: viewWidth * 0.8,
        borderRadius: 16,
        backgroundColor: colors.card,
      },
      image: {
        borderRadius: 16,
      },
      title: {
        ...typography.textXL,
        fontWeight: 'bold',
        marginTop: 16,
        marginBottom: 24,
        color: colors.textCardPrimary,
      },
      timerContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        height: 76,
        borderTopWidth: 1,
        width: '100%',
        borderTopColor: colors.border,
      },
      timer: {
        ...typography.timer,
        color: colors.textCardSecondary,
      },
    });
  }, [colors, typography, viewWidth]);
};

type ModalState = 'loading' | 'confirm';

type ComponentRef = {
  setModalState: (s: ModalState) => void;
  hideModal: () => void;
};

type Props = {
  theme: Theme;
  ticket: Ticket;
  onPressClose: () => void;
};

const Component: React.FC<Props> = ({ theme, ticket, onPressClose }) => {
  const viewWidth = useViewWidth();
  const info = useMemo(() => toTicketInfo(theme, ticket), [theme, ticket]);
  const styles = useStyles({ theme, viewWidth });
  const [timer, setTimer] = useState<NodeJS.Timeout | null>();
  const [receptionTime, setReceptionTime] = useState(info.receptionTime);
  useEffect(() => {
    const update = () =>
      setReceptionTime(getTicketReceptionTime(ticket.usedAt));
    update();
    const id = setInterval(update, 250);
    setTimer(id);
    return () => {
      clearInterval(id);
      setTimer(null);
    };
  }, [ticket.usedAt]);
  useEffect(() => {
    if (timer && receptionTime === 0) {
      clearInterval(timer);
      setTimer(null);
    }
  }, [timer, receptionTime]);
  return (
    <Wrapper
      style={styles.wrapper}
      FooterComponent={<Footer onPress={onPressClose} />}
    >
      <View style={[styles.container, { transform: [{ rotate: '180deg' }] }]}>
        <ItemImage
          containerStyle={styles.image}
          emptyImage='benefit'
          size={viewWidth * 0.53}
          rounded
          source={toImageSource(ticket.profileImage)}
        />
        <Text style={styles.title} numberOfLines={2}>
          {ticket.name}
        </Text>
        {ticket.feature === 'in_place' && (
          <View style={styles.timerContainer}>
            <Text style={styles.timer}>
              {('00' + Math.floor(receptionTime / 60000)).slice(-2)}
              {':'}
              {('00' + Math.floor((receptionTime / 1000) % 60)).slice(-2)}
            </Text>
          </View>
        )}
      </View>
    </Wrapper>
  );
};

export type { ComponentRef as VoucherPresentRef, Props as VoucherPresentProps };
export const VoucherPresent = withTheme(Component);
