import { Reducer } from 'redux';
import {
  CaptureLotteryTask,
  GetLotteryTask,
  GetPortalTask,
  GetWalletTask,
  IssueTicketTask,
  ListBenefitTask,
  ListNoticeTask,
  ListTask,
  ListTicketTask,
  ListTransactionTask,
  PointAcquisitionTask,
  UpdateHolderNotificationTask,
  UseTicketTask,
  WithID,
} from '.';
import {
  QuestResource,
  SiteUISettings,
  TokenHolderResource,
  TokenNoticeResource,
  TokenResource,
  TokenStampResource,
  TokenTransactionResource,
} from '../../mint';
import { Actions, ActionTypes } from './actions';
import {
  emptyCaptureLottery,
  emptyHolder,
  emptyIssueTicket,
  emptyListAllVoucher,
  emptyListAvailableVoucher,
  emptyListBenefit,
  emptyListNotice,
  emptyListOrder,
  emptyListTransaction,
  emptyLottery,
  emptyOrderTicket,
  emptyPointAcquisition,
  emptyPortal,
  emptySelectedNotice,
  emptySelectedTransaction,
  emptyTicketDefaultValues,
  emptyToken,
  emptyUpdateHolderEmailNotifications,
  emptyUseTicket,
  emptyWallet,
} from './empties';
import { OrderTicketTask, TicketDefaultValuesTask } from './types';

type HolderState = {
  holder: TokenHolderResource;
  selectedNotice: TokenNoticeResource;
  selectedTransaction: TokenTransactionResource;
  checkInStampIDs: string[];
  introStampIDs: string[];
};

type State = {
  clientId: string;
  token: TokenResource;
  introStamp?: TokenStampResource;
  siteui: SiteUISettings;
  getPortal: GetPortalTask;
  getWallet: GetWalletTask;
  updateHolderNotification: UpdateHolderNotificationTask;
  listNotice: ListNoticeTask;
  listTransaction: ListTransactionTask;
  listBenefit: ListBenefitTask;
  listAvailableVoucher: ListTicketTask;
  listAllVoucher: ListTicketTask;
  listOrder: ListTicketTask;
  ticketDefaultValues: TicketDefaultValuesTask;
  pointAcquisition: PointAcquisitionTask;
  issueTicket: IssueTicketTask;
  useTicket: UseTicketTask;
  orderTicket: OrderTicketTask;
  getLottery: GetLotteryTask;
  captureLottery: CaptureLotteryTask;
  holderTitleQuest?: QuestResource;
} & HolderState;

const initialHolderState: HolderState = {
  holder: emptyHolder,
  selectedNotice: emptySelectedNotice,
  selectedTransaction: emptySelectedTransaction,
  checkInStampIDs: [],
  introStampIDs: [],
};

const initialState: State = {
  clientId: '',
  token: emptyToken,
  siteui: {
    version: '1',
    login: {
      methods: [{ type: 'email', is_default: true }],
      providers: [],
    },
    portal: {
      menu: { main: [], more: [] },
      tabs: ['home'],
      home: { contents: [] },
    },
    footer: [],
  },
  ...initialHolderState,
  getPortal: emptyPortal,
  getWallet: emptyWallet,
  updateHolderNotification: emptyUpdateHolderEmailNotifications,
  listNotice: emptyListNotice,
  listTransaction: emptyListTransaction,
  listBenefit: emptyListBenefit,
  listAvailableVoucher: emptyListAvailableVoucher,
  listAllVoucher: emptyListAllVoucher,
  listOrder: emptyListOrder,
  ticketDefaultValues: emptyTicketDefaultValues,
  pointAcquisition: emptyPointAcquisition,
  issueTicket: emptyIssueTicket,
  orderTicket: emptyOrderTicket,
  useTicket: emptyUseTicket,
  getLottery: emptyLottery,
  captureLottery: emptyCaptureLottery,
};

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_POINT:
      return {
        ...state,
        getPortal: {
          loaded: action.task.status === 'success',
          status: action.task.status,
        },
        ...(action.task.status === 'in_progress' && initialHolderState),
        ...(action.task.status === 'success' && {
          clientId: action.task.clientId,
          token: action.task.token,
          introStamp: action.task.introStamp,
          siteui: action.task.siteui,
          listBenefit: {
            status: '',
            items: action.task.benefits,
          },
        }),
      };
    case ActionTypes.GET_WALLET:
      return {
        ...state,
        getWallet: { status: action.task.status },
        ...(action.task.status === 'success' && {
          pointAcquisition: {
            status: action.task.acquisitionInfo ? 'success' : '',
            transaction: action.task.acquisitionInfo,
          },
          holder: action.task.holder,
          listNotice: {
            status: '',
            items: action.task.notices,
            unread: action.task.unreadNoticeCount,
          },
          listTransaction: {
            status: '',
            items: action.task.transactions,
          },
          listAvailableVoucher: {
            type: 'available_voucher',
            status: '',
            items: action.task.availableVouchers,
          },
          listAllVoucher: {
            type: 'all_voucher',
            status: '',
            items: action.task.allVouchers,
          },
          listOrder: {
            type: 'order',
            status: '',
            items: action.task.orders,
          },
          checkInStampIDs: action.task.checkinStampIDs,
          introStampIDs: action.task.introStampIDs,
          holderTitleQuest: action.task.quests.find(
            (q) => q.type === 'token_holder_title',
          ),
        }),
      };
    case ActionTypes.INIT_WALLET:
      return { ...state, ...initialHolderState };
    case ActionTypes.UPDATE_HOLDER_NOTIF:
      return {
        ...state,
        holder: {
          ...state.holder,
          email_notifications_enabled:
            action.task.status === 'success'
              ? action.task.enabled
              : state.holder.email_notifications_enabled,
        },
        updateHolderNotification: action.task,
      };

    case ActionTypes.POINT_ACQUISITION:
      return {
        ...state,
        pointAcquisition: action.task,
        holder: {
          ...state.holder,
          balances:
            action.task.status === 'success'
              ? state.holder.balances + action.task.transaction.amount
              : state.holder.balances,
        },
        listTransaction: {
          ...state.listTransaction,
          ...(action.task.status === 'success' && {
            items: [...state.listTransaction.items, action.task.transaction],
          }),
        },
      };
    case ActionTypes.LIST_TRANSACTION:
      return {
        ...state,
        listTransaction: {
          ...state.listTransaction,
          status: action.task.status,
          ...(action.task.status === 'success' && {
            items: action.task.transactions,
          }),
        },
      };
    case ActionTypes.LIST_NOTICE:
      return {
        ...state,
        listNotice: {
          ...state.listNotice,
          status: action.task.status,
          ...(action.task.status === 'success' && {
            items: action.task.notices,
            unread: action.task.unread,
          }),
        },
      };
    case ActionTypes.MARK_AS_READ_NOTICE:
      return {
        ...state,
        listNotice: {
          status: '',
          items: state.listNotice.items.map((e) =>
            e.id === action.id ? { ...e, read_at: new Date().toString() } : e,
          ),
          unread: state.listNotice.unread - 1,
        },
      };
    case ActionTypes.MARK_AS_UNREAD_NOTICE:
      return {
        ...state,
        listNotice: {
          status: '',
          items: state.listNotice.items.map((e) =>
            e.id === action.id ? { ...e, read_at: null } : e,
          ),
          unread: state.listNotice.unread + 1,
        },
      };
    case ActionTypes.LIST_BENEFIT:
      return {
        ...state,
        listBenefit: {
          ...state.listBenefit,
          status: action.task.status,
          ...(action.task.status === 'success' && {
            items: action.task.benefits,
          }),
        },
      };
    case ActionTypes.LIST_TICKET:
      return {
        ...state,
        ...(action.task.type === 'available_voucher' && {
          listAvailableVoucher: {
            ...action.task,
            items: action.task.tickets ?? state.listAvailableVoucher.items,
          },
        }),
        ...(action.task.type === 'all_voucher' && {
          listAllVoucher: {
            ...action.task,
            items: action.task.tickets ?? state.listAllVoucher.items,
          },
        }),
        ...(action.task.type === 'order' && {
          listOrder: {
            ...action.task,
            items: action.task.tickets ?? state.listOrder.items,
          },
        }),
      };
    case ActionTypes.ISSUE_TICKET:
      return {
        ...state,
        issueTicket: action.task,
        ...(action.task.ticket
          ? {
              holder: {
                ...state.holder,
                balances: state.holder.balances - action.task.ticket.amount,
              },
              listAvailableVoucher: addListTaskItem(
                state.listAvailableVoucher,
                action.task.ticket,
              ) as ListTicketTask,
            }
          : {}),
      };
    case ActionTypes.USE_TICKET:
      return {
        ...state,
        useTicket: action.task,
        ...(action.task.status === 'success' && {
          listAvailableVoucher: removeListTaskItem(
            state.listAvailableVoucher,
            action.task.ticket,
          ) as ListTicketTask,
          listAllVoucher: addListTaskItem(
            state.listAllVoucher,
            action.task.ticket,
          ) as ListTicketTask,
        }),
      };
    case ActionTypes.ORDER_TICKET:
      return {
        ...state,
        orderTicket: action.task,
        ...(action.task.status === 'success' && {
          listOrder: addListTaskItem(
            state.listOrder,
            action.task.ticket,
          ) as ListTicketTask,
        }),
      };
    case ActionTypes.GET_TICKET_DEFAULT_VALUES:
      return {
        ...state,
        ticketDefaultValues: action.task,
      };
    case ActionTypes.CAPTURE_LOTTERY:
      return {
        ...state,
        captureLottery: action.task,
        listAvailableVoucher: addListTaskItem(
          state.listAvailableVoucher,
          action.task.ticket,
        ) as ListTicketTask,
        listTransaction: addListTaskItem(
          state.listTransaction,
          action.task.transaction,
        ) as ListTransactionTask,
        holder: {
          ...state.holder,
          balances:
            state.holder.balances +
            (action.task.transaction ? action.task.transaction.amount : 0),
        },
      };
    case ActionTypes.GET_LOTTERY:
      return {
        ...state,
        getLottery: action.task,
      };
    default:
      return state;
  }
};

export default reducer;

function addListTaskItem<T extends WithID>(
  task: ListTask<T>,
  item?: T,
): ListTask<T> {
  const { items: prev } = task;
  const next =
    !item || prev.some(({ id }) => id === item.id) ? prev : [item].concat(prev);
  return { ...task, items: next };
}

function removeListTaskItem<T extends WithID>(
  task: ListTask<T>,
  item?: T,
): ListTask<T> {
  const { items: prev } = task;
  const next = prev.filter(({ id }) => id !== item.id);
  return { ...task, items: next };
}
