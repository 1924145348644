import React, { useCallback, useState } from 'react';
import {
  ImageURISource,
  StyleSheet,
  Text,
  TouchableOpacity,
} from 'react-native';
import { launchImageLibrary } from '../../lib/imagepicker';
import regexp from '../../lib/regexp';
import { UserProfile } from '../../mint';
import { Theme, withTheme } from '../../theme';
import { Button, Person, TextInput, Wrapper } from '../elements';
import { toImageSource } from '../utils';

const styles = StyleSheet.create({
  button: {
    marginTop: '25px',
  },
});

type FormState = {
  name: string;
  screenName: string;
  image?: ImageURISource;
  nameError?: string;
  screenNameError?: string;
};

type Props = {
  theme: Theme;
  loading: boolean;
  updated: boolean;
  profile: UserProfile;
  onPressChange: (form: FormState) => void;
};

const Component: React.FC<Props> = ({
  theme: { colors },
  profile,
  ...props
}) => {
  const initFormState: FormState = {
    name: profile.name,
    screenName: profile.screenName,
    image: toImageSource(profile.portraitImage),
  };
  const [form, setForm] = useState(initFormState);
  const setName = useCallback(
    (value: string) =>
      setForm({ ...form, name: value, nameError: validateName(value) }),
    [form],
  );
  const setScreenName = useCallback(
    (value: string) =>
      setForm({
        ...form,
        screenName: value,
        screenNameError: validateScreenName(value),
      }),
    [form],
  );
  const setImageUri = useCallback(
    (value: string) => setForm({ ...form, image: { uri: value } }),
    [form],
  );
  const onPressEditImage = useCallback(async () => {
    const result = await launchImageLibrary();
    switch (result.cancelled) {
      case false:
        setImageUri(result.uri);
        break;
    }
  }, [setImageUri]);
  const onPressChange = useCallback(
    () => props.onPressChange(form),
    [form, props],
  );
  return (
    <Wrapper style={{ paddingHorizontal: 16 }}>
      <TouchableOpacity
        disabled={props.loading || props.updated}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          height: '83px',
          paddingLeft: 8,
        }}
        onPress={onPressEditImage}
      >
        <Person
          size={50}
          emptyImage='person'
          emptyStyle={{ backgroundColor: colors.textBackgroundHidden }}
          source={form.image}
        />
        <Text style={{ marginLeft: 16 }}>プロフィール画像を設定する</Text>
      </TouchableOpacity>
      <TextInput
        editable={!props.loading && !props.updated}
        label='お名前'
        value={form.name}
        containerStyle={{ marginTop: '10px' }}
        onChangeText={setName}
        errorMessage={form.nameError}
      />
      <TextInput
        editable={!props.loading && !props.updated}
        label='ID'
        value={form.screenName}
        containerStyle={{ marginTop: '25px' }}
        onChangeText={setScreenName}
        errorMessage={form.screenNameError}
      />
      <Button
        loading={props.loading}
        disabled={
          props.updated ||
          form.name === '' ||
          (form.nameError ? true : false) ||
          form.screenName === '' ||
          (form.screenNameError ? true : false)
        }
        title='変更'
        style={styles.button}
        onPress={onPressChange}
      />
    </Wrapper>
  );
};

export type {
  Props as AccountProfileEditProps,
  FormState as AccountProfileEditForm,
};
export const AccountProfileEdit = withTheme(Component);

const validateName = (value: string): string | undefined => {
  if (value !== '' && !regexp.userName.test(value)) {
    return 'ユーザー名に使用できない文字が含まれています';
  }
  if (value.length > 128) {
    return 'ユーザー名は128文字以内で入力してください';
  }
  return undefined;
};

const validateScreenName = (value: string): string | undefined => {
  if (value !== '' && !regexp.screenName.test(value)) {
    return 'ユーザーIDに使用できない文字が含まれています';
  }
  if (value.length > 32) {
    return 'ユーザーIDは32文字以内で入力してください';
  }
  return undefined;
};
