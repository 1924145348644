import { applyMiddleware, combineReducers, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import AccountReducer from './account';
import AppInfoReudcer from './appinfo';
import ErrorReducer from './error';
import PointReducer from './point';

export interface State {
  error: ReturnType<typeof ErrorReducer>;
  account: ReturnType<typeof AccountReducer>;
  point: ReturnType<typeof PointReducer>;
  appinfo: ReturnType<typeof AppInfoReudcer>;
}

const rootReducer = combineReducers<State>({
  error: ErrorReducer,
  account: AccountReducer,
  point: PointReducer,
  appinfo: AppInfoReudcer,
});

const store = createStore(
  rootReducer,
  applyMiddleware(
    thunk,
    ...(__DEV__ ? [createLogger({ diff: true, collapsed: true })] : []),
  ),
);

export default store;
