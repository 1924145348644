import { registerRootComponent } from 'expo';
import Constants from 'expo-constants';
import { activateKeepAwake } from 'expo-keep-awake';
import 'expo/build/Expo.fx';
// import 'react-native-gesture-handler';
import env from './env';
// import Geolocation from "@react-native-community/geolocation";
// Geolocation.setRNConfiguration({
//   skipPermissionRequests: true,
//   authorizationLevel: "whenInUse", // 'always' | 'whenInUse' | 'auto';
// });
import App from './navigation';
import * as sw from './sw';

if (__DEV__) {
  activateKeepAwake();
  console.info('mintServer: ', env.server);
}

globalThis._mint = env;

if (Constants.platform.web) {
  const style = document.createElement('style');
  style.type = 'text/css';
  style.innerText = ':focus { outline-width: 0 }';
  document.getElementsByTagName('head')[0].appendChild(style);
}

registerRootComponent(App);
sw.register();
