import { Entypo, MaterialIcons } from '@expo/vector-icons';
import React, {
  ComponentProps,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ActivityIndicator,
  Animated,
  ColorValue,
  Easing,
  FlatList,
  ImageSourcePropType,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useViewWidth } from '../../hooks';
import {
  BenefitFeature,
  BenefitResource,
  QuestResource,
  SiteMenuItem,
  TokenHolderResource,
  TokenNoticeResource,
  TokenOrganizerResource,
  TokenResource,
} from '../../mint';
import { Theme, withTheme } from '../../theme';
import { ItemImage, MembershipCard, Person, Wrapper } from '../elements';
import { BenefitIcon } from '../elements/BenefitIcon';
import { toImageSource } from '../utils';

type MaterialIconsGlyphs = ComponentProps<typeof MaterialIcons>['name'];
type EntypoGlyphs = ComponentProps<typeof Entypo>['name'];
type Glyphs = MaterialIconsGlyphs | EntypoGlyphs;

const defaultMenuProps = new Map<string, { icon: string; title: string }>([
  ['history', { icon: 'history', title: 'ポイント内訳' }],
  ['notices', { icon: 'notifications-none', title: 'おしらせ' }],
  ['tickets', { icon: 'card-giftcard', title: '特典をつかう' }],
  ['benefits', { icon: 'Entypo/shop', title: '特典ショップ' }],
  ['vouchers', { icon: 'phone-iphone', title: 'チケットをつかう' }],
  ['orders', { icon: 'inbox', title: '配送メール受け取り' }],
  ['scan', { icon: 'filter-center-focus', title: 'スキャン' }],
  ['checkin', { icon: 'location-on', title: 'チェックイン' }],
  ['share', { icon: 'share', title: 'シェア' }],
  ['link', { icon: 'link', title: 'リンク' }],
  ['captureWithCamera', { icon: 'camera-alt', title: '撮影' }],
]);

const useMenuStyles = ({ theme: { colors, typography } }: { theme: Theme }) => {
  return useMemo(() => {
    return StyleSheet.create({
      container: {
        marginTop: 16,
        paddingHorizontal: 16,
        width: '100%',
      },
      section: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        width: '100%',
      },
      buttonContainer: {
        overflow: 'hidden',
        alignItems: 'center',
        paddingTop: 16,
        borderRadius: 16,
        height: 98,
      },
      buttonLabel: {
        ...typography.textS,
        marginTop: 8,
        fontWeight: 'bold',
        color: colors.textCardPrimary,
      },
      badge: {
        position: 'absolute',
        overflow: 'hidden',
        transform: [{ translateX: 25 }],
        width: 8,
        height: 8,
        borderRadius: 4,
        backgroundColor: colors.accent,
      },
      moreContainer: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        height: 42,
      },
      more: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-end',
        width: 160,
        height: 30,
      },
      moreLabel: {
        ...typography.textS,
        marginBottom: 3,
        fontWeight: 'bold',
        color: colors.link,
      },
    });
  }, [colors, typography]);
};

export type MenuItemProps = {
  type: SiteMenuItem['type'];
  font: string;
  icon: Glyphs;
  title: string;
  href?: string;
  color: ColorValue;
  showBadge?: boolean;
  loading?: boolean;
  backgroundColor?: ColorValue;
};

export function toMenuItemProps(e: SiteMenuItem): MenuItemProps {
  const { icon: defaultIcon, title: defaultTitle } = defaultMenuProps.has(
    e.type,
  )
    ? defaultMenuProps.get(e.type)
    : { icon: null, title: null };
  const [x, y] = (e.icon ?? defaultIcon ?? 'priority-high').split('/');
  return {
    type: e.type,
    icon: (y ? y : x) as Glyphs,
    font: y ? x : 'MaterialIcons',
    title: e.title ?? defaultTitle ?? '...',
    color: e.color,
    href: e.type === 'link' ? e.href : undefined,
    backgroundColor:
      e.background_color !== 'transparent' ? e.background_color : null,
  };
}

const MenuItem = withTheme<
  {
    theme: Theme;
    firstLine?: boolean;
    width: number;
    onPress: (item: MenuItemProps) => void;
  } & MenuItemProps
>(({ theme, firstLine, width, onPress, ...item }) => {
  const styles = useMenuStyles({ theme });
  return (
    <TouchableOpacity
      style={[
        styles.buttonContainer,
        { backgroundColor: item.backgroundColor ?? theme.colors.card, width },
        !firstLine && { marginTop: 16 },
      ]}
      onPress={() => onPress(item)}
    >
      <>
        {item.showBadge && <View style={styles.badge} />}
        {item.loading && (
          <ActivityIndicator size={40} color={theme.colors.primary} />
        )}
        {!item.loading && item.font === 'MaterialIcons' && (
          <MaterialIcons
            name={item.icon as MaterialIconsGlyphs}
            size={40}
            color={item.color ?? theme.colors.textCardPrimary}
          />
        )}
        {!item.loading && item.font === 'Entypo' && (
          <Entypo
            name={item.icon as EntypoGlyphs}
            size={40}
            color={item.color ?? theme.colors.textCardPrimary}
          />
        )}
        <Text style={styles.buttonLabel}>{item.title}</Text>
      </>
    </TouchableOpacity>
  );
});

const MenuItemPadding: React.FC<{
  columnInfo: {
    length: number;
    width: number;
  };
  itemLength: number;
}> = ({ columnInfo, itemLength }) => {
  const count = columnInfo.length - (itemLength % columnInfo.length);
  return (
    count !== columnInfo.length && (
      <View style={{ width: columnInfo.width * count + (count - 1) * 14 }} />
    )
  );
};

const Menu = withTheme<{
  theme: Theme;
  loading: boolean;
  viewWidth: number;
  items: MenuItemProps[];
  onPress: (item: MenuItemProps) => void;
}>(({ theme, viewWidth, onPress, ...props }) => {
  const styles = useMenuStyles({ theme });
  const { columnInfo, firstItems, moreItems } = useMemo(() => {
    const items = props.items.filter((e) => !!e);
    // 1行の列数
    const columnLength = viewWidth < 450 ? 3 : 4;
    // 列の幅=(viewWidth-(padding16*2 + padding14*columns)) / columns
    const columnWidth =
      (viewWidth - (32 + 14 * (columnLength - 1))) / columnLength;
    // 最初の2行に表示するアイテム数
    const countFirst = columnLength * 2;
    return {
      columnInfo: {
        length: columnLength,
        width: columnWidth,
      },
      // 最初の2行
      firstItems:
        items.length > countFirst ? items.slice(0, countFirst) : items,
      // 3行目以降
      moreItems: items.length > countFirst ? items.slice(countFirst) : [],
    };
  }, [viewWidth, props.items]);
  const [openMore, setOpenMore] = useState(false);
  const [moreHeight, setMoreHeight] = useState(0);
  const anime = useRef(new Animated.Value(0));
  const toggleMore = useCallback(() => {
    Animated.timing(anime.current, {
      useNativeDriver: false,
      duration: 300,
      toValue: openMore ? 0 : 1,
      easing: Easing.bezier(0.4, 0.0, 0.2, 1),
    }).start(() => setOpenMore(!openMore));
  }, [openMore]);
  return (
    <View style={styles.container}>
      <View style={styles.section}>
        {firstItems.map((item, i) => (
          <MenuItem
            key={`first-menu-${i}`}
            firstLine={i < columnInfo.length}
            width={columnInfo.width}
            onPress={onPress}
            {...item}
          />
        ))}
        <MenuItemPadding
          columnInfo={columnInfo}
          itemLength={firstItems.length}
        />
      </View>
      {moreItems.length > 0 && (
        <>
          <Animated.View
            style={{
              overflow: 'hidden',
              height: anime.current.interpolate({
                inputRange: [0, 1],
                outputRange: [0, moreHeight],
              }),
            }}
          >
            <View
              style={[styles.section, { position: 'absolute' }]}
              onLayout={({
                nativeEvent: {
                  layout: { height },
                },
              }) => setMoreHeight(height)}
            >
              {moreItems.map((item, i) => (
                <MenuItem
                  key={`second-menu-${i}`}
                  width={columnInfo.width}
                  onPress={onPress}
                  {...item}
                />
              ))}
              <MenuItemPadding
                columnInfo={columnInfo}
                itemLength={moreItems.length}
              />
            </View>
          </Animated.View>
          <View style={styles.moreContainer}>
            <TouchableOpacity style={styles.more} onPress={toggleMore}>
              <Text style={styles.moreLabel}>すべて見る</Text>
              <Animated.View
                style={{
                  transform: [
                    {
                      rotateZ: anime.current.interpolate({
                        inputRange: [0, 1],
                        outputRange: ['0rad', `${Math.PI}rad`],
                      }),
                    },
                  ],
                }}
              >
                <MaterialIcons
                  name='keyboard-arrow-down'
                  size={22}
                  color={theme.colors.link}
                />
              </Animated.View>
            </TouchableOpacity>
          </View>
        </>
      )}
    </View>
  );
});

const useSectionStyles = ({
  theme: { colors, typography },
}: {
  theme: Theme;
}) => {
  return useMemo(() => {
    return StyleSheet.create({
      container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 40,
        paddingHorizontal: 16,
        width: '100%',
        height: 59,
        paddingLeft: 16,
      },
      title: {
        ...typography.textXL,
        fontWeight: 'bold',
        color: colors.textBackgroundPrimary,
      },
      moreContainer: {
        height: 18,
        flexDirection: 'row',
        alignItems: 'center',
      },
      more: {
        ...typography.textS,
        fontWeight: 'bold',
        color: colors.link,
      },
    });
  }, [colors, typography]);
};

const Section = withTheme<{
  theme: Theme;
  title: string;
  children: React.ReactNode;
  onPressMore?: () => void;
}>(({ theme, title, children, onPressMore }) => {
  const styles = useSectionStyles({ theme });
  return (
    <>
      <View style={styles.container}>
        <Text style={styles.title}>{title}</Text>
        {onPressMore && (
          <TouchableOpacity style={styles.moreContainer} onPress={onPressMore}>
            <Text style={styles.more}>すべて見る</Text>
            <MaterialIcons
              name='chevron-right'
              size={22}
              color={theme.colors.link}
            />
          </TouchableOpacity>
        )}
      </View>
      {children}
    </>
  );
});

const Description = withTheme<{
  theme: Theme;
  children: string;
}>(({ theme: { colors, typography }, children }) => {
  return (
    <View style={{ paddingHorizontal: 16 }}>
      <View
        style={{
          width: '100%',
          padding: 16,
          borderRadius: 16,
          backgroundColor: colors.card,
        }}
      >
        <Text
          style={{ ...typography.paragraphM, color: colors.textCardPrimary }}
        >
          {children}
        </Text>
      </View>
    </View>
  );
});

const Owner = withTheme<{
  theme: Theme;
  owner: TokenOrganizerResource;
}>(({ theme: { colors, typography }, owner }) => {
  return (
    <View style={{ paddingHorizontal: 16 }}>
      <View
        style={{
          width: '100%',
          padding: 16,
          borderRadius: 16,
          backgroundColor: colors.card,
        }}
      >
        <View style={{ alignItems: 'center' }}>
          <Person
            emptyImage='person'
            size={80}
            source={toImageSource(owner.profile_image)}
          />
          <Text
            style={{
              ...typography.textS,
              marginTop: 8,
              color: colors.textCardPrimary,
            }}
          >
            {owner.name}
          </Text>
        </View>
        <Text
          style={{
            ...typography.paragraphM,
            marginTop: 16,
            color: colors.textCardPrimary,
          }}
        >
          {owner.description}
        </Text>
      </View>
    </View>
  );
});

const useListItemStyles = ({
  theme: { colors, typography },
}: {
  theme: Theme;
}) => {
  return useMemo(() => {
    return StyleSheet.create({
      container: {
        overflow: 'hidden',
        borderRadius: 16,
        marginRight: 12,
        width: 160,
        backgroundColor: colors.card,
      },
      image: {
        backgroundColor: colors.backgroundDark,
      },
      textContainer: {
        paddingTop: 14,
        paddingHorizontal: 12,
        height: 76,
      },
      text: {
        ...typography.paragraphM,
        color: colors.textCardPrimary,
      },
      amountContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 12,
        height: 44,
      },
      amount: {
        ...typography.textM,
        marginLeft: 8,
        fontWeight: 'bold',
        color: colors.accent,
      },
      moreContainer: {
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 16,
        width: 144,
        backgroundColor: colors.primary,
      },
      more: {
        ...typography.textL,
        marginBottom: 30,
        fontWeight: 'bold',
        color: colors.card,
      },
    });
  }, [colors, typography]);
};

const ListItem = withTheme<{
  theme: Theme;
  image?: ImageSourcePropType;
  text: string;
  unread?: boolean;
  amount?: {
    value: number;
    unit: string;
  };
  benefitFeature?: BenefitFeature;
  onPress: () => void;
}>(({ theme, image, text, unread, amount, benefitFeature, onPress }) => {
  const styles = useListItemStyles({ theme });
  const isBenefit = amount && benefitFeature ? true : false;
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <ItemImage
        style={styles.image}
        size={160}
        source={image}
        emptyImage={amount ? 'benefit' : 'notice'}
      />
      <View style={[styles.textContainer, { height: amount ? 61 : 76 }]}>
        <Text
          style={[
            styles.text,
            { fontWeight: unread || isBenefit ? 'bold' : 'normal' },
          ]}
          numberOfLines={2}
          ellipsizeMode='tail'
        >
          {text}
        </Text>
      </View>
      {isBenefit && (
        <View style={styles.amountContainer}>
          <BenefitIcon feature={benefitFeature} size={24} />
          <Text style={styles.amount}>
            {amount.value}
            {amount.unit}
          </Text>
        </View>
      )}
    </TouchableOpacity>
  );
});

const ListMore = withTheme<{
  theme: Theme;
  onPress: () => void;
}>(({ theme, onPress }) => {
  const styles = useListItemStyles({ theme });
  return (
    <TouchableOpacity style={styles.moreContainer} onPress={onPress}>
      <Text style={styles.more}>もっと見る</Text>
      <MaterialIcons name='arrow-forward' size={30} color={theme.colors.card} />
    </TouchableOpacity>
  );
});

const OpenDrawerButton = withTheme<{
  theme: Theme;
  onPress: () => void;
}>(({ theme: { colors }, onPress }) => {
  return (
    <TouchableOpacity
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        width: 32,
        height: 32,
      }}
      onPress={onPress}
    >
      <View
        style={{
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderColor: colors.textBackgroundPrimary,
          width: 24,
          height: 8,
        }}
      />
      <View
        style={{
          borderBottomWidth: 1,
          borderColor: colors.textBackgroundPrimary,
          width: 24,
          height: 8,
        }}
      />
    </TouchableOpacity>
  );
});

type Props = {
  loading: boolean;
  point: TokenResource;
  holder: TokenHolderResource;
  holderTitleQuest?: QuestResource;
  notices: TokenNoticeResource[];
  benefits: BenefitResource[];
  menu: MenuItemProps[];
  onPressMembership: () => void;
  onPressBenefitItem: ({ id: string }) => void;
  onPressNoticeItem: (v: TokenNoticeResource) => void;
  onPressNoticeListMore: () => void;
  onPressBenefitListMore: () => void;
  onPressDrawerMenu: () => void;
  onPressPortalMenu: (item: MenuItemProps) => void;
};

const Component: React.FC<Props> = ({
  loading,
  point,
  holder,
  holderTitleQuest,
  menu,
  ...props
}) => {
  const viewWidth = useViewWidth();
  const notices = useMemo(
    () =>
      props.notices
        .filter((_, i) => i < 10)
        .map((e) => ({ notice: e }))
        .concat(props.notices.length > 10 ? [{ notice: undefined }] : []),
    [props.notices],
  );
  const benefits = useMemo(
    () =>
      props.benefits
        .filter((_, i) => i < 10)
        .map((e) => ({ benefit: e }))
        .concat(props.benefits.length > 10 ? [{ benefit: undefined }] : []),
    [props.benefits],
  );
  const onPressMenuItem = useCallback(
    (item: MenuItemProps) => {
      if (loading) return;
      props.onPressPortalMenu(item);
    },
    [loading, props],
  );
  return (
    <Wrapper style={{ paddingBottom: 40 }}>
      <View
        style={{
          paddingHorizontal: 16,
          justifyContent: 'center',
          alignItems: 'flex-start',
          height: 64,
        }}
      >
        <OpenDrawerButton onPress={props.onPressDrawerMenu} />
      </View>
      <View style={{ paddingHorizontal: 16 }}>
        <MembershipCard
          viewWidth={viewWidth}
          point={point}
          holder={holder}
          quest={holderTitleQuest}
          onPress={props.onPressMembership}
        />
      </View>
      <Menu
        loading={loading}
        viewWidth={viewWidth}
        items={menu}
        onPress={onPressMenuItem}
      />
      {notices && notices.length > 0 && (
        <Section title='おしらせ' onPressMore={props.onPressNoticeListMore}>
          <FlatList
            style={{ paddingHorizontal: 16 }}
            data={notices}
            horizontal={true}
            alwaysBounceHorizontal={true}
            showsHorizontalScrollIndicator={false}
            keyExtractor={(_, i) => `notice-${i}`}
            renderItem={({ item: { notice } }) =>
              notice ? (
                <ListItem
                  key={notice.id}
                  image={toImageSource(notice.image)}
                  unread={!notice.read_at}
                  text={notice.body}
                  onPress={() => props.onPressNoticeItem(notice)}
                />
              ) : (
                <ListMore onPress={props.onPressNoticeListMore} />
              )
            }
          />
        </Section>
      )}
      {benefits && benefits.length > 0 && (
        <Section
          title='ポイントをつかう'
          onPressMore={props.onPressBenefitListMore}
        >
          <FlatList
            style={{ paddingHorizontal: 16 }}
            data={benefits}
            horizontal={true}
            alwaysBounceHorizontal={true}
            showsHorizontalScrollIndicator={false}
            keyExtractor={(_, i) => `benefit-${i}`}
            renderItem={({ item: { benefit } }) =>
              benefit ? (
                <ListItem
                  key={benefit.id}
                  image={toImageSource(benefit.profile_image)}
                  text={benefit.name}
                  amount={{ value: benefit.amount, unit: point.short_unit }}
                  benefitFeature={benefit.feature}
                  onPress={() => props.onPressBenefitItem(benefit)}
                />
              ) : (
                <ListMore onPress={props.onPressBenefitListMore} />
              )
            }
          />
        </Section>
      )}
      <Section title={`${point.name}とは`}>
        <Description>{point.description}</Description>
      </Section>
      <Section title='発行者'>
        <Owner owner={point.owner} />
      </Section>
    </Wrapper>
  );
};

export type { Props as PortalProps };
export { Component as Portal };
