import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountEmailEdit, AccountEmailForm } from '../components/pages';
import { useAuth, useGoBack, useNavigationHeader, usePoint } from '../hooks';
import { AccountEmailNavigation, AccountEmailRoute } from '../navigation';
import {
  operations as accountOperations,
  selectors as accountSelectors,
} from '../states/account';
import {
  operations as pointOperations,
  selectors as pointSelectors,
} from '../states/point';

type Props = {
  navigation: AccountEmailNavigation;
  route: AccountEmailRoute;
};

const Container: React.FC<Props> = () => {
  useAuth();
  usePoint();
  const dispatch = useDispatch();
  const accountTask = useSelector(accountSelectors.getUpdateEmailTask);
  const { status: holderTask } = useSelector(
    pointSelectors.updateHolderNotification,
  );
  const { email } = useSelector(accountSelectors.getProfile);
  const { wallet: walletId, email_notifications_enabled } = useSelector(
    pointSelectors.holder,
  );
  const goBack = useGoBack(
    ({ goBack }) => {
      dispatch(accountOperations.endUpdateEmail());
      dispatch(pointOperations.resetUpdateHolderEmailNotifications());
      goBack();
    },
    [dispatch],
  );
  useNavigationHeader(
    () => ({ title: 'メールアドレス設定', left: goBack }),
    [goBack],
  );
  useEffect(() => {
    if (accountTask === 'success' && holderTask === 'success') {
      goBack.onPress();
    }
  }, [goBack, accountTask, holderTask]);
  const onPressChange = useCallback(
    (v: AccountEmailForm) => {
      dispatch(accountOperations.updateEmail(v));
      dispatch(
        pointOperations.updateHolderNotification({
          walletId,
          enabled: v.notificationsEnabled,
        }),
      );
    },
    [dispatch, walletId],
  );
  return (
    <AccountEmailEdit
      loading={accountTask === 'in_progress'}
      updated={accountTask === 'success'}
      defaultEmailValue={email}
      notificationsEnabled={email_notifications_enabled}
      onPressChange={onPressChange}
    />
  );
};

export { Container as AccountEmailEditView };
export default Container;
