import { ApplicationError } from '../lib/error';
import { AuthTokens } from '../mint';
import { storage } from './storage';

const key = 'authTokens';

export const saveAuthTokens = (tokens: AuthTokens) => {
  storage.save({ key, data: tokens, expires: null });
};

export const loadAuthTokens = async () => {
  return await storage
    .load<AuthTokens>({ key })
    .catch(() => Promise.reject(new ApplicationError('auth_require')));
};

export const removeAuthTokens = () =>
  storage.remove({ key }).catch(console.error);
