import Constants from 'expo-constants';

type MintConfig = {
  server: string;
  defaultTokenId: string;
  sourceVersion: string;
};

const getEnv = () => Constants.manifest.web.config.mint as MintConfig;

export default getEnv();
