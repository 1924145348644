import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Camera } from '../components/pages';
import {
  useAcquisitionPopUp,
  useAuth,
  useGoBack,
  useNavigationHeader,
  usePoint,
} from '../hooks';
import { CameraNavigation, CameraRoute } from '../navigation';
import {
  operations as pointOperations,
  TakePictureAsync,
} from '../states/point';

type Props = {
  navigation: CameraNavigation;
  route: CameraRoute;
};

const Container: React.FC<Props> = () => {
  useAuth();
  usePoint();
  const dispatch = useDispatch();
  const { onPress: goBack } = useGoBack();
  useNavigationHeader(
    () => ({
      title: '撮影',
      transparent: true,
      right: { tintColor: 'white', image: 'close', onPress: goBack },
    }),
    [goBack],
  );
  useAcquisitionPopUp(() => ({ onPressClose: goBack }), [goBack]);
  const onPress = useCallback(
    (f: TakePictureAsync) => {
      dispatch(pointOperations.capture({ type: 'camera', takePicture: f }));
    },
    [dispatch],
  );
  return <Camera onPress={onPress} />;
};

export { Container as CameraView };
export default Container;
