import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountPasswordEdit } from '../components/pages';
import { useAuth, useGoBack, useNavigationHeader, usePoint } from '../hooks';
import { AccountPasswordNavigation, AccountPasswordRoute } from '../navigation';
import {
  operations as accountOperations,
  selectors as accountSelectors,
} from '../states/account';

type ContainerProps = {
  navigation: AccountPasswordNavigation;
  route: AccountPasswordRoute;
};

const Container: React.FC<ContainerProps> = () => {
  useAuth();
  usePoint();
  const dispatch = useDispatch();
  const taskStatus = useSelector(accountSelectors.getUpdatePasswordTask);
  const goBack = useGoBack();
  useNavigationHeader(
    () => ({ title: 'パスワードの変更', left: goBack }),
    [goBack],
  );
  useEffect(() => {
    if (taskStatus === 'success') {
      dispatch(accountOperations.endUpdatePassword());
      goBack.onPress();
    }
  }, [dispatch, goBack, taskStatus]);
  const onPressChange = useCallback(
    (v) => dispatch(accountOperations.updatePassword(v)),
    [dispatch],
  );
  return (
    <AccountPasswordEdit
      loading={taskStatus === 'in_progress'}
      updated={taskStatus === 'success'}
      onPressChange={onPressChange}
    />
  );
};

export { Container as AccountPasswordEditView };
export default Container;
