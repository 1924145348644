import { Ionicons } from '@expo/vector-icons';
import React from 'react';
import {
  Image,
  ImageSourcePropType,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';
import { Theme, withTheme } from '../../theme';

const Component: React.FC<{
  theme: Theme;
  size: number;
  emptyImage: 'point' | 'person';
  emptyStyle?: StyleProp<ViewStyle>;
  source?: ImageSourcePropType;
}> = ({ theme: { colors }, size, emptyImage, emptyStyle, source }) => (
  <View
    style={[
      {
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        width: size,
        height: size,
        borderRadius: size / 2,
      },
      !source && (emptyStyle ?? { backgroundColor: colors.backgroundLight }),
    ]}
  >
    {source ? (
      <Image style={{ width: '100%', height: '100%' }} source={source} />
    ) : (
      <Ionicons
        name={emptyImage === 'point' ? 'leaf' : emptyImage}
        size={size / 2}
        color={colors.card}
      />
    )}
  </View>
);

export const Person = withTheme(Component);
