import { ForwardedRef } from 'react';
import { ColorValue, ImageURISource } from 'react-native';

export function setForwardRef<T>(ref: ForwardedRef<T>, current: T) {
  if (typeof ref === 'function') {
    ref(current);
  } else if (ref) {
    ref.current = current;
  }
}

export class RGBA {
  public red: number;
  public green: number;
  public blue: number;
  public alpha: number;
  constructor(hex: ColorValue) {
    const s = hex.toString();
    if (!/^#[A-Fa-f0-9]{6}$/.test(s)) {
      throw new Error(`Bad Hex: ${s}`);
    }
    this.red = parseInt(s.slice(1, 3), 16);
    this.green = parseInt(s.slice(3, 5), 16);
    this.blue = parseInt(s.slice(5, 7), 16);
    this.alpha = 1;
  }
  public toString(): string {
    return `rgba(${this.red}, ${this.green}, ${this.blue}, ${this.alpha})`;
  }
  public setAlpha(v: number): RGBA {
    this.alpha = v;
    return this;
  }
}

export function toImageSource(image?: {
  uploaded: boolean;
  url: string;
}): ImageURISource | null {
  if (!image || !image.uploaded || image.url === 'about:blank') {
    return null;
  }
  return { uri: image.url };
}
