import { useMemo } from 'react';
import { ListData } from '../components/pages/types';
import { TaskStatus } from '../lib/task';

export const useListData = <T>(v: {
  data: T[];
  status: TaskStatus;
  onLoad: (event?: { more: boolean }) => void;
}): ListData<T> => {
  const { data, status, onLoad } = v;
  return useMemo(() => {
    return {
      data,
      refreshing: status === 'in_progress',
      onRefresh: () => {
        onLoad();
      },
      onEndReached: () => {
        onLoad({ more: true });
      },
    };
  }, [data, status, onLoad]);
};
