import React from 'react';
import { useSelector } from 'react-redux';
import { Loading } from '../components/elements';
import { HistoryDetail } from '../components/pages';
import {
  useAuth,
  useGoBack,
  useNavigationHeader,
  usePoint,
  useTransaction,
} from '../hooks';
import { HistoryDetailNavigation, HistoryDetailRoute } from '../navigation';
import { selectors as pointSelectors } from '../states/point';

type ContainerProps = {
  navigation: HistoryDetailNavigation;
  route: HistoryDetailRoute;
};

const Container: React.FC<ContainerProps> = ({ route }) => {
  useAuth();
  const { point } = usePoint();
  const { transactionId } = { transactionId: undefined, ...route.params };
  const transaction = useTransaction({ id: transactionId });
  const holder = useSelector(pointSelectors.holder);
  const checkInStampIDs = useSelector(pointSelectors.checkinStampIDs);
  const introStampIDs = useSelector(pointSelectors.introStampIDs);
  const goBack = useGoBack();
  useNavigationHeader(() => ({ title: '送受信履歴', left: goBack }), [goBack]);
  if (!point || !holder || !transaction) {
    return <Loading title='通信中' />;
  }
  return (
    <HistoryDetail
      holder={holder}
      transaction={transaction}
      checkInStampIDs={checkInStampIDs}
      introStampIDs={introStampIDs}
    />
  );
};

export { Container as HistoryDetailView };
export default Container;
