import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootParamList } from '../navigation';
import {
  operations as accountOperations,
  selectors as accountSelectors,
} from '../states/account';
import { selectors as pointSelectors } from '../states/point';

const useAuth = () => {
  const route = useRoute<RouteProp<RootParamList>>();
  const navigation = useNavigation<StackNavigationProp<RootParamList>>();
  const isFocused = navigation.isFocused();
  const dispatch = useDispatch();
  const { loaded } = useSelector(pointSelectors.getPortal);
  const signinTask = useSelector(accountSelectors.getSigninTask);
  useEffect(() => {
    if (!isFocused || !loaded) {
      return;
    }
    switch (signinTask.status) {
      case '':
        dispatch(accountOperations.initializeAuth());
        break;
      case 'signed_out':
        navigation.reset({ index: 0, routes: [{ name: 'AuthStart' }] });
        if (route.name !== 'AuthLogout') {
          dispatch(accountOperations.addNextRouteAfterSignIn(route));
        }
        break;
    }
  }, [navigation, route, dispatch, isFocused, loaded, signinTask.status]);
  useEffect(() => {
    if (
      signinTask.status === 'signed_in' &&
      signinTask.nextRoutes.length > 0 &&
      signinTask.nextRoutes[0].name === route.name
    ) {
      dispatch(accountOperations.consumeNextRouteAfterSignIn());
    }
  }, [dispatch, route.name, signinTask.status, signinTask.nextRoutes]);
};

export { useAuth };
export default useAuth;
