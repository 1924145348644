import { PathConfigMap } from '@react-navigation/native';
import { createURL } from 'expo-linking';
import { RootParamList } from '.';

const screens: PathConfigMap<RootParamList> = {
  AuthStart: { path: 'auth' },
  AuthLogout: { path: 'logout' },
  AuthVerification: { path: 'auth-verification' },
  AuthCallback: 'auth/callback',
  AccountSettings: { path: 'account' },
  AccountProfile: { path: 'account/profile' },
  AccountPhone: { path: 'account/phone' },
  AccountPhoneVerification: { path: 'account/phone-verification' },
  AccountEmail: { path: 'account/email' },
  AccountPassword: { path: 'account/password' },
  Portal: { path: '' },
  Membership: { path: 'membership' },
  HistoryList: { path: 'histories' },
  HistoryDetail: {
    path: 'histories/:transactionId',
    parse: { transactionId: String },
  },
  NoticeList: { path: 'notices' },
  NoticeDetail: {
    path: 'notices/:noticeId',
    parse: { noticeId: String },
  },
  BenefitList: { path: 'benefits' },
  BenefitDetail: {
    path: 'benefits/:benefitId',
    parse: { benefitId: String },
  },
  BenefitCompose: {
    path: 'benefits/:benefitId/compose',
    parse: { ticketId: String },
  },
  VoucherList: { path: 'vouchers' },
  VoucherDetail: {
    path: 'vouchers/:ticketId',
    parse: { ticketId: String },
  },
  VoucherPresent: {
    path: 'vouchers/:ticketId/present',
    parse: { ticketId: String },
  },
  OrderList: { path: 'orders' },
  OrderDetail: {
    path: 'orders/:ticketId',
    parse: { ticketId: String },
  },
  Scanner: { path: 'scan' },
  Camera: { path: 'camera' },
  NotFound: '*',
};

export default {
  prefixes: [createURL('/')],
  //   prefixes: [],
  config: {
    screens,
  },
};
