import axios from 'axios';
import { Dispatch as ReactDispatch } from 'react';
import { Linking } from 'react-native';
import { ApplicationError } from '../../lib/error';
import { State as RootState } from '../store';
import actions, { Actions } from './actions';
import { appinfo } from './selectors';

type Dispatch = ReactDispatch<Actions>;
type GetRootState = () => RootState;

export default {
  checkVersion: () => async (dispatch: Dispatch, getState: GetRootState) => {
    const { checkVersionTask } = appinfo(getState());
    if (
      checkVersionTask.status === 'in_progress' ||
      // new Date().getTime() - checkVersionTask.timestamp < 3600000
      new Date().getTime() - checkVersionTask.timestamp < 60000
    ) {
      return;
    }
    dispatch(actions.startCheckVersionTask());
    try {
      const baseURL = await Linking.getInitialURL().then(
        (initialURL) => new URL(initialURL).origin,
      );
      const version = await axios
        .create({ baseURL })
        .get<{ version: string }>('/version.json')
        .then((res) => res.data.version)
        .catch(() =>
          Promise.reject(new ApplicationError('buildinfo_not_found')),
        );
      dispatch(actions.successCheckVersionTask(version));
      globalThis._mint = { ...globalThis._mint, latestVersion: version };
    } catch (err) {
      dispatch(actions.errorCheckVersionTask());
      console.error(err);
    }
  },
};
