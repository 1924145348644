import React, { useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';
import regexp from '../../lib/regexp';
import { Button, TextInput, Wrapper } from '../elements';

const styles = StyleSheet.create({
  button: {
    marginTop: '25px',
  },
});

type FormState = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  currentPasswordError?: string;
  newPasswordError?: string;
  confirmPasswordError?: string;
};

const initFormState: FormState = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

type Props = {
  loading: boolean;
  updated: boolean;
  onPressChange: (form: FormState) => void;
};

const Component: React.FC<Props> = (props) => {
  const [form, setForm] = useState({ ...initFormState });
  const setCurrentPassword = useCallback(
    (value: string) =>
      setForm({
        ...form,
        currentPassword: value,
        currentPasswordError: validateCurrentPassword(value),
      }),
    [form],
  );
  const setNewPassword = useCallback(
    (value: string) =>
      setForm({
        ...form,
        newPassword: value,
        newPasswordError: validateNewPassword(value),
      }),
    [form],
  );
  const setConfirmPassword = useCallback(
    (value: string) =>
      setForm({
        ...form,
        confirmPassword: value,
        confirmPasswordError: validateConfirmPassword(value, form.newPassword),
      }),
    [form],
  );
  const onPressChange = useCallback(() => {
    props.onPressChange(form);
    setForm(initFormState);
  }, [form, props]);
  return (
    <Wrapper style={{ paddingHorizontal: 16 }}>
      <TextInput
        editable={!props.loading && !props.updated}
        secureTextEntry={true}
        label='現在のパスワード'
        value={form.currentPassword}
        containerStyle={{ marginTop: '25px' }}
        onChangeText={setCurrentPassword}
        errorMessage={form.currentPasswordError}
      />
      <TextInput
        editable={!props.loading && !props.updated}
        secureTextEntry={true}
        label='新しいパスワード'
        value={form.newPassword}
        containerStyle={{ marginTop: '25px' }}
        onChangeText={setNewPassword}
        errorMessage={form.newPasswordError}
      />
      <TextInput
        editable={!props.loading && !props.updated}
        secureTextEntry={true}
        label='パスワードの確認'
        value={form.confirmPassword}
        containerStyle={{ marginTop: '25px' }}
        onChangeText={setConfirmPassword}
        errorMessage={form.confirmPasswordError}
      />
      <Button
        loading={props.loading}
        disabled={
          props.updated ||
          (form.currentPasswordError ? true : false) ||
          form.newPassword === '' ||
          (form.newPasswordError ? true : false) ||
          form.confirmPassword === '' ||
          (form.confirmPasswordError ? true : false)
        }
        title='変更'
        style={styles.button}
        onPress={onPressChange}
      />
    </Wrapper>
  );
};

export type { Props as AccountPasswordEditProps };
export { Component as AccountPasswordEdit };

const validateCurrentPassword = (value: string): string | undefined => {
  if (value !== '' && (value.length < 8 || value.length > 256)) {
    return 'パスワードは8文字以上、256文字以内で入力してください';
  }
  if (!regexp.password.test(value)) {
    return 'パスワードは半角英数字記号のみで入力してください';
  }
  return undefined;
};

const validateNewPassword = (value: string): string | undefined => {
  if (value !== '' && (value.length < 8 || value.length > 256)) {
    return 'パスワードは8文字以上、256文字以内で入力してください';
  }
  if (!regexp.password.test(value)) {
    return 'パスワードは半角英数字記号のみで入力してください';
  }
  return undefined;
};

const validateConfirmPassword = (
  value: string,
  newPassword: string,
): string | undefined => {
  if (value !== '' && (value.length < 8 || value.length > 256)) {
    return 'パスワードは8文字以上、256文字以内で入力してください';
  }
  if (!regexp.password.test(value)) {
    return 'パスワードは半角英数字記号のみで入力してください';
  }
  if (value !== newPassword) {
    return 'パスワードが一致しません';
  }
  return undefined;
};
