import { MaterialIcons } from '@expo/vector-icons';
import React, { useMemo } from 'react';
import Moment from 'react-moment';
import {
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { RefreshControl } from 'react-native-web-refresh-control';
import { useViewWidth } from '../../hooks';
import {
  TokenHolderResource,
  TokenResource,
  TokenTransactionResource,
} from '../../mint';
import { Theme, withTheme } from '../../theme';
import { Wrapper } from '../elements';
import { getTransactionTitle, RGBA } from '../utils';
import { ListData } from './types';

const useBalanceStyles = ({
  theme: { colors, typography },
}: {
  theme: Theme;
}) => {
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          overflow: 'hidden',
          marginVertical: 16,
          marginHorizontal: 16,
          paddingHorizontal: 27,
          height: 96,
          borderRadius: 16,
          backgroundColor: colors.primary,
        },
        label: {
          ...typography.paragraphM,
          fontWeight: 'bold',
          color: colors.card,
        },
        balance: {
          ...typography.balanceL,
          color: colors.card,
        },
      }),
    [colors, typography],
  );
};

const Balance = withTheme<{
  theme: Theme;
  point: TokenResource;
  holder: TokenHolderResource;
}>(({ theme, point, holder }) => {
  const styles = useBalanceStyles({ theme });
  return (
    <View style={styles.container}>
      <Text style={styles.label}>現在の{point.unit}</Text>
      <Text style={styles.balance}>
        {holder.balances} {point.short_unit}
      </Text>
    </View>
  );
});

const useListItemStyles = ({
  theme: { colors, typography },
  received,
}: {
  theme: Theme;
  received: boolean;
}) => {
  return useMemo(() => {
    const amountColor = received
      ? colors.positivePrimary
      : colors.negativePrimary;
    return StyleSheet.create({
      container: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginHorizontal: 16,
        padding: 16,
        borderRadius: 16,
        backgroundColor: colors.card,
      },
      title: {
        ...typography.paragraphM,
        color: colors.textCardPrimary,
      },
      date: {
        ...typography.paragraphS,
        color: colors.textCardHidden,
      },
      amountContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 64,
        height: 64,
        padding: 8,
        borderRadius: 12,
        backgroundColor: new RGBA(amountColor).setAlpha(0.1).toString(),
      },
      amount: {
        ...typography.paragraphXL,
        fontWeight: 'bold',
        color: amountColor,
      },
      next: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: 32,
        height: 32,
        borderRadius: 16,
        overflow: 'hidden',
        backgroundColor: colors.backgroundLight,
      },
    });
  }, [colors, typography, received]);
};

const ListItem = withTheme<{
  theme: Theme;
  viewWidth: number;
  holder: TokenHolderResource;
  transaction: TokenTransactionResource;
  checkInStampIDs: string[];
  introStampIDs: string[];
  onPress: () => void;
}>(
  ({
    theme,
    viewWidth,
    holder,
    transaction: tx,
    checkInStampIDs,
    introStampIDs,
    onPress,
  }) => {
    const received = useMemo(
      () => tx.to.wallet === holder.wallet,
      [tx, holder],
    );
    const styles = useListItemStyles({ theme, received });
    const info = useMemo(() => {
      return {
        sign: received ? '+' : '-',
        title: getTransactionTitle({
          tx,
          recv: received,
          checkInStampIDs,
          introStampIDs,
          text: ({ type, recv }) => {
            switch (type) {
              case 'checkin':
                return `チェックインで${tx.token.unit}を獲得`;
              case 'intro':
                return `会員登録で${tx.token.unit}を獲得`;
              case 'benefit':
                return '特典と交換';
              default:
                return recv
                  ? `${tx.token.unit}を獲得`
                  : `${tx.token.unit}を消費`;
            }
          },
        }),
      };
    }, [received, tx, checkInStampIDs, introStampIDs]);
    return (
      <TouchableOpacity style={styles.container} onPress={onPress}>
        <View style={styles.amountContainer}>
          <Text
            adjustsFontSizeToFit={true}
            style={styles.amount}
          >{`${info.sign}${tx.amount}`}</Text>
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginLeft: 16,
          }}
        >
          <View
            style={{
              flexDirection: 'column',
              justifyContent: 'center',
              width: viewWidth * 0.52,
            }}
          >
            <Text style={styles.title} numberOfLines={2} ellipsizeMode='tail'>
              {info.title}
            </Text>
            <Text style={styles.date}>
              <Moment format='YYYY/MM/DD HH:mm'>{tx.created_at}</Moment>
            </Text>
          </View>
          <View style={styles.next}>
            <MaterialIcons
              name='arrow-forward'
              size={16}
              color={theme.colors.textCardSecondary}
            />
          </View>
        </View>
      </TouchableOpacity>
    );
  },
);

type Props = {
  point: TokenResource;
  holder: TokenHolderResource;
  data: ListData<TokenTransactionResource>;
  checkInStampIDs: string[];
  introStampIDs: string[];
  onPressItem: (t: TokenTransactionResource) => void;
};

const Component: React.FC<Props> = ({
  point,
  holder,
  data: { data, refreshing, onRefresh, onEndReached },
  checkInStampIDs,
  introStampIDs,
  onPressItem,
}) => {
  const width = useViewWidth();
  return (
    <Wrapper scrollDisabled>
      <Balance point={point} holder={holder} />
      <FlatList
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        data={data}
        keyExtractor={(_, i) => `transaction-${i}`}
        renderItem={({ item }) => (
          <ListItem
            viewWidth={width}
            holder={holder}
            transaction={item}
            checkInStampIDs={checkInStampIDs}
            introStampIDs={introStampIDs}
            onPress={() => onPressItem(item)}
          />
        )}
        onEndReached={onEndReached}
        onEndReachedThreshold={0.1}
        ItemSeparatorComponent={() => <View style={{ height: 8 }} />}
        ListFooterComponent={() => <View style={{ height: 100 }} />}
      />
    </Wrapper>
  );
};

export type { Props as HistoryListProps };
export { Component as HistoryList };
