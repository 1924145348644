import React, { useCallback } from 'react';
import { Loading } from '../components/elements';
import { VoucherPresent } from '../components/pages';
import {
  useAuth,
  useGoBack,
  useNavigationHeader,
  usePoint,
  useVoucher,
} from '../hooks';
import { VoucherPresentNavigation, VoucherPresentRoute } from '../navigation';

type Props = {
  navigation: VoucherPresentNavigation;
  route: VoucherPresentRoute;
};

const Container: React.FC<Props> = ({ route }) => {
  useAuth();
  usePoint();
  const goBack = useGoBack();
  const { ticketId } = { ticketId: undefined, ...route.params };
  const ticket = useVoucher({ id: ticketId });
  useNavigationHeader(() => ({ transparent: true }), []);
  const onPressClose = useCallback(() => {
    goBack.onPress();
  }, [goBack]);
  if (!ticket) {
    return <Loading title='通信中' />;
  }
  return <VoucherPresent ticket={ticket} onPressClose={onPressClose} />;
};

export { Container as VoucherPresentView };
export default Container;
