import { Ionicons } from '@expo/vector-icons';
import React, { useMemo } from 'react';
import {
  Image,
  ImageSourcePropType,
  ImageStyle,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import { BenefitImage, NoticeImage } from '../../assets';
import { Theme, withTheme } from '../../theme';
import { TicketInfo } from '../utils/types';

const useStyles = ({
  theme: { colors, typography },
  rounded,
  size,
}: {
  theme: Theme;
  rounded: boolean;
  size: number;
}) =>
  useMemo(() => {
    return StyleSheet.create({
      container: {
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: rounded ? size * 0.08 : 0,
        width: size,
        height: size,
      },
      emptyImage: { width: '50%', height: '50%' },
      image: {
        backgroundColor: colors.backgroundLight,
        width: '100%',
        height: '100%',
      },
      infoContainer: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.7)',
      },
      infoTitle: {
        ...typography.textSmaller,
        marginTop: 4,
        fontWeight: 'bold',
        color: 'white',
      },
    });
  }, [colors, typography, rounded, size]);

type Props = {
  theme: Theme;
  size: number;
  rounded?: boolean;
  emptyImage?: 'notice' | 'benefit';
  style?: StyleProp<ImageStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  source?: ImageSourcePropType;
  info?: {
    title: string;
    icon: TicketInfo['icon'];
  };
};

const Component: React.FC<Props> = ({
  theme,
  size,
  rounded,
  emptyImage,
  style,
  containerStyle,
  source,
  info,
}) => {
  const styles = useStyles({ theme, rounded, size });
  const emptySource = useMemo(
    () =>
      emptyImage === 'benefit'
        ? BenefitImage
        : emptyImage === 'notice'
        ? NoticeImage
        : null,
    [emptyImage],
  );
  return (
    <View
      style={[
        styles.container,
        containerStyle,
        !source && { backgroundColor: theme.colors.itemBackgroundEmpty },
      ]}
    >
      {source ? (
        <Image
          style={[styles.image, style]}
          source={source}
          resizeMode='contain'
        />
      ) : (
        <Image style={styles.emptyImage} source={emptySource} />
      )}
      {info && (
        <View style={styles.infoContainer}>
          <Ionicons name={info.icon} color='white' size={14} />
          <Text style={styles.infoTitle}>{info.title}</Text>
        </View>
      )}
    </View>
  );
};

export type { Props as ItemImageProps };
export const ItemImage = withTheme(Component);
